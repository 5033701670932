<vp-circular-menu></vp-circular-menu>
<div id="vnc-commander-app" style="background-color: transparent;" [ngClass]="{'hin-theme': isHinTheme}">
  <div style="display: none" *ngIf="participants && participants.length > 0">
    <vp-participant *ngFor="let participant of participants" audioOnly="true" [participant]="participant">
    </vp-participant>
  </div>
  <div id="activeCallIndicatorBar"
    *ngIf="!configService.isAnonymous && showActiveCall && !hideActiveCall"
    (click)="navigateToCall()" class="active activeCall">
    <div class="active-call"><span class="icon icon-call-pickup has-camera"></span> {{ 'ACTIVE_CALL' | translate }}
      <span class="video-time">{{ videoTime }}</span>
    </div>
  </div>

  <div *ngIf="configService.selectedServer && (isLoggedIn || hasStoredProfile || configService.isAnonymous)" id="mainLayout" class="main-layout" [ngClass]="{
            'showActiveCall': showActiveCall && !selectedConversation}">
    <div id="layoutContainer">
              <!-- HEADER -->
      <div id="layoutHeader" *ngIf="!configService.isAnonymous && !hideheader" class="header__blue" [class.is-hin-theme]="isHinTheme"
        [class.is-airbus-theme]="isAirbusTheme" [class.show-dialog]="showDialog"
        [class.header__pink]="currentRoute === 'commander'"
        [ngClass]="{'position-fixed': window.location.pathname.endsWith('/talk') && isOnCordova && CommonUtil.isOnIOS(), 'hide-header': !showHeader}">
        <mat-progress-bar id="xmppLoading" mode="indeterminate" *ngIf="(!isLoggedIn && !isExternal()) || !isOnline || !isXmppConnected"></mat-progress-bar>
        <vp-header (userUpdated)="handleCollapse($event)" [collapsed]="collapsed" [fullurl]="fullRoute"
        [url]="currentRoute" [app]="app"></vp-header>
      </div>

      <!-- CONTENT -->
      <div id="layoutContent" [class.profile-page]="isProfile" [class.is-iframe]="hideheader">
        <div class="vnc-grid no-padding" [class.full-width]="fullWidth">
          <div *ngIf="!configService.isAnonymous && sidebarAllow && screen === 'desktop'" class="vnc-cell--3-col"
            [class.sidebar-collapsed]="showWide" [class.sidebar-expanded]="!showWide">
            <vp-sidebar [url]="currentRoute" [fullRoute]="fullRoute" (onToggleExpand)="showWide = $event"
              [isExpanded]="!showWide"></vp-sidebar>
          </div>
          <div class="vnc-cell--9-col clearfix" [class.content-in-sidebar-expanded]="sidebarAllow && !showWide"
            [class.content-in-sidebar-collapsed]="sidebarAllow && showWide"
            [class.full-width]="currentRoute==='content'" [ngClass]="{ 'vnc-cell--12-col': !sidebarAllow }"
            vpTxtHighlightPos (onSelectionChange)="textSelectionData = $event" *ngIf="fullRoute">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>

  </div>

  <vp-backdrop-overlay></vp-backdrop-overlay>
  <vp-bubble-chat *ngIf="isEnable()" [ngClass]="{'showSidebar': collapsed, 'hideSidebar': !collapsed}"></vp-bubble-chat>
  <vp-talk-profile-card></vp-talk-profile-card>
  <vp-notifications [showSidebar]=collapsed [options]="options" (onCreate)="onCreate()"
    (onDestroy)="onDestroy()"></vp-notifications>
  <vp-authentication *ngIf="!configService.isAnonymous && !configService.selectedServer"></vp-authentication>
  <vp-oauth-login *ngIf="!configService.isAnonymous && configService.selectedServer && loginWithOAuth"></vp-oauth-login>
</div>
<dialog-outlet></dialog-outlet>
