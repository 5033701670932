
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "app/environments/environment";
export class ConstantsUtil {
  static ALL_TARGET = "all";
  static INITIATE_TAG_BASED_SEARCH = "INITIATE_TAG_BASED_SEARCH";
  static SEARCHING_FOR_CHANNELS = "SEARCHING_FOR_CHANNELS";
  static CONFERENCE_LINK_PART = "/vncmeet/join/";
  static CONFERENCE_LINK_MOBILE_PART = "/talk/join/";
  static CONFERENCE_ASSETS_LINK_PART = "/assets/meeting/";
  static CONFERENCE_LINK_CLICK_EVENT = "vncmeet-join-link-click";
  static NO_SESSION_ID_ERROR = "VNC_ERROR_NO_SESSION_ID";
  static LAST_SORT_ID_SYNC = "last_sort_id_sync";
  static HIDE_AUTOCOMPLETE = "HIDE_AUTOCOMPLETE";
  static LIMIT_MESSAGES = 1000;
  static DESKTOP_RESIZE_CONSTRAINTS = {
    width: 1280,
    height: 720
  };
  static TIME_TO_SHOW_JOIN = 5; // minutes

  static KEY_FIREBASE_TOKEN = "firebaseToken";
  static KEY_SERVER_URL = "serverURL";
  static KEY_USERNAME = "username";

  static TITLE_GENERAL_GROUP = "General";

  static POST_MESSAGE = "VNC_PORTAL_POST_MESSAGE";
  static TFA_OTP_VERIFICATION = "TFA_OTP_VERIFICATION";
  static LOGIN_USERNAME_CLICKED = "VNC_PORTAL_LOGIN_INPUT_CLICKED";
  static SHOW_FORGOT_PASSWORD_FORM = "SHOW_FORGOT_PASSWORD_FORM";
  static SHOW_REGISTRATION_FORM = "SHOW_REGISTRATION_FORM";
  static SHOW_LOGIN_FORM = "SHOW_LOGIN_FORM";
  static GO_TO_LOGIN_SCREEN = "GO_TO_LOGIN_SCREEN";

  static ERROR_APP_OFFLINE = "ERROR_APP_OFFLINE";
  static ERROR_XMPP_NOT_CONNECTED = "ERROR_XMPP_NOT_CONNECTED";


  static ROUTE_CONVERSATION_SELECT_TARGET = "route_select_conversation_target";
  static CLOSE_SIDEBAR = "close Sidebar";
  static SEND_TOPICS = "Send Topics";
  static ADD_COMMENT_IN_MENTIONED_COMMENT = "Add comment in mentioned comments";
  static UPDATE_COMMENT_IN_MENTIONED_COMMENT = "Add comment in mentioned comments";
  static DELETE_COMMENT_IN_MENTIONED_COMMENT = "Delete comment in mentioned comments";
  static OPEN_SIDEBAR = "Open Sidebar";
  static TOGGLE_SIDEBAR = "Toggle Sidebar";
  static TOGGLE_SIDEBAR_FULL_EXPANSION = "Toggle Sidebar Full Expansion";
  static HIDE_SEARCH_HEADER = "Hide Search Header";
  static APPLY_FILTERS = "apply_filters";

  static HIDE_GLOBAL_SEARCH_SCREEN = "hideGlobalSearchScreen";
  static HIDE_PRIVATE_SEARCH_SCREEN = "hidePrivateSearchScreen";
  static HIDE_USER_MENU = "hideUserMenu";
  static FAVOURITE_LIST_NAME = "favourite__";
  static HIDDEN_LIST_NAME = "hidden__";

  static START_E2EE_CHAT = "Start E2EE chat";

  static LOCAL_STORAGE_NOTIFICATION_MAP = "notification_mapping";
  static THEME = "theme";
  static FONT_SIZE = "font_size";

  static CONV_MAPPING_FILE_NAME = "notificationMapping.json";

  static REPLY_MESSAGE_LINE_BREAK = "\n-------------\n";

  static SCREEN_SHARE_STARTED_COMMAND_DATA = "started";
  static SCREEN_SHARE_STOPPED_COMMAND_DATA = "stopped";

  static USER_IS_ALREADY = "user is already";
  static ALREADY_BEEN_TAKEN = "already been taken";
  static CHAT_BUBBLE_COLORS = [
    "#f12923",
    "#e4004f",
    "#8a00a3",
    "#531aab",
    "#2f36a9",
    "#0095f5",
    "#00afcc",
    "#018675",
    "#3ca53b",
    "#79bc32",
    "#ff8600",
    "#ff3e0c",
    "#664337",
    "#4d6a79"
  ];
  static  FOLLOW_ME_COMMAND = "follow-me";
  static  SET_INITIATOR_COMMAND = "set-initiator";
  static SCREEN_SHARING_COMMAND = "SCREEN_SHARING_COMMAND";
  static SET_PASSWORD_COMMAND = "SET_PASSWORD_COMMAND";
  static SCREEN_SHARING_REQUEST = "SCREEN_SHARING_REQUEST";
  static SCREEN_SHARING_REQUEST_ALLOW = "SCREEN_SHARING_REQUEST_ALLOW";
  static SCREEN_SHARING_REQUEST_DENY = "SCREEN_SHARING_REQUEST_DENY";
  static TOGGLE_MUTE_EVERYONE_COMMAND = "TOGGLE_MUTE_EVERYONE";
  static TOGGLE_MUTE_CAMERA_COMMAND = "TOGGLE_MUTE_CAMERA";
  static USER_INFO = "USER_INFO";
  static CHAT_BACKGROUND = "chat_background";
  static CHAT_BACKGROUND_COLOR = "chat_background_color";
  static CHECK_DATEPICKER = "CHECK_DATEPICKER";
  /**
   * Maps the names of the commands expected by the API with the name of the
   * commands expected by jitsi-meet
   */
  static COMMANDS = {
    avatarUrl: "avatar-url",
    displayName: "display-name",
    email: "email",
    hangup: "video-hangup",
    submitFeedback: "submit-feedback",
    toggleAudio: "toggle-audio",
    toggleChat: "toggle-chat",
    toggleFilmStrip: "toggle-film-strip",
    toggleShareScreen: "toggle-share-screen",
    setShareScreenType: "set-share-screen-type",
    removeShareScreenType: "removeShareScreenType",
    toggleVideo: "toggle-video",
    raiseMyHand: "raise-hand",
    wakeup: "wakeup",
    rejoin: "rejoin"
  };

  static CONFERENCE_USER_ROLE = {
    moderator: "moderator",
    none: "none",
  };

  static CONFERENCE_MEDIA_STATUS = {
    mutedByModerator: "muted-by-moderator",
    muted: "muted",
    unmuted: "unmuted",
  };

  static CONFERENCE_MEDIA_TYPE = {
    audio: "audio",
    video: "video",
    screen: "screen",
    none: "none",
  };

  static PAGINATION_LIMIT = {
    CHANNEL_FILES: 25,
    CHANNEL_MEMBERS: 25,
    CHANNEL_TOPIC_FILES: 25,
    CHANNEL_TOPIC_MEMBERS: 25,
    ARCHIVED_CHANNELS: 25,
    ARCHIVED_TOPICS: 25,
    TRASH_CHANNELS: 25,
    TRASH_TOPICS: 25,
  };


  static CONFERENCE_GUM_ERRORS = {
    CHROME_EXTENSION_INSTALLATION_ERROR: "gum.chrome_extension_installation_error",
    CHROME_EXTENSION_USER_CANCELED: "gum.chrome_extension_user_canceled",
    SCREENSHARING_USER_CANCELED: "gum.screensharing_user_canceled",
    CONSTRAINT_FAILED: "gum.constraint_failed",
    FIREFOX_EXTENSION_NEEDED: "gum.firefox_extension_needed",
    NOT_FOUND: "gum.not_found",
    PERMISSION_DENIED: "gum.permission_denied",
    UNSUPPORTED_RESOLUTION: "gum.unsupported_resolution",
    GENERAL_MEDIA_ERROR: "gum.general"
  };

  static MEDIA_DEVICE_KIND = {
    videoinput: "videoinput",
    audioinput: "audioinput",
    audiooutput: "audiooutput"
  };

  static USER_BANDWIDTH_QUALITY = {
    good: "good",
    average: "average",
    poor: "poor",
    good_background: "#27AB7F",
    average_background: "#ffffff",
    poor_background: "#e02b00",
  };

  static JitsiRecordingMode = {
    FILE : "file",
  };

  static JitsiRecordingStatus = {
    ON : "on",
    PENDING: "pending",
    OFF: "off",
    NOT_STARTED: "not-started",
    START_IN_PROGRESS: "start-in-progress",
  };

  static USER_JOINED_TIMEOUT = 5000;

  static DEFAULT_MEETING_NAME = "VNCmeet";

  static MAX_ATTACH_FILES = 20;

  static MAX_ATTACH_FILE_SIZE = 50000000;

  static isOnAndroid() {
    return typeof device !== "undefined" && !!device.platform && device.platform.toUpperCase() === "ANDROID";
  }


  static getBaseUrl() {
    if (environment.isCordova) {
      if (this.isOnAndroid()) {
        return window.location.href.split("/www")[0] + "/www";
      } else {
        return window.location.href.split("/talk")[0].replace(/index.html/gi, "");
      }
    } else if (environment.isElectron) {
      return window.location.href.split("/talk")[0].replace(/index.html/gi, "");
    } else {
      return "";
    }
  }

  static getFullUrl(url: string) {
    return this.getBaseUrl() + url;
  }
  static VIDEO_BACKGROUND = [
    {
        id: "1",
        src: this.getFullUrl("/assets/virtual-background/vnc/lagoon_001.png")
    },
    {
        id: "2",
        src: this.getFullUrl("/assets/virtual-background/vnc/lagoon_002.png")
    },
    {
        id: "3",
        src: this.getFullUrl("/assets/virtual-background/vnc/lagoon_003.png")
    },
    {
        id: "4",
        src: this.getFullUrl("/assets/virtual-background/vnc/lagoon_004.png")
    },
    {
        id: "5",
        src: this.getFullUrl("/assets/virtual-background/vnc/lagoon_005.png")
    },
    {
        id: "6",
        src: this.getFullUrl("/assets/virtual-background/vnc/lagoon_006.png")
    }
];

static FOLDER_ID = {
  BRIEFCASE: 16,
  CALENDAR: 10,
  CHATS: 14,
  CONTACTS: 7,
  DRAFTS: 6,
  EMAILED_CONTACTS: 13,
  INBOX: 2,
  JUNK: 4,
  SENT: 5,
  TASKS: 15,
  TRASH: 3,
  STARRED: 9999
};

static DEFAULT_CHAT_BACKGROUND  = [
  {
    id: "fruit_background",
    name: "Background-fruits.png",
    type: "background",
    src: this.getFullUrl("/assets/chat-backgrounds/Background-fruits.png"),
  },
  {
    id: "plane_pattern",
    name: "Pattern--Planes.png",
    type: "avatar",
    src: this.getFullUrl("/assets/chat-backgrounds/Pattern--Planes.png"),
  },
  {
    id: "vnc_pattern",
    name: "Pattern--VNC.png",
    type: "avatar",
    src: this.getFullUrl("/assets/chat-backgrounds/Pattern--VNC.png"),
  },
];
}

export enum ScreenViews {
  FULL = "full",
  TILES = "tiles",
  FILMSTRIP = "filmstrip",
  GRID = "grid",
  CONDENSED = "condensed",
  STRIPE = "stripe",
  FLOATING = "floating"
}


export enum BroadcastKeys {
  hideSearchComponent = "hideSearchComponent",
  closeSearchScreen = "closeSearchScreen",
  hideArchiveScreen = "hideArchiveScreen",
  hideGlobalSearchSingleScreenMob = "hideGlobalSearchSingleScreenMob",
  HIDE_CHAT_MEDIA_COMPONENT = "hide_chat_media_component",
  HIDE_CHAT_MEDIA_MENU = "hide_chat_media_menu",
  HIDE_EMAIL_POPUP = "hide_email_popup",
  CLOSE_ALL_DIALOG = "close_all_dialog",
  REFRESH_MESSAGES = "REFRESH_MESSAGES",
  REPLACE_MESSAGES = "REPLACE_MESSAGES",
  HIDE_PREVIEW_IMAGE = "hidePreviewImage",
  DEACTIVATE_PROFILE_CARD = "deactivate_profile_card",
  HIDE_INVITATION = "hide_invitation",
  CALL_REQUEST_TIMED_OUT = "call_request_timed_out",
  CALL_REQUEST_REJECTED = "call_request_rejected",
  REMOVED_FROM_ROOM = "REMOVED_FROM_ROOM",
  CALL_LAST_REMOTE_USER_LEFT = "CALL_LAST_REMOTE_USER_LEFT",
  CALL_USER_CANCELED_SHARE_SCREEN_CALL = "CALL_USER_CANCELED_SHARE_SCREEN_CALL",
  LOST_CONNECTION_WHILE_ACTIVE_CALL = "LOST_CONNECTION_WHILE_ACTIVE_CALL",
  CALL_USER_CANCELED_SHARE_SCREEN_SWITCH = "CALL_USER_CANCELED_SHARE_SCREEN_SWITCH",
  SHARE_SCREEN_MACOS_PERMISSIONS_ERROR = "SHARE_SCREEN_MACOS_PERMISSIONS_ERROR",
  MIC_MACOS_PERMISSIONS_ERROR = "MIC_MACOS_PERMISSIONS_ERROR",
  CAM_MACOS_PERMISSIONS_ERROR = "CAM_MACOS_PERMISSIONS_ERROR",
  CALL_CONNECTION_FAILED = "CALL_CONNECTION_FAILED",
  CALL_REJOIN = "CALL_REJOIN",
  CALL_ON_USER_LEFT = "onUserLeft",
  CALL_ON_USER_JOINED = "onUserJoined",
  INITIATOR_LEFT = "initiator_left",
  CALL_SECOND_DEVICE_JOINED = "CALL_2ND_DEVICE_JOINED",
  ALL_PARTICIPANTS_LEFT = "all_participants_left",
  FOCUS_TEXT_INPUT = "focus_text_input",
  RUN_DETECT_CHANGES = "detect_changes",
  LONG_PRESS_END = "long_press_end",
  DETECT_CHANGES_IN_CHAT_WINDOW = "detect_changes_in_chat_widow",
  HIDE_INCOMING_INVITATION = "HIDE_INCOMING_INVITATION",
  SHOW_NEW_TOPIC_FILES="showNewTopicFiles",
  FETCH_CHANNEL_MEMBERS = "FETCH_CHANNEL_MEMBERS",
  FETCH_CHANNEL_FILES = "FETCH_CHANNEL_FILES",
  FETCH_TOPIC_FILES = "FETCH_TOPIC_FILES",
  RENAME_FILE = "RENAME_FILE",
  REMARK_ADDED = "REMARk_ ADDED",
  REMARK_CLICKED = "REMARK_CLICKED",
  STICKY_NOTE_CLICKED = "STICKY_NOTE_CLICKED",
  STICKY_NOTE_ADDED = "STICKY NOTE ADDED",
  TOPIC_EDIT_CREATE = "tec_toggle", // shows false -> editor window open, true-> editor window closed,
  STICKY_NOTE_DELETED = "sticky_note_deleted",
  HIDE_COLOR_DIALOG = "hide_color_dialog"
}

export enum SideRoasterTabType{
  DEFAULT = "default",
  USERS_ONLINE = "usersOnline",
  CHANNEL_BAR = "channelBar",
  TOPIC_BAR = "topicBar",
  SOCIAL_BAR = "socialBar",
}
