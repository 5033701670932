
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { State, getSelectedConversation } from "../../../reducers";
import { Observable, Subject } from "rxjs";
import { NewPad } from "../interfaces/new-pad.interface";
import { PadUrl } from "../interfaces/pad-url.interface";
import {
  getPadsCountSelector,
  getPadUrlForIframeSelector,
  isShowPadWindowSelector,
  padsSelector,
  getCurrentPadId,
  getIsPadLoading,
  getCurrentPadInfo
} from "../@store/pad.selectors";
import { Pad } from "../interfaces/pad.interface";
import { PadsCounter } from "../interfaces/pads-counter.interface";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { map, share, take } from "rxjs/operators";
import { CookieService } from "./cookie.service";
import { XmppService } from "../../../services/xmpp.service";
import { ResponseCreatedPad } from "../interfaces/response-created-pad.interface";
import { CookieKeys } from "../helpers/cookie.keys.enum";
import { StatusIsShowPadWindow } from "../interfaces/status-is-show-pad-window.interface";
import { PadUrlData } from "../interfaces/pad-url-data.interface";
import { ConversationType } from "../common/emuns/conversation-type.enum";

import * as padActions from "../@store/pad.actions";
import { getUserJID } from "app/reducers";
import { Conversation } from "app/talk/models/conversation.model";
import { CommonUtil } from "app/talk/utils/common.util";
import { environment } from "app/environments/environment";
import { ConversationUpdate } from "app/talk/actions/conversation";
import { MatDialog } from "@angular/material/dialog";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";
import { SnackbarType } from "app/channels/models/snackbar.model";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class PadService {
  currentTarget = "";
  public padRename$ = new Subject<boolean>();
  public padCreate$ = new Subject<boolean>();
  constructor(
    private store$: Store<State>,
    private domSanitizer: DomSanitizer,
    private dialogService: MatDialog,
    private cookieService: CookieService,
    private xmppService: XmppService,
    private channelSnackBarService: ChannelSnackbarService,
    private translate: TranslateService,

  ) { }

  loadListPad(target: string, typeOfConversation: string): void {
    this.store$.dispatch(padActions.createCurrentTarget({ target, typeOfConversation }));
  }

  getSelectedConversation(): Observable<Conversation> {
    return this.store$.select(getSelectedConversation);
  }

  async createNewPad(target: string) {

    let options = {
      "width": "480px",
      "height": "280px",
      "border-radius": "2.6px"
    };
    const { CreatePadComponent } = await import(
      "app/shared/components/create-pad/create-pad.component");
    const pDialog = this.dialogService.open(CreatePadComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: {
        action: "create"
      },
      autoFocus: true
     }, options));
    pDialog.afterClosed().subscribe((name) => {
      if (!!name) {
        const typeOfConversation = target.indexOf("@conference") !== -1 ? "groupchat" : "chat";
        this.store$.pipe(select(getUserJID), take(1)).subscribe(user => {
          const newTarget = this.getTargetOfType(user.bare, typeOfConversation, target);
          const plainTarget = this.getPlainTargetOfType(user.bare, typeOfConversation, target);
          const params = { target: newTarget, name, clearTarget: this.currentTarget, plainTarget};
          this.store$.dispatch(padActions.createNewPad(params));
          let snackBarText = "";
          this.translate.get("THE_DOCUMENT").pipe(take(1)).subscribe(text => {
            snackBarText = text;
            snackBarText = snackBarText + " " + name;
            this.translate.get("WAS_CREATED").pipe(take(1)).subscribe(endText => {
              snackBarText = snackBarText + " " + endText;
              this.channelSnackBarService.openSnackBar(snackBarText, SnackbarType.CHECKMARK);
            });
          });

          this.store$.dispatch(new ConversationUpdate({target, changes: {has_pads: true}}));

          const signal = {
            type: "pad-create",
            target: target
          };
          this.xmppService.sendSignalToTarget(signal);
        });

      }
    });
  }

  async renamePad(pad: any) {

    let options = {
      "width": "480px",
      "height": "280px",
      "border-radius": "2.6px",
      "visibility": "visible"
    };
    const { CreatePadComponent } = await import(
      "app/shared/components/create-pad/create-pad.component");
    const pDialog = this.dialogService.open(CreatePadComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: {
        action: "rename",
        padname: pad.padname,
        padid: pad.padid
      },
      autoFocus: true
     }, options));
    pDialog.afterClosed().subscribe((padname) => {
      if (!!padname) {
        this.translate.get("DOCUMENT_UPADTED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(`${padname} ${text}`, SnackbarType.CHECKMARK);
        });
        this.padRename$.next(true);
        this.store$.dispatch(padActions.renamePadRequest({ padid: pad.padid, padname }));
      }
    });
  }

  lastPadRemoved(target) {
    const signal = {
      type: "last-pad-deleted",
      target: target
    };
    this.xmppService.sendSignalToTarget(signal);
  }

  renamePadById(pad: any): void {
    this.store$.dispatch(padActions.renamePad({padname: pad.padname, padid: pad.padid}));
  }

  deletePad(padID) {
    this.store$.dispatch(padActions.deletePadRequest({ padID }));
    this.padRename$.next(true);
  }

  deletePadById(padID: string) {
    this.store$.dispatch(padActions.deletePad({ padID }));
  }

  getPadUrl(target: string, padid: string, padname: string, plainTarget?:string): void {
    const padUrlData: PadUrlData = { target, padid, padname, plainTarget };
    if (environment.isElectron) {
      localStorage.removeItem(CookieKeys.SESSION_ID);
    }
    // this.cookieService.delete(CookieKeys.SESSION_ID);
    this.store$.dispatch(padActions.loadPadUrl(padUrlData));
  }

  showPadWindow(): void {
    this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        this.currentTarget = conv.Target;
        // this.store$.dispatch(new ConversationRemovePadNotification(conv.Target));
      }
    });
    this.store$.dispatch(padActions.showPadWindow());
  }

  resetPads(): void {
    this.store$.dispatch(padActions.resetPads());
  }

  hidePadWindow(): void {
    if (environment.isElectron) {
      localStorage.removeItem(CookieKeys.SESSION_ID);
    }
    // this.cookieService.delete(CookieKeys.SESSION_ID);
    this.store$.dispatch(padActions.hidePadWindow());
  }

  isShowPadWindow(): Observable<StatusIsShowPadWindow> {
    return this.store$.pipe(select(isShowPadWindowSelector), share());
  }

  getPadList(): Observable<Pad[]> {
    return this.store$.pipe(select(padsSelector));
  }

  getCurrentPadId(): Observable<string> {
    return this.store$.pipe(select(getCurrentPadId));
  }

  getCurrentPad(): Observable<{ currentPadName: string, currentPadId: string }> {
    return this.store$.pipe(select(getCurrentPadInfo));
  }

  getIsPadLoading(): Observable<boolean> {
    return this.store$.pipe(select(getIsPadLoading));
  }

  getCountPads(): Observable<PadsCounter> {
    return this.store$.pipe(select(getPadsCountSelector));
  }

  getPadUrlForIframe(): Observable<{ padUrl: SafeResourceUrl }> {
    return this.store$.pipe(
      select(getPadUrlForIframeSelector),
      map((data: ResponseCreatedPad) => {
        /* if (this.isHasCookie()) {
          this.cookieService.delete(CookieKeys.SESSION_ID);
        } */
        if (environment.isElectron) {
          localStorage.setItem(CookieKeys.SESSION_ID, data.padSession);
        } else {
          this.cookieService.set(CookieKeys.SESSION_ID, data.padSession, 1, "/");
        }
        const bypassSecurityTrustResourceUrl: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(data.padURL);
        return { padUrl: bypassSecurityTrustResourceUrl };
      })
    );
  }

  isHasCookie(): boolean {
    if (environment.isElectron) {
      return !!localStorage.getItem(CookieKeys.SESSION_ID);
    }
    return !!this.cookieService.get(CookieKeys.SESSION_ID);
  }

  removeCookie(): void {
    if (environment.isElectron) {
      localStorage.removeItem(CookieKeys.SESSION_ID);
    }
  }

  createPad(target: string, name?: string, currentTarget?: string, plainTarget?: string): NewPad {
    return new NewPad(target, name, currentTarget, plainTarget);
  }

  createPadUrl(target: string, { padid, padname }: PadUrlData): PadUrl {
    return new PadUrl(target, padid, padname);
  }

  getTargetOfType(jid: string, typeOfConversation: string, currentTarget: string): string {
      if (typeOfConversation === ConversationType.CHAT) {
        const targetId = CommonUtil.getTargetForOneToOne(jid, currentTarget);

        return this.processTarget(targetId);
      }

      return this.processTarget(currentTarget);
  }

  getPlainTargetOfType(jid: string, typeOfConversation: string, currentTarget: string): string {
      if (typeOfConversation === ConversationType.CHAT) {
        const targetId = CommonUtil.getTargetForOneToOne(jid, currentTarget);
        return targetId;
      }
      return currentTarget;
  }


  private processTarget(target: string): string {
    return CommonUtil.md5(target);
  }

}
