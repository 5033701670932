import { VNCUserPreviewService } from "vnc-library";

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  NgZone
} from "@angular/core";

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { GlobalAuthService as PloneAuthService } from "./plone/auth.service";

import { ResponsiveService } from "./shared/services";

import { Subscription } from "rxjs";
import { fromEvent, merge, Subject, BehaviorSubject } from "rxjs";
import { Broadcaster } from "./talk/shared/providers/broadcaster.service";
import { VNCTalkNotificationsService } from "./talk/notifications/notifications.service";
import { ConfigService } from "./config.service";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { FetchMeSuccess, UpdateNetworkInformation, SetDomain, SetAvailableApps,
  SetActiveTab, UpdateLogoutOption, SetAppSettings, SetBare, NotifyOptionChange,
   OnlineStatus, SetBaseApiUrl, DisableGlobalMute, SetChatBackgroundImages, SetSelectedChatBackgroundPreview, SetChatBackgroundColorPreview } from "./actions/app";
import {
  getIsAppOnline,
  getIsAppBootstrapped,
  getIsConnectedXMPP,
  getNetworkInformation,
  getUserJID,
  RootState,
  getIsLoggedIn,
  getGlobalMute,
  getUserProfile,
  getAppSettings,
} from "./reducers/index";
import { ExpandSideRostersList, ExpandConversationList } from "./talk/actions/layout";
import { Conversation as NewConversation, Conversation, Recipient } from "./talk/models/conversation.model";
import { NotificationService as NewNotificationService, NotificationService } from "./talk/services/notification.service";
import { ConversationRepository } from "./talk/repositories/conversation.repository";
import { AvatarRepository } from "app/talk/repositories/avatar.repository";
import { AppService } from "./shared/services/app.service";
import { JID } from "./talk/models/jid.model";
import {
  getConferenceParticipants, getConversationById, getConferenceType,
  getIsSharing,
  getTotalUnreadCount,
  TalkRootState,
} from "./talk/reducers";
import { UpdateJitsiConfig } from "./talk/actions/conference";
import { JitsiService } from "./talk/services/jitsi.service";
import { ConferenceRepository } from "./talk/repositories/conference.repository";
import { CommonUtil, APK_STORE_URL } from "./talk/utils/common.util";
import { BroadcastKeys, ConstantsUtil } from "./talk/utils/constants.util";
import { XmppService } from "./talk/services/xmpp.service";
import { environment } from "./environments/environment";
import { Events } from "./talk/shared/providers";
import { ContactRepository } from "./talk/repositories/contact.repository";
import { NotIdle } from "idlejs/dist";
import { normalizeCommonJSImport } from "app/talk/utils/normalize-common-js-import";
import { ElectronService } from "./shared/providers/electron.service";
import { Message } from "./talk/models/message.model";
import { ToastService } from "app/shared/services/toast.service";
import { MatIconRegistry } from "@angular/material/icon";
import { MatDialog } from "@angular/material/dialog";
import { DatetimeService } from "./talk/services/datetime.service";
import * as Bowser from "bowser";
import { AudioOutputService } from "./talk/services/audio-output.service";
import { DomSanitizer } from "@angular/platform-browser";
import { CallkitService } from "./talk/services/callkit.service";
import { UserConfigRepository } from "./talk/repositories/userconfig.repository";
import { RFCRepository } from "./talk/repositories/rfc.repository";
import { CommonService } from "./shared/providers";
import { distinctUntilChanged, filter, mapTo, take, takeUntil, takeWhile } from "rxjs/operators";
import { ChannelRepository } from "./channels/repository/channel.repository";
import { OwnCloudService } from "./shared/providers/owncloud.service";
import { DatabaseService } from "./talk/services/db/database.service";
import { ConversationService } from "app/talk/services/conversation.service";
import { LoggerService } from "./shared/services/logger.service";

// Initialize Olm as early as possible.
if (window.Olm) {
  window.Olm.init().catch(e => {
      // eslint-disable-next-line no-console
      console.warn("Failed to initialize Olm, E2EE will be disabled", e);
      delete window.Olm;
  });
}

@Component({
  selector: "vp-portal",
  templateUrl: "./root.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VNCPortalComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  selectedConversation: NewConversation;
  isLoggedIn: boolean;
  userJID: JID;

  hideSoundActivation = true;
  vncApps: Array<any> = [];
  collapsed = false;
  isOnline = false;
  isXmppConnected = false;
  isNetworkOnline = false;
  isSyncLocked = false;
  onSingleText: any;
  hasStoredProfile: boolean;
  onGroupText: any;
  onIncomingCall: any;
  options: any = {
    timeOut: 2000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 60,
    maxStack: 5,
    showProgressBar: false,
    pauseOnHover: true,
    preventDuplicates: true,
    preventLastDuplicates: "visible",
    rtl: false,
    animate: "scale",
    position: ["right", "top"]
  };
  isSingleApp = false;
  currentRoute: string = "";
  subscriber: any = null;
  sidebarAllow: boolean = true;
  hideScroll: boolean = false;
  fullRoute: string;
  isProfile: boolean = false;
  showWide: boolean = true;
  textSelectionData: { pos: ClientRect; text: string };
  app: { title: string; path: string; };
  _routeDataSubscriber: Subscription;
  fullWidth: boolean = true;
  isHinTheme = localStorage.getItem("theme") === "hin";
  isAirbusTheme = localStorage.getItem("theme") === "airbus";
  destroy$ = new Subject();
  screen: string = this.responsiveService.getScreen();
  showActiveCall = false;
  showVideoChat = false;
  bootstrapped = false;
  videoTime = "";
  participants = [];
  private isAlive$ = new Subject<boolean>();
  showHeader = true;
  hideDrawer = true;
  hideActiveCall: boolean;
  window = window;
  CommonUtil = CommonUtil;
  isOnCordova = environment.isCordova;
  isOnElectron = environment.isElectron;

  isFirebaseSetUpCompleted = false;
  loginWithOAuth: boolean;
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  markAsRead: any;
  showedEnvironmentDialog: any;
  profileAndConfigRetrievalRecoveryTimesMade: number;
  configAttempts = 0;
  showWelcomeDialogFlag = environment.showWelcomeDialog;
  showRemainDialogFlag = environment.showRemainDialog;
  expandedAutomatically: any;
  private activeConferenceTarget: string;
  showDialog: boolean;

  apnsToken: string = "0000";
  voipToken: string = "0000";
  pushEnvironment: string = "prod"; // change to 'dev' for testing push notifications in debug build
  voip: any;

  isDelayedRetrieveConfigRequired = false;
  isDelayedRetrieveInitialDataRequired = false;
  settings: any = {};

  omemoDeviceExceedMatDialogRef: any;
  tfaReminderDialogRef: any;
  loadedLibs: boolean;
  checkNetwork: any;

  svgPlayButton = `
      <svg width="68px" height="56px" viewBox="0 0 48 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Button/custom/play/@m/default" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" width="68px" height="56px">
            <g id="Group" fill-rule="nonzero">
               <path d="M24,40 C28.5607477,40 32.6728972,39.9025341 36.3364486,39.7076023 C40,39.5126706 42.2803738,39.2592593 43.1775701,38.9473684 C44.9719626,38.1676413 46.2242991,35.9649123 46.9345794,32.3391813 C47.6448598,28.7134503 48,24.5808967 48,19.9415205 C48,15.3021442 47.6448598,11.1890838 46.9345794,7.60233918 C46.2242991,4.01559454 44.9719626,1.83235867 43.1775701,1.05263158 C42.2803738,0.81871345 40,0.584795322 36.3364486,0.350877193 C32.6728972,0.116959064 28.5607477,0 24,0 C19.4392523,0 15.3271028,0.0974658869 11.6635514,0.292397661 C8,0.487329435 5.71962617,0.701754386 4.82242991,0.935672515 C3.02803738,1.79337232 1.77570093,4.01559454 1.06542056,7.60233918 C0.355140187,11.1890838 0,15.3021442 0,19.9415205 C0,24.5808967 0.355140187,28.7134503 1.06542056,32.3391813 C1.77570093,35.9649123 3.02803738,38.1676413 4.82242991,38.9473684 C5.71962617,39.2592593 8,39.5126706 11.6635514,39.7076023 C15.3271028,39.9025341 19.4392523,40 24,40 Z" id="" fill="#FFFFFF"></path>
               <path d="M19,26.2768203 L19,13.7231797 C19,13.1708949 19.4477153,12.7231797 20,12.7231797 C20.1740269,12.7231797 20.3450412,12.768595 20.4961389,12.8549365 L31.4805745,19.1317569 C31.9600919,19.4057668 32.1266887,20.0166215 31.8526787,20.4961389 C31.7640894,20.6511702 31.6356058,20.7796538 31.4805745,20.8682431 L20.4961389,27.1450635 C20.0166215,27.4190734 19.4057668,27.2524767 19.1317569,26.7729593 C19.0454153,26.6218616 19,26.4508473 19,26.2768203 Z" id="Path" fill="#337CBD"></path>
           </g>
           <g id="Group-Copy" fill-rule="nonzero">
               <path d="M24,40 C28.5607477,40 32.6728972,39.9025341 36.3364486,39.7076023 C40,39.5126706 42.2803738,39.2592593 43.1775701,38.9473684 C44.9719626,38.1676413 46.2242991,35.9649123 46.9345794,32.3391813 C47.6448598,28.7134503 48,24.5808967 48,19.9415205 C48,15.3021442 47.6448598,11.1890838 46.9345794,7.60233918 C46.2242991,4.01559454 44.9719626,1.83235867 43.1775701,1.05263158 C42.2803738,0.81871345 40,0.584795322 36.3364486,0.350877193 C32.6728972,0.116959064 28.5607477,0 24,0 C19.4392523,0 15.3271028,0.0974658869 11.6635514,0.292397661 C8,0.487329435 5.71962617,0.701754386 4.82242991,0.935672515 C3.02803738,1.79337232 1.77570093,4.01559454 1.06542056,7.60233918 C0.355140187,11.1890838 0,15.3021442 0,19.9415205 C0,24.5808967 0.355140187,28.7134503 1.06542056,32.3391813 C1.77570093,35.9649123 3.02803738,38.1676413 4.82242991,38.9473684 C5.71962617,39.2592593 8,39.5126706 11.6635514,39.7076023 C15.3271028,39.9025341 19.4392523,40 24,40 Z" id="" fill="#FFFFFF"></path>
               <path d="M19,26.2768203 L19,13.7231797 C19,13.1708949 19.4477153,12.7231797 20,12.7231797 C20.1740269,12.7231797 20.3450412,12.768595 20.4961389,12.8549365 L31.4805745,19.1317569 C31.9600919,19.4057668 32.1266887,20.0166215 31.8526787,20.4961389 C31.7640894,20.6511702 31.6356058,20.7796538 31.4805745,20.8682431 L20.4961389,27.1450635 C20.0166215,27.4190734 19.4057668,27.2524767 19.1317569,26.7729593 C19.0454153,26.6218616 19,26.4508473 19,26.2768203 Z" id="Path" fill="#337CBD"></path>
           </g>
        </g>
      </svg>
      `;
  hideheader: boolean = false;

  constructor(private auth: PloneAuthService,
              private changeDetectionRef: ChangeDetectorRef,
              private responsiveService: ResponsiveService,
              private router: Router,
              private activated: ActivatedRoute,
               private owncloudService: OwnCloudService,
              public newNotificationService: NewNotificationService,
              private broadcaster: Broadcaster,
              private notificationsService: VNCTalkNotificationsService,
              public configService: ConfigService,
              private userPreviewService: VNCUserPreviewService,
              private datetimeService: DatetimeService,
              private translate: TranslateService,
              private ownCloudService: OwnCloudService,
              private commonService: CommonService,
              private appStore: Store<RootState>,
              private conversationRepo: ConversationRepository,
              private avatarRepo: AvatarRepository,
              private appService: AppService,
              private xmppService: XmppService,
              private store: Store<TalkRootState>,
              private conferenceRepo: ConferenceRepository,
              private changeDetectorRef: ChangeDetectorRef,
              private jitsiService: JitsiService,
              private contactRepo: ContactRepository,
              private ngZone: NgZone,
              private electronService: ElectronService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog,
              private toastService: ToastService,
              private audioOutputService: AudioOutputService,
              private logger: LoggerService,
              private databaseService: DatabaseService, // Need it to init so do not remove it
              private conversationService: ConversationService,
              private userConfigRepo: UserConfigRepository, // Need it to init so do not remove it
              private rfcRepo: RFCRepository, // Need it to init so do not remove it
              private callKitService: CallkitService,
              private sanitizer: DomSanitizer,
              private channelRepository: ChannelRepository,
              private notificationService: NotificationService
              ) {
    this.logger.info("[RootComponent][constructor]");
    this.userPreviewService.setCopyToClipboard((text) => {
      CommonUtil.copyToClipboard([text]);
      this.notificationService.openSnackBarWithTranslation("COPIED_TO_CLIPBOARD", {}, 2000);
    });
    this.matIconRegistry.registerFontClassAlias("mdi");
    this.registerIcons();
    localStorage.setItem("environment", environment.theme);
    if (!!environment.enableOauthLogin) {
      localStorage.setItem("enableOauthLogin", "true");
    }
    this.matIconRegistry.addSvgIcon(
      `icon-product-date-new`,
      this.getTrustResourceURL(`/assets/svg/icon-product-date-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      "wave",
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/wave.svg`)
    );
    this.setupTranslationService();
    document.body.style.backgroundImage = "none";
    if (localStorage.getItem("isSupportedRTF") !== null) {
      this.configService.set("isSupportedRTF", localStorage.getItem("isSupportedRTF") === "true");
    }
    document.addEventListener("deviceready", this.deviceReady.bind(this), false);

    if (CommonUtil.isOnIOS()) {
      const restoredApnsToken = localStorage.getItem("iosApnsToken");
      if (!!restoredApnsToken) {
        this.apnsToken = restoredApnsToken;
      }
      const restoredVoipToken = localStorage.getItem("iosVoipToken");
      if (!!restoredVoipToken) {
        this.voipToken = restoredVoipToken;
      }
    }
    if ((CommonUtil.isOnIOS()) || (CommonUtil.isOnAndroid())) {
      this.callKitService.init();
      this.collapsed = true;
    }

    // Add an event listener if click on a conf join link in app
    document.addEventListener(ConstantsUtil.CONFERENCE_LINK_CLICK_EVENT, (e: any) => {
      this.logger.info(`[RootComponent][${ConstantsUtil.CONFERENCE_LINK_CLICK_EVENT}]`, e);

      const loc = new URL(e.detail);

      const eventData = {
        url: e.detail,
        path: loc.pathname
      };

      this.processConfJoinUniversalLink(eventData);
    });

    this.isOnElectron = this.electronService.isElectron;
    if (this.isOnElectron) {
      this.electronService.setBadgeCountListener();
    }

    if (CommonUtil.isMobileSize()) {
      document.body.classList.add("disable-select");
    }

    if (!this.configService.isAnonymous) {
      this.appStore.select(getIsConnectedXMPP).subscribe(v => {
        this.logger.info("[root.ts] is xmpp connected", v);
        this.isXmppConnected = v;
        this.changeDetectorRef.markForCheck();
      });

      try {
        this.hasStoredProfile = !!localStorage.getItem("token");
      } catch (error) {
        this.hasStoredProfile = false;
      }

      this.appStore.select(getIsLoggedIn).subscribe(loggedIn => {
        this.isLoggedIn = loggedIn;
        this.logger.info("[RootComponent][constructor getIsLoggedIn] loggedIn", loggedIn);
        if (loggedIn) {
          this.handleSharing();
        }

        this.changeDetectorRef.markForCheck();
      });
      this.appStore.select(getUserJID).subscribe(jid => {
        this.userJID = jid;
        localStorage.setItem("userJID", jid?.bare);
        this.configService.setUserJid(jid?.bare);
        this.logger.info("[RootComponent][constructor getUserJID] jid", jid);
        // ToDo: check why!
/*        if (jid !== null) {
          this.conversationRepo.handlePushNotification();
        } */
        this.changeDetectorRef.markForCheck();
      });
    }

    // network changes
    merge(
      fromEvent(window, "online").pipe(mapTo(true)),
      fromEvent(window, "offline").pipe(mapTo(false))
    ).subscribe(this.notifyConnectionStatus.bind(this));

    this.store.select(getNetworkInformation).subscribe(lastState => {
        if (!lastState) {
          return;
        }

        let isConnected = lastState.onlineState;

        this.logger.info("[RootComponent][getNetworkInformation] isConnected", isConnected, navigator.onLine);

        // if network changed
        if (this.isNetworkOnline !== isConnected) {
          if (isConnected && this.isCordovaOrElectron && !lastState.inBackground){
            this.sendFailedInlineRepliesAndMarkAsReadRequests();
          }

          this.isNetworkOnline = isConnected;
          this.store.dispatch(new OnlineStatus(isConnected));

          // lost connection alert
          if (this.isNetworkOnline) {
            // We are ON
            if (this.isDelayedRetrieveConfigRequired) {
              this.isDelayedRetrieveConfigRequired = false;
              setTimeout(() => {
                this.loadConfigOnly();
              });
            }
            if (this.isDelayedRetrieveInitialDataRequired) {
              this.isDelayedRetrieveInitialDataRequired = false;
              setTimeout(() => {
                this.loadInitialData();
              });
            }
          }
        }

        // edge case
        if (!isConnected && navigator.onLine) {
          this.store.dispatch(new UpdateNetworkInformation({
            onlineState: navigator.onLine,
            connectionType: (lastState && lastState.connectionType) || "unknown"
          }));
        }
    });

    // Saved the route so we can use one variable for multiple purpose
    // and stored the subscription to avoid multiple subscribes
    if (this.subscriber === null) {
      this.subscriber = router.events.subscribe((evt) => {
        // TODO should have used route.snapshot.data but for some reason that wasn't working
        if (evt instanceof NavigationEnd) {
          this.fullRoute = this.router.url;
          // this.logger.info("[fullRoute]", this.router.url);

          let url: any = this.activated.snapshot.firstChild.url;
          url = url.length > 0 ? url[0].path : "";
          this.currentRoute = url;
          this.isProfile = (this.currentRoute === "profile");
          this.changeDetectorRef.markForCheck();
        }
      });
    }

    if (window.outerHeight < 800) {
      this.options.maxStack = 3;
    }

    this.conversationRepo.getSelectedConversation().pipe(takeUntil(this.isAlive$)).subscribe(conv => {
      // this.logger.info("[RootComponent][getSelectedConversation]", this.selectedConversation, conv);

      const needToShowActiveCallNotificationIfRequired = !conv || !this.selectedConversation || (this.selectedConversation?.Target !== conv?.Target);

      this.selectedConversation = conv;

      if (needToShowActiveCallNotificationIfRequired) {
        this.showActiveCallNotificationIfRequired();
      }

      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getIsAppOnline).pipe(takeUntil(this.isAlive$)).subscribe((online) => {
      this.isOnline = online;
      if (!!online) {
        this.appStore.select(getUserJID).pipe(filter(v => !!v && this.configService.get("ownCloudURL")), take(1)).subscribe((v) => {
          const username = v?.bare.split("@")[0];
          this.ownCloudService.initCredentials(this.configService.get("ownCloudURL"), username, localStorage.getItem("token"));
        });
      }
      this.logger.info("[root.ts] is online", online);
      this.changeDetectorRef.markForCheck();
    });

    this.conferenceRepo.getActiveConference().subscribe(conversationTarget => {
      if (conversationTarget && conversationTarget !== null) {
        this.logger.info("[RootComponent][getActiveConference]", conversationTarget);

        if (this.isOnCordova && navigator.proximity) {
          if (environment.isCordova && device && (device.manufacturer === "Google") && (device.model.startsWith("Pixel 3"))) {
            this.logger.info("[RootComponent][skipProximity] detected device with defect in proximity support for Pixel 3");
          } else {
            navigator.proximity.enableSensor();
            navigator.proximity.enableProximityScreenOff();
          }
        }

        this.showVideoChat = true;
        if (CommonUtil.isOnNativeMobileDevice()) {
          this.logger.info("[RootComponent][getActiveConference] keepAwake");
          window.plugins.insomnia.keepAwake(() => {
            this.logger.info("[RootComponent][getActiveConference] keepAwake success");
          }, error => {
            this.logger.error("[RootComponent][getActiveConference] keepAwake error", error);
            this.logger.sentryErrorLog("[RootComponent][getActiveConference] keepAwake error", error);
          });
        }
      } else {
        this.logger.info("[RootComponent][getActiveConference] no");

        if (environment.isCordova && navigator.proximity) {
          navigator.proximity.disableSensor();
          navigator.proximity.disableProximityScreenOff();
        }

        this.showVideoChat = false;

        if (this.CommonUtil.isOnAndroid()) {
          cordova.plugins.backgroundMode.disable();
        }

        // Need to call it to bypass lock screen when accepted a call.
        // We should back this property to 'false' once call is finished.
        if (localStorage.getItem("enabledLockScreenVisibility") && this.CommonUtil.isOnAndroid() && !conversationTarget) {
          this.logger.info("[RootComponent][getActiveConference] finished");
          localStorage.removeItem("enabledLockScreenVisibility");
          setTimeout(() => {
            this.logger.info("[RootComponent][getActiveConference] enableLockScreenVisibility false");
            if (window.FirebasePlugin && window.FirebasePlugin.enableLockScreenVisibility) {
              window.FirebasePlugin.enableLockScreenVisibility(false);
            }
          }, 1500);
        }

        if (CommonUtil.isOnNativeMobileDevice()) {
          this.logger.info("[RootComponent][getActiveConference] allowSleepAgain");
          window.plugins.insomnia.allowSleepAgain(() => {
            this.logger.info("[RootComponent][getActiveConference] allowSleepAgain success");
          }, error => {
            this.logger.error("[RootComponent][getActiveConference] allowSleepAgain error", error);
            this.logger.sentryErrorLog("[RootComponent][getActiveConference] allowSleepAgain error", error);
          });
        }
      }

      this.activeConferenceTarget = conversationTarget;

      this.changeDetectorRef.markForCheck();
    });

    this.jitsiService.getCallDuration().subscribe(res => {
      if (this.showActiveCall) {
        this.videoTime = res;
        this.changeDetectorRef.markForCheck();
      }
    });

    this.conferenceRepo.getParticipants().subscribe(res => {
      this.participants = res;
      this.changeDetectorRef.markForCheck();
    });

    this.conferenceRepo.getHasActiveCall().subscribe(showActiveCall => {
      this.logger.info("[RootComponent][getHasActiveCall]", showActiveCall);

      this.showActiveCall = showActiveCall;
      this.changeDetectorRef.markForCheck();
    });

    this.store.select(getIsAppBootstrapped).pipe(distinctUntilChanged()).subscribe(v => {
      if (!this.bootstrapped) {
        if (!!v && !!localStorage.getItem("token")) {
          this.bootstrapped = true;
          this.logger.info("[RootComponent][getIsAppBootstrapped]", v);
          setTimeout(() => {
            this.logger.info("[RootComponent][getIsAppBootstrapped] recheckConfigs");
            this.recheckConfigs();
            this.databaseService.cleanupOmemoErrors().subscribe();
          }, 15000);
          setTimeout(() => {
            this.broadcaster.broadcast("checkOmemoQueue");
          }, 3000);
        }
      }
    });
    if (window.location !== window.parent.location) {
      this.hideheader = true;
     }
  }

  private notifyConnectionStatus(isConnected: boolean) {
    let connectionType = "unknown";
    if (navigator.connection) {
      connectionType = navigator.connection.type ? navigator.connection.type : navigator.connection.effectiveType || "unknown";
    }
    this.logger.info(new Date().toISOString() + " [RootComponent][notifyConnectionStatus] isOnline: ", isConnected, connectionType, navigator.connection);
    //
    this.store.dispatch(new UpdateNetworkInformation({
      onlineState: isConnected,
      connectionType: connectionType
    }));
  }

  private showActiveCallNotificationIfRequired() {
    this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(activeConfTarget => {
      // this.logger.info("[RootComponent][showActiveCallNotificationIfRequired]", this.selectedConversation);

      if (activeConfTarget) {
        if (!this.selectedConversation || activeConfTarget !== this.selectedConversation?.Target) {
          if (this.jitsiService.isJoined) {
            this.displayActiveCallNotification(activeConfTarget);
          }
        } else {
          this.hideActiveCallNotification();
        }
      } else {
        this.hideActiveCallNotification();
      }
    });
  }

  private hideActiveCallNotification() {
    if ((!CommonUtil.isOnMobileDevice() || CommonUtil.isOnIpad())) {
      this.notificationsService.remove(null, "active");
    }
  }

  private displayActiveCallNotification(conversationTarget) {
    this.logger.info("[RootComponent][displayActiveCallNotification]", conversationTarget, this.selectedConversation);

    if ((this.selectedConversation && this.selectedConversation.conferenceType !== "screen")
      || CommonUtil.isOnMobileDevice()) {
      this.conferenceRepo.showActiveCall();
    }

    // // detach video tracks from HTML
    // this.jitsiService.detachAllTracks();

    // Web/iPad -> show an active call notification
    // if ((!CommonUtil.isOnMobileDevice() || CommonUtil.isOnIpad()) && conversationTarget !== null) {
    //   this.store.select(state => getConversationById(state, conversationTarget))
    //     .pipe(take(1))
    //     .subscribe();
    // }
  }

  isMobileSize() {
    return CommonUtil.isMobileSize();
  }

  private handleBackButton(): void {

    document.addEventListener("backbutton", (e) => {
      this.logger.info("[backbutton] event", e);
      if (CommonUtil.isOnMobileDevice()) {
        this.broadcaster.broadcast("hideSwipeAction");
      }
      let sharingStatus = false;
      this.store.select(getIsSharing).pipe(take(1)).subscribe(isSharing => sharingStatus = isSharing);

      if (sharingStatus) {
        return;
      }
      if (typeof QRScanner !== "undefined") {
        QRScanner.getStatus((status) => {
          this.logger.info(status);
          if (status.scanning || status.showing) {
            QRScanner.destroy();
          }
        });
      }

      if (document.querySelector("#registrationIframe") !== null) {
        document.querySelector("#registrationIframe").remove();

      } else if (document.querySelector("#forgotPasswordIframe") !== null) {
        document.querySelector("#forgotPasswordIframe").remove();
      } else if (document.querySelector("vp-vncmeet") !== null) {
        navigator.app.exitApp();
      } else if (document.querySelector("#loginIframe") !== null) {
        navigator.app.exitApp();
        let myEvent = new CustomEvent("logoutXmpp", {
          detail: e.data
        });
        document.querySelector("body").dispatchEvent(myEvent);
      } else if (document.querySelector("vp-new-conversation-mobile") !== null || document.querySelector("vp-start-chat") !== null) {
        this.broadcaster.broadcast("hideStartConversationDialog");
      }  else if (document.querySelector("vp-settings") !== null) {
        this.broadcaster.broadcast("hideMobileSettings");
      } else if (document.querySelector("vp-mobile-notification-center") !== null) {
        this.broadcaster.broadcast("hideNotificationCenter");
      } else if (document.querySelector("vp-app-dialog") !== null) {
        this.broadcaster.broadcast("hideStartConversationDialog");
      } else if (document.querySelector("vp-view-result") !== null) {
        this.broadcaster.broadcast("closeSurveyResult");
      } else if (document.querySelector("vp-file-preview-dialog") !== null) {
        this.broadcaster.broadcast("closePreviewDialog");
      } else if (document.querySelector("vp-view-survey") !== null) {
        this.broadcaster.broadcast("closeSurvey");
      } else if (document.querySelector("vp-survey") !== null) {
        this.broadcaster.broadcast("hideSurvey");
      } else if (document.querySelector("vp-scheduled-conferences") !== null) {
        this.broadcaster.broadcast("hideScheduledConferences");
      } else if (document.querySelector("vp-add-participants") !== null) {
        this.broadcaster.broadcast("closeAddParticipantDialog");
      } else if (document.querySelector("vp-omemo-hit-max-limit") !== null) {
        this.broadcaster.broadcast("closeOMEMODialog");
      } else if (document.querySelector(".action-menu-backdrop") !== null) {
        this.broadcaster.broadcast("CLOSE_ACTION_MENU");
      } else if (document.querySelector("vp-profile-info-dialog") !== null) {
        this.broadcaster.broadcast("CLOSE_PROFILE_DIALOG");
      }  else if (document.querySelector("vp-chat-avatar-upload-dialog") !== null) {
        this.broadcaster.broadcast("hideChatUploadAvatarComponent");
      } else if (document.querySelector("vp-chat-right-sidebar") !== null) {
        this.broadcaster.broadcast("CLOSE_SIDEBAR_DIALOG");
      } else if (document.querySelector("vp-send-channel-link") !== null) {
        this.logger.info("[vp-send-channel-link] Backbutton press");
        this.broadcaster.broadcast("CLOSE_CHANNEL_SEND_TO_DIALOG");
      } else if (document.querySelector(".crop-avatar-header") !== null) {
        this.broadcaster.broadcast("hideCropAvatar");
      } else if (document.querySelector(".transfer-omemo-component") !== null) {
        this.broadcaster.broadcast("HIDE_OMEMO_DIALOG");
      } else if (document.querySelector("vp-send-topic-link") !== null) {
        this.logger.info("[vp-send-topic-link] Backbutton press");
        this.broadcaster.broadcast("CLOSE_TOPIC_SEND_TO_DIALOG");
      } else if (document.querySelector("vp-move-to-channel") !== null) {
        this.logger.info("[vp-move-to-channel] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOVE_CHANNEL_DIALOG");
      } else if (document.querySelector("#tfaOtpIframe") !== null) {
        this.configService.hideTfaOtpIframe();
        this.configService.loginIframe();
      } else if (document.querySelector("vp-update-avatar") !== null) {
        this.broadcaster.broadcast("closeUploadAvatarDialog");
      } else if (document.querySelector("vp-meeting-settings-dialog") !== null) {
        this.broadcaster.broadcast("closeMeetingDialog");
      } else if (document.querySelector("vp-mobile-schedule-dialog") !== null) {
        this.broadcaster.broadcast("closeScheduleDialog");
      }  else if (document.querySelector("vp-editor-dialog") !== null) {
        this.broadcaster.broadcast("closeDescriptionDialog");
      }  else if (document.querySelector("vp-owncloud-public") !== null
      || document.querySelector("vp-owncloud-upload") !== null
      || document.querySelector("vp-mobile-schedule-dialog") !== null
      || document.querySelector("vp-mcb-list-mobile") !== null
      || document.querySelector("vp-owncloud-share") !== null) {
        this.broadcaster.broadcast("closeDialog");
      } else if (document.querySelector("vnc-document-preview") !== null) {
        this.broadcaster.broadcast("closeDocumentPreview");
      } else if (document.querySelector("vp-owncloud-detail") !== null) {
        this.broadcaster.broadcast("hideDetailsDialog");
      } else if (document.querySelector("vp-files-dialog") !== null) {
        this.broadcaster.broadcast("hideFilesDialog");
      } else if (document.querySelector("vp-select-app-dialog") !== null) {
        this.broadcaster.broadcast("closeSelectAppDialog");
      } else if (document.querySelector(".media-preview-dialog-component") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_PREVIEW_IMAGE);
      } else if (document.querySelector("vp-rfc-dialog") !== null) {
        this.broadcaster.broadcast("hideRfcDialog");
      } else if (document.querySelector("vp-invite-user-dialog") !== null) {
        this.broadcaster.broadcast("hideInviteUserDialog");
      } else if (document.querySelector("vp-upgrade-account-dialog") !== null) {
        this.broadcaster.broadcast("hideUpgradeAccountDialog");
      } else if (document.querySelector("vp-pad-dialog") !== null) {
        this.broadcaster.broadcast("closePadMenu");
      } else if (document.querySelector("vp-groupchat-info") !== null) {
        this.broadcaster.broadcast("closeGroupInfo");
      } else if (document.querySelector("vp-groupchat-information") !== null) {
        this.broadcaster.broadcast("closeUserInfoDialog");
      } else if (document.querySelector("vp-create-pad") !== null) {
        this.broadcaster.broadcast("closeCreatePadForm");
      } else if (document.querySelector(".add-participants-dialog-component") !== null) {
        this.broadcaster.broadcast("hideStartVideoMeeting");
      } else if (document.querySelector("vp-conversation-history") !== null && document.querySelector(".mobile-action-backdrop.show-search-form .show-participants") !== null) {
        this.broadcaster.broadcast("hideParticipantsList");
      }  else if (document.querySelector("vp-conversation-history") !== null && document.querySelector(".mobile-action-backdrop.show-search-form") !== null) {
        this.broadcaster.broadcast("hideSearchGroupChatsForm");
      } else if (document.querySelector("vp-new-broadcast") !== null && document.querySelector("vp-add-users-to-list") !== null) {
        this.broadcaster.broadcast("hideAddUserToList");
        this.logger.info("[root.ts] onBacKButton", "hideAddUserToList");
      } else if (document.querySelector("vp-new-broadcast") !== null && document.querySelector("vp-create-list-new") !== null) {
        this.broadcaster.broadcast("hideCreateList");
        this.logger.info("[root.ts] onBacKButton", "hideCreateList");
      }  else if (document.querySelector("vp-new-broadcast") !== null && document.querySelector("vp-add-contact") !== null) {
        this.broadcaster.broadcast("hideAddContact");
        this.logger.info("[root.ts] onBacKButton", "hideAddContact");
      } else if (document.querySelector("vp-new-broadcast") !== null) {
        this.broadcaster.broadcast("hideNewBroadcast");
        this.logger.info("[root.ts] onBacKButton", "hideNewBroadcast");
      } else if (document.querySelector("vp-legal-notice-dialog") !== null) {
        this.broadcaster.broadcast("hideLegalNoticeDialog");
        this.logger.info("[root.ts] onBacKButton", "hideLegalNoticeDialog");
      } else if (document.querySelector("body.show-camera") !== null) {
        CommonUtil.destroyQRScanCode();
      } else if (document.querySelector("vp-electron-screen-sharing-dialog") !== null) {
        this.broadcaster.broadcast("hideElectronScreenSharingDialog");
        this.logger.info("[root.ts] onBacKButton", "hideElectronScreenSharingDialog");
      } else if (document.querySelector("vp-oauth-login") !== null) {
        localStorage.removeItem("scanQRCode");
        this.ngZone.run(() => {
          this.loginWithOAuth = false;
          this.changeDetectorRef.markForCheck();
        });
        this.configService.loginIframe();
        this.logger.info("[root.ts] onBacKButton", "vp-oauth-login");
      } else if (document.querySelector("vp-update-avatar") !== null) {
        this.broadcaster.broadcast("hideUploadAvatar");
        this.logger.info("[root.ts] onBacKButton", "hideUploadAvatar");
      } else if (document.querySelector("vp-mobile-app-settings") !== null) { // TODO: ADDED BY HUY - Move event to broadcaster service
        this.logger.info("[root.ts] onBacKButton", "hideMobileAppSettings");
        this.broadcaster.broadcast("hideMobileAppSettings");
      } else if (document.querySelector("vp-app-settings") !== null) { // TODO: ADDED BY HUY - Move event to broadcaster service
        this.broadcaster.broadcast("hideAppSettings");
        this.logger.info("[root.ts] onBacKButton", "hideAppSettings");
      }  else if (document.querySelector("vp-dialog-conversation-menu") !== null) {
        this.broadcaster.broadcast("hideConversationMenu");
        this.logger.info("[root.ts] onBacKButton", "hideConversationMenu");
      }  else if (document.querySelector("vp-mobile-bottom-sheet-topic-channel-menu") !== null) {
        this.broadcaster.broadcast("hideBottomSheetTopicChannelMenu");
        this.logger.info("[root.ts] onBacKButton", "hideBottomSheetTopicChannelMenu");
      } else if (document.querySelector("vp-mobile-bottom-sheet-chat-actions-menu") !== null) {
        this.broadcaster.broadcast("hideChatActionsBottomMenu");
        this.logger.info("[root.ts] onBacKButton", "hideChatActionsBottomMenu");
      } else if (document.querySelector("vp-mobile-bottom-sheet-add-actions-menu") !== null) {
        this.broadcaster.broadcast("hideAddActionsBottomMenu");
        this.logger.info("[root.ts] onBacKButton", "hideAddActionsBottomMenu");
      } else if (document.querySelector("vp-user-info") !== null) {
        this.broadcaster.broadcast("closeUserInfoDialog");
        this.logger.info("[root.ts] onBacKButton", "closeUserInfoDialog");
      } else if (document.querySelector("vp-edit-profile") !== null) {
        this.broadcaster.broadcast("hideEditProfile");
        this.logger.info("[root.ts] onBacKButton", "hideEditProfile");
      } else if (document.querySelector(".forward-dialog-component") !== null) {
        this.broadcaster.broadcast("hideForwardComponent");
        this.logger.info("[root.ts] onBacKButton", "hideForwardComponent");
      } else if (document.querySelector(".preview-image-dialog-component") !== null) {
        this.broadcaster.broadcast("hidePreviewImage");
        this.logger.info("[root.ts] onBacKButton", "hidePreviewImage");
      } else if (document.querySelector("vp-user-menu") !== null) {
        this.broadcaster.broadcast("hideUserMenu");
        this.logger.info("[root.ts] onBacKButton", "hideUserMenu");
      } else if (document.querySelector("#vp-talk-profile-card-container") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.DEACTIVATE_PROFILE_CARD);
        this.logger.info("[root.ts] onBacKButton", BroadcastKeys.DEACTIVATE_PROFILE_CARD);
      } else if (document.querySelector("vp-help-dialog") !== null) {
        this.broadcaster.broadcast("hideHelpDialog");
        this.logger.info("[root.ts] onBacKButton", "hideHelpDialog");
      } else if (document.querySelector(".changelog-component") !== null) {
        this.broadcaster.broadcast("hideChangelog");
        this.logger.info("[root.ts] onBacKButton", "hideChangelog");
      } else if (document.querySelector("vp-about-dialog") !== null) {
        this.broadcaster.broadcast("hideAboutDialog");
        this.logger.info("[root.ts] onBacKButton", "hideAboutDialog");
      } else if (document.querySelector("vp-service-desk") !== null) {
        this.broadcaster.broadcast("hideServiceDesk");
        this.logger.info("[root.ts] onBacKButton", "hideServiceDesk");
      } else if (document.querySelector("vp-create-list") !== null) {
        this.broadcaster.broadcast("hideCreateList");
        this.logger.info("[root.ts] onBacKButton", "hideCreateList");
      } else if (document.querySelector("vp-all-settings") !== null) {
        this.broadcaster.broadcast("hideAllSettings");
        this.logger.info("[root.ts] onBacKButton", "hideAllSettings");
      } else if (document.querySelector("vp-global-chat") !== null && document.querySelector("vp-add-users-to-list") !== null) {
        this.broadcaster.broadcast("hideAddUserToList");
        this.logger.info("[root.ts] onBacKButton", "hideAddUserToList");
      } else if (document.querySelector("vp-global-chat") !== null && document.querySelector("vp-create-list-new") !== null) {
        this.broadcaster.broadcast("hideCreateList");
        this.logger.info("[root.ts] onBacKButton", "hideCreateList");
      }  else if (document.querySelector("vp-global-chat") !== null && document.querySelector("vp-add-contact") !== null) {
        this.broadcaster.broadcast("hideAddContact");
        this.logger.info("[root.ts] onBacKButton", "hideAddContact");
      } else if (document.querySelector("vp-global-chat") !== null && document.querySelector("vp-global-chat .show-search-form") !== null) {
        this.broadcaster.broadcast(ConstantsUtil.HIDE_SEARCH_HEADER);
        this.logger.info("[root.ts] onBacKButton", "hideSearchForm");
      } else if (document.querySelector("vp-edit-group") !== null) {
        this.broadcaster.broadcast("hideEditGroup");
        this.logger.info("[root.ts] onBacKButton", "hideEditGroup");
      } else if (document.querySelector("vp-delete-group") !== null) {
        this.broadcaster.broadcast("hideDeleteGroup");
        this.logger.info("[root.ts] onBacKButton", "hideDeleteGroup");
      } else if (document.querySelector("vp-confirmation-channel") !== null) {
        this.logger.info("[vp-confirmation-channel] Backbutton press");
        this.broadcaster.broadcast("CLOSE_DELETE_CONFIRM_DIALOG");
      } else if (document.querySelector("vp-send-email-to-friends") !== null) {
        this.logger.info("[vp-send-email-to-friends] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SEND_EMAIL_TO_FRIEND_DIALOG");
      } else if (document.querySelector("vp-file-preview") !== null) {
        this.logger.info("[vp-file-preview] Backbutton press");
        this.broadcaster.broadcast("CLOSE_CHANNEL_FILE_PREVIEW_DIALOG");
      } else if (document.querySelector("vp-subscribe-invitation") !== null) {
        this.logger.info("[vp-subscribe-invitation] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SUBSCRIBE_INVITE_DIALOG");
      } else if (document.querySelector("vp-topic-right-bar") !== null) {
        this.logger.info("[vp-topic-right-bar] Backbutton press");
        this.broadcaster.broadcast("CLOSE_TOPIC_RIGHTBAR_DIALOG");
      } else if (document.querySelector("vp-channel-right-bar") !== null) {
        this.logger.info("[vp-channel-right-bar] Backbutton press");
        this.broadcaster.broadcast("CLOSE_CHANNEL_RIGHTBAR_DIALOG");
      } else if (document.querySelector("vp-global-chat") !== null) {
        this.logger.info("root.component broadcast ConstantsUtil.TOGGLE_SIDEBAR");
        this.broadcaster.broadcast(ConstantsUtil.TOGGLE_SIDEBAR);
        this.logger.info("[root.ts] onBacKButton", "close rosters list");
      } else if (document.querySelector(".mdl-dialog") !== null) {
        this.broadcaster.broadcast("closeDialog");
        this.logger.info("[root.ts] onBacKButton", "closeDialog");
      } else if (document.querySelector("vp-archive") === null && document.querySelector(".user-menu") !== null && document.querySelector("mdl-dialog-host-component") === null) {
        this.broadcaster.broadcast(ConstantsUtil.HIDE_USER_MENU);
      } else if (document.querySelector("sidebar-container") !== null) {
        this.hideSidebar();
        this.logger.info("[root.ts] onBacKButton", "closeSidebar");
      } else if (document.querySelector(".vnc-chat-window-message-action-header") !== null) {
        this.broadcaster.broadcast("hideMessageSelection");
        this.logger.info("[root.ts] onBacKButton", "hideMessageSelection");
      } else if (document.querySelector(".vnc-chat-window-search-header") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.hideSearchComponent);
        this.logger.info("[root.ts] onBacKButton", "hideSearchComponent");
      } else if (document.querySelector("vp-video-conference") !== null && document.querySelector(".vt-video-call.hide-mobile") === null) {
        this.broadcaster.broadcast("showTextChat");
        this.logger.info("[root.ts] onBacKButton", "showTextChat");
      } else if (document.querySelector("vp-chat-window") !== null) {
        this.broadcaster.broadcast("goBackConversationList");
        this.logger.info("[root.ts] onBacKButton", "goBackConversationList");
        this.showActiveCallNotificationIfRequired();
      } else if (document.querySelector(".media-options-component") !== null) {
        this.broadcaster.broadcast("hideMediaOptionsDialog");
      } else if (document.querySelector("vp-mobile-channel-list-selection-dialog") !== null) {
        this.logger.info("[vp-mobile-channel-list-selection-dialog] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOBILE_CHANNEL_LIST_DIALOG");
      } else if (document.querySelector("vp-channel-attach-dialog") !== null) {
        this.logger.info("[vp-channel-attach-dialog] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOBILE_TOPIC_ATTACHMENT_DIALOG");
      } else if (document.querySelector("vp-topic-related-object-dialog") !== null) {
        this.logger.info("[vp-topic-related-object-dialog] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOBILE_CREATE_TOPIC_RELATED_TOPIC_DIALOG");
      } else if (document.querySelector("vp-create-topic-settings-dialog") !== null) {
        this.logger.info("[vp-create-topic-settings-dialog] Backbutton press");
        this.broadcaster.broadcast("CLOSE_CREATE_TOPIC_SETTINGS_DIALOG");
      } else if (document.querySelector("vp-sticky-note") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_STICKY_NOTE_DIALOG);
      } else if (document.querySelector("vp-add-remark-popup") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_ADD_REFERENCE_DIALOG);
      } else if (document.querySelector("vp-user-select-mobile") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_USER_SELECT_MOBILE_DIALOG);
      } else if (document.querySelector("vp-smart-link-filter") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_SMART_LINK_FILTER_DIALOG);
      } else if (document.querySelector("vp-smart-link-popup") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_SMART_LINK_POPUP_DIALOG);
      } else if (document.querySelector("vp-smart-link-preview") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_SMART_LINK_PREVIEW_DIALOG);
      } else if (document.querySelector("vp-smart-objects-preview") !== null) {
        this.broadcaster.broadcast(CloseSmartObjectDialog.CLOSE_SMART_OBJECTS_PREVIEW_DIALOG);
      }  else if (document.querySelector("vp-editor-dialog") !== null) {
        this.logger.info("[vp-editor-dialog] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOBILE_EDITOR_DESCRIPTION_DIALOG");
      } else if (document.querySelector("vp-channel-add-edit-comment") !== null) {
        this.logger.info("[vp-channel-add-edit-comment] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOBILE_ADD_EDIT_COMMENT_DIALOG");
      } else if (document.querySelector("vp-create-topic") !== null) {
        this.logger.info("[vp-create-topic] Backbutton press");
        this.broadcaster.broadcast("closeCreateTopicChannelDialog");
      } else if (document.querySelector("vp-create-channel") !== null) {
        this.logger.info("[vp-create-channel] Backbutton press");
        this.broadcaster.broadcast("closeCreateChannelDialog");
      } else if (document.querySelector("vp-mobile-create-social-post-dialog") !== null) {
        this.logger.info("[vp-mobile-create-social-post-dialog] Backbutton press");
        this.broadcaster.broadcast("CLOSE_MOBILE_CREATE_SOCIAL_POST_DIALOG");
      } else if (document.querySelector("vp-create-social-post") !== null) {
        this.logger.info("[vp-create-social-post] Backbutton press");
        this.broadcaster.broadcast("CLOSE_CREATE_SOCIAL_POST_DIALOG");
      } else if (document.querySelector("vp-topic-detail") !== null) {
        this.logger.info("[vp-topic-detail] Backbutton press");
        this.broadcaster.broadcast("backTopicDetail");
      } else if (document.querySelector("vp-start-conference") !== null) {
        this.broadcaster.broadcast("closeStartConferenceDialog");
      } else if (document.querySelector("vp-channel-detail") !== null) {
        this.logger.info("[vp-channel-detail] Backbutton press");
        this.ngZone.run(() => {
          this.router.navigateByUrl("/talk/channels");
          setTimeout(() => {
            this.broadcaster.broadcast("showLeftPanel");
          }, 50);
        });
      } else if (document.querySelector("vp-social-topic-detail") !== null) {
        this.logger.info("[vp-social-topic-detail] Backbutton press");
        const element: any = document.querySelector("vp-social-topic-detail .social-topic-detail-mobile .back-item");
        if (element !== null) {
          element.click();
        }
      } else if (document.querySelector("vp-social-topics") !== null) {
        this.logger.info("[vp-social-topics] Backbutton press");
        this.broadcaster.broadcast("BACK_TO_CHANNEL_LIST");
      } else if (document.querySelector("vp-create-post-social") !== null) {
        this.logger.info("[vp-create-post-social] Backbutton press");
        this.broadcaster.broadcast("CLOSE_CREATE_POST_DIALOG");
      } else if (document.querySelector("vp-social-profile-card-component") !== null) {
        this.logger.info("[vp-social-profile-card-component] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SOCIAL_POPUP_DIALOG");
      } else if (document.querySelector("vp-social-file-preview") !== null) {
        this.logger.info("[vp-social-file-preview] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SOCIAL_POST_FILE_PREVIEW_DIALOG");
      } else if (document.querySelector("vp-social-follow-unfollow") !== null) {
        this.logger.info("[vp-social-follow-unfollow] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SOCIAL_FOLLOW_UNFOLLOW");
      } else if (document.querySelector("vp-social-bookmarked-posts") !== null) {
        this.logger.info("[vp-social-bookmarked-posts] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SOCIAL_BOOKMARK");
      } else if (document.querySelector("vp-social-live-post-detail") !== null) {
        this.logger.info("[vp-social-live-post-detail] Backbutton press");
        this.broadcaster.broadcast("BACK_TO_SOCIAL_STREAM");
      } else if (document.querySelector("vp-user-social-profile") !== null) {
        this.logger.info("[vp-user-social-profile] Backbutton press");
        this.broadcaster.broadcast("CLOSE_SOCIAL_PROFILE_COMPONENT");
      }
      if (document.querySelector("vp-chat-media") !== null && document.querySelector(".preview-image-dialog-component") === null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CHAT_MEDIA_COMPONENT);
      }

      if (document.querySelector("vp-search-window") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.hideSearchComponent);
      }

      if (document.querySelector("vp-search-screen") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.closeSearchScreen);
      }

      if (document.querySelector("vp-archive") !== null) {
        this.logger.info("[root.ts] onBacKButton", "hideArchiveScreen");
        this.logger.info("[root.ts] onBacKButton", "hideArchiveScreen");
        this.broadcaster.broadcast(BroadcastKeys.hideArchiveScreen);
      }

      if (document.querySelector("vp-media-menu") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CHAT_MEDIA_MENU);
      }
      if (document.querySelector("vp-email-selected-message") !== null) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_EMAIL_POPUP);
      }
      if (document.getElementsByClassName("back-icon").length) {
        this.broadcaster.broadcast("closeSeeAllOptions");
      } else if(document.querySelector("vnc-global-search-mobile-s") !== null){
        this.broadcaster.broadcast("closeGlobalSearch");
      }


      setTimeout(() => {
        this.ngZone.run(() => {
          this.changeDetectorRef.markForCheck();
        });
        if (document.querySelectorAll(".dialog-backdrop").length > 0) {
          let element = <HTMLElement>document.querySelectorAll(".dialog-backdrop")[document.querySelectorAll(".dialog-backdrop").length - 1];
          element.style.display = "none";
          element = null;
        }
      }, 500);
      this.logger.info("[root.ts] onBackButton", "end");
    }, false);
  }

  private handleEvents(): void {

    this.broadcaster.on<boolean>("toggle_header").subscribe(data => {
      this.logger.info("[RootComponent] toggle_header", data);
      if (!document.querySelector("vp-pad-window")) {
        this.showHeader = !data;

        if (!this.showHeader) {
          this.hideDrawer = true;
        }
        this.changeDetectorRef.markForCheck();
      }
    });

    this.broadcaster.on<boolean>("showHeader").subscribe(v => {
      this.logger.info("[RootComponent] showHeader", v);
      if (!document.querySelector("vp-pad-window")) {
        this.showHeader = v;
        if (!this.showHeader) {
          this.hideDrawer = true;
        }
        this.changeDetectorRef.markForCheck();
      }
    });

    this.broadcaster.on<Recipient>("showUserInfo").subscribe(recipient => {
      this.showUserInfo(recipient);
      this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on<Recipient>("showUserInfoOnHover").subscribe(recipient => {
      this.showUserInfoOnHover(recipient);
      this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on<boolean>("showDialog").subscribe(data => {
      this.showDialog = data;
      this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("togglePinConversation").subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("toggleConversationFavorite").subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("moveToIndex").subscribe(() => {
      this.conversationRepo.navigateToConversation(null);
    });

    this.broadcaster.on<any>("HIDEINCOMINGCALLNOTIFICATION").subscribe(params => {
      // (target: string, cancelCallkitCall = true
      this.notificationsService.hideIncomingCallNotification(params.target, params.cancelCallkitCall);
    });

    this.broadcaster.on<any>("electronOpenUri").subscribe(async (messageData) => {
        this.logger.info("[root.component][openUri] message: ", event, messageData);
        let data = new URL(messageData);
        try {
          const qs = await normalizeCommonJSImport(import("qs-native"));
          let path = data.pathname.split("//main")[1];
          let params = qs.parse(data.search);
          this.logger.info("[root.component][openUri] data: ", data);
          this.logger.info("[root.component][openUri] params: ", params, path);
          this.logger.info("[root.component][openUri] isOnIOS: ", CommonUtil.isOnIOS());
          if ((["start-chat", "audio-call", "video-call"].indexOf(params.action) > -1) && !!params.target) {
            this.logger.info("[root.component][openUri] will navigate to: ", params.target);
            setTimeout(() => {
              this.conversationRepo.navigateToConversation(params.target);
            }, 500);
          }

          if (params && path === "/talk/trigger") {
            if (CommonUtil.isOnIOS() && !!params.target) {
              this.logger.info("[root.component][openUri] will navigate to: ", params.target);
              setTimeout(() => {
                this.conversationRepo.navigateToConversation(params.target);
              }, 500);
            } else {
              this.router.navigate([path], { queryParams: params });
            }
          }
          if (data.href === "vnctalk://talk/channels") {
            this.ngZone.run(() => {
              this.logger.info("[root.component][openUri] navigate to channels");
              this.router.navigateByUrl("/talk/channels");
            });
          }
        } catch (e) {
          this.logger.error("erro in openUri: ", e);
          this.logger.sentryErrorLog("erro in openUri: ", e);
        }
        try {
          if (data.pathname.indexOf("//vncmeet") !== -1) {
            this.logger.info("[root.component][openUri] join call: ", data.pathname.replace("//vncmeet", "/vncmeet"));
            this.store.select(getIsConnectedXMPP).
              pipe(filter(isConnectedXMPP => !!isConnectedXMPP)
              , take(1)
              , distinctUntilChanged())
              .subscribe(() => {
                this.logger.warn("[trigger][join-meeting] connected");

                setTimeout(() => {
                  this.logger.warn("[trigger][join-meeting] joining ");
                  this.joinVideoCallFromLink(data.pathname.replace("//vncmeet", "/vncmeet"));
                }, 300);
              });

          }
        } catch (e) {
          this.logger.error("erro in openUri: ", e);
          this.logger.sentryErrorLog("erro in openUri: ", e);
        }
    });

    this.broadcaster.on<any>("stopAllPreviewMediaStreams").subscribe(() => {
      this.jitsiService.stopAllPreviewMediaStreams();
    });

    this.broadcaster.on<any>("USER_REQUESTED_ACCES").subscribe(data => {
      this.notificationsService.joinGroupRequestNotification(data.target, data.message, data.target);
    });


    this.broadcaster.on<any>("remindTFA").subscribe(async (r) => {
      const vncDirectoryPublicUrl = this.configService.get("vncDirectoryPublicUrl");
      // this.logger.info("root.component remindTFA userconfigdata: ", r, vncDirectoryPublicUrl);
      if ((vncDirectoryPublicUrl.indexOf("vncdirectory.vnc.biz") > -1) && !CommonUtil.isOnMobileDevice()) {
        if (this.omemoDeviceExceedMatDialogRef) {
          this.broadcaster.broadcast("closeOMEMODialog");
        }
        setTimeout(async () => {
          let dialogStyles: any;
          if (CommonUtil.isMobileSize()) {
            dialogStyles = {
              "width": "97vw",
              "maxWidth": "97vw",
              "height": "auto",
              "visibility": "visible"
            };
          } else {
            dialogStyles = {
              "width": "500px",
              "visibility": "visible"
            };
          }
          const { TFAReminderDialogComponent } = await import(
            "./talk/shared/components/dialogs/tfa-reminder/tfa-reminder.component");
          this.tfaReminderDialogRef = this.dialog.open(TFAReminderDialogComponent, Object.assign({
            backdropClass: "vnctalk-form-backdrop",
            panelClass: "vnctalk-form-panel",
            disableClose: true,
            autoFocus: true
          }, dialogStyles)).afterClosed().subscribe(() => {
            this.tfaReminderDialogRef = null;
            setTimeout(() => {
              this.broadcaster.broadcast("recheck2FA");
            }, 60000);
          });
        }, 300);
      }
    });

    this.broadcaster.on<boolean>("showOMEMOHitMaxLimitDialog")
      .subscribe(async () => {

      if (this.omemoDeviceExceedMatDialogRef || !!document.querySelector("vp-settings")) {
        return;
      }

      let dialogStyles: any;
      if (CommonUtil.isMobileSize()) {
        dialogStyles = {
          "width": "97vw",
          "maxWidth": "97vw",
          "height": "auto",
          "visibility": "visible"
        };
      } else {
        dialogStyles = {
          "width": "500px",
          "visibility": "visible"
        };
      }
      const { OMEMOHitMaxLimitDialogComponent } = await import(
        "./talk/shared/components/dialogs/omemo-hit-max-limit/omemo-hit-max-limit.component");
      this.omemoDeviceExceedMatDialogRef = this.dialog.open(OMEMOHitMaxLimitDialogComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        autoFocus: true
      }, dialogStyles)).afterClosed().subscribe(() => {
          this.omemoDeviceExceedMatDialogRef = null;
        });
      });

      // on Mobile the dialog is not displayed sometimes, so need a change detection after timout
      setTimeout(() => {
        this.changeDetectorRef.markForCheck();
      }, 100);

    if (environment.showRemainDialog) {
      this.broadcaster.on<boolean>("showRemainDialog")
      .subscribe(() => {
        this.showRemainDialog();
      });
    }

    this.broadcaster.on<boolean>("showEnvironmentDialog")
    .subscribe(() => {
      if (!this.showedEnvironmentDialog && !this.isCordovaOrElectron) {
        this.showedEnvironmentDialog = true;
        this.showEnvironmentDialog();
      }
    });

    this.broadcaster.on<any>("FORCED_LOGOUT").subscribe(() => {
      if (this.isLoggedIn) {
        this.doLogout();
      }
    });

    document.querySelector("body").addEventListener("logoutXmpp", () => {
      if (this.isLoggedIn) {
        this.appService.logout();
      }
      this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on<any>("hideOAuthForm")
      .subscribe(() => {
        this.ngZone.run(() => {
          this.loginWithOAuth = false;
          this.changeDetectorRef.markForCheck();
        });
      });

    this.broadcaster.on<boolean>("USER_LOGGED_OUT")
      .subscribe(() => {

        // hide the sidedrawer in mobile app on logout
        this.hideDrawer = true;
        this.changeDetectorRef.markForCheck();
      });

    this.broadcaster.on<any>("onNewMessage").subscribe(msg => {
      let target = msg.from.bare;
      if (msg.vncTalkBroadcast) {
        target = msg.vncTalkBroadcast.origtarget;
      }
      this.handleMarkAsRead(target, msg);
    });

    this.broadcaster.on<any>("showWelcomeDialog")
      .subscribe(() => {
        this.logger.info("showWelcomeDialog");
        this.showWelcomeDialog();
      });

    this.broadcaster.on<any>("copyToClipboardFromGlobal").subscribe(copyText => {
      this.toastService.show("COPIED_TO_CLIPBOARD");
      CommonUtil.copyToClipboard([copyText]);
    });


    // 1-1 chat new message (Web & Desktop)
    this.onSingleText = this.broadcaster.on<any>("new_single_text")
      .pipe(filter(msg => this.userJID && msg.from.bare !== this.userJID?.bare))
      .subscribe(msg => {
        // this.logger.info("new_single_text (maybeSSA [handleNotification])", msg.body, msg.from.bare, msg.delay, msg);
        let target = msg.from.bare;
        this.store.select(state => getConversationById(state, target))
          .pipe(take(1)
          , filter(conv => !!conv))
          .subscribe(conv => {
            if (!msg.vncTalkConference && !msg.notification) {
              this.handleDesktopNotification(conv, msg);
            }
          });
      });

    // Group chat or Broadcast new message  (Web & Desktop)
    this.onGroupText = this.broadcaster.on<any>("new_group_text")
      .pipe(filter(msg => this.userJID && msg.from.resource !== this.userJID?.bare && msg.fromJid !== this.userJID?.bare))
      .subscribe(msg => {

        let target = msg.from.bare;
        if (!!msg.vncTalkBroadcast) {
          target = msg.vncTalkBroadcast.origtarget;
        }
        this.store.select(state => getConversationById(state, target))
          .pipe(take(1)
          , filter(conv => !!conv && !msg.delay))
          .subscribe(conv => {
            if (!msg.vncTalkConference && !msg.notification) {
              this.handleDesktopNotification(conv, msg);
            }
          });
      });

    this.broadcaster.on<any>("recordingNotify").pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        this.logger.info("[recordingNotify]", data);
        data.fileName = `${data.file}.mp4`;
        this.notificationsService.recordingNotification(data);
    });

    this.broadcaster.on<any>("toggleActiveCall").pipe(takeUntil(this.isAlive$))
      .subscribe(hide => {
        this.hideActiveCall = !hide;
        this.logger.info("[RootComponent] toggleActiveCall", this.hideActiveCall);
        this.changeDetectorRef.markForCheck();
    });
    this.broadcaster.on<any>("toggleHideVideoIOS").pipe(takeUntil(this.isAlive$))
      .subscribe(v => {
        this.logger.info("toggleHideVideoIOS", v);
        this.conferenceRepo.toggleHideVideoIOS(v);
    });

    this.broadcaster.on<any>("showScheduledConferences").pipe(takeUntil(this.isAlive$))
      .subscribe(async (data) => {
        if (!!document.querySelector("vp-scheduled-conferences") || this.conversationRepo.scheduledConferencesShow) {
          return;
        }

        const dialogStyles = CommonUtil.isMobileSize() ?
           {
            "width": "100%",
            "maxWidth": "100%",
            "height": "100%",
            "maxHeight": "100%",
            "visibility": "visible"
          }
          :
          {
            "width": "517px",
            "maxWidth": "80vw",
            "height": "none",
            "maxHeight": "600px",
            "visibility": "visible"
          };
        const { ScheduledConferencesComponent } = await import(
            "./talk/shared/components/dialogs/scheduled-conferences/scheduled-conferences.component");
        this.dialog.open(ScheduledConferencesComponent, {
          backdropClass: "forward-dialog-backdrop",
          panelClass: "forward-dialog-panel",
          disableClose: true,
          autoFocus: false,
          width: dialogStyles.width,
          data: data,
          maxHeight: dialogStyles.maxHeight,
          maxWidth: dialogStyles.maxWidth,
          height: dialogStyles.height
        });
      });

    // call signals
    //

    this.broadcaster.on<any>(BroadcastKeys.CALL_REQUEST_REJECTED)
     .pipe(takeUntil(this.isAlive$)).subscribe(target => {
       this.logger.info("[RootComponent] CALL_REQUEST_REJECTED", target);
       this.maybeHangup(target, "CALL_REQUEST_REJECTED");
     });
    this.broadcaster.on<any>(BroadcastKeys.INITIATOR_LEFT)
     .pipe(takeUntil(this.isAlive$)).subscribe(target => {
       this.logger.info("[RootComponent] INITIATOR_LEFT", target);
       this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
         this.store.select(getConferenceType).pipe(take(1)).subscribe(conferenceType => {
           if ((participants.length < 2) || (conferenceType === "screen-receive")) {
             this.logger.info("[RootComponent] INITIATOR_LEFT maybehangup ", target);
             this.maybeHangup(target, "INITIATOR_LEFT");
           }
         });
        });
     });
     this.broadcaster.on<any>(BroadcastKeys.CALL_SECOND_DEVICE_JOINED)
      .pipe(takeUntil(this.isAlive$)).subscribe(target => {
        this.logger.info("[RootComponent] maybeHangup CALL_SECOND_DEVICE_JOINED", target);
        this.maybeHangup(target, "CALL_SECOND_DEVICE_JOINED");
      });
    //
    this.broadcaster.on<any>(BroadcastKeys.CALL_CONNECTION_FAILED)
      .pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.logger.info("[RootComponent] CALL_CONNECTION_FAILED => hangupCallIfActive");
        this.conferenceRepo.hangupCallIfActive();
      });
    //
    this.broadcaster.on<any>(BroadcastKeys.CALL_LAST_REMOTE_USER_LEFT)
      .pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.logger.info("[RootComponent] CALL_LAST_REMOTE_USER_LEFT");
        this.conferenceRepo.hangupCallIfActive();
      });
    this.broadcaster.on<any>(BroadcastKeys.CALL_USER_CANCELED_SHARE_SCREEN_CALL)
      .pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.logger.info("[RootComponent] CALL_USER_CANCELED_SHARE_SCREEN_CALL");
        this.conferenceRepo.sendLeaveCallSignal("screen");
        this.conferenceRepo.hangupCallIfActive();
      });


    // OMEMO
    this.broadcaster.on<any>("onOMEMOSendChatsHistoryIntention")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(async (data) => {
        this.logger.info("[RootComponent] onOMEMOSendChatsHistoryIntention ", data);

        const dialogStyles  = CommonUtil.isMobileSize() ? {
          "width": "100%",
          "maxWidth": "100%",
          "height": "100%",
          "maxHeight": "100%",
          "visibility": "visible"
        } : {
          "width": "517px",
          "maxWidth": "80vw",
          "height": "none",
          "maxHeight": "600px",
          "visibility": "visible"
        };
        const { OMEMOForwardHistoryRequestComponent } = await import(
          "./talk/shared/components/dialogs/omemo-forward-history-request/omemo-forward-history-request.component");
        this.dialog.open(OMEMOForwardHistoryRequestComponent,
          Object.assign({
            data: {chatsNames: data.chatsNames, from: data.from, fromBare: data.fromBare},
            backdropClass: "vnctalk-form-backdrop",
            panelClass: "vnctalk-form-panel",
            disableClose: true,
            autoFocus: true
          }, dialogStyles)).afterClosed().subscribe((res: any) => {
            this.logger.info("[RootComponent] onOMEMOSendChatsHistoryIntention alert res", res);
            // on Electron the dialog is not displayed sometimes, so need a change detection after timout
            setTimeout(() => {
              this.changeDetectorRef.markForCheck();
            }, 100);
          });
      });

    this.broadcaster.on<any>("hinXmppConn")
     .pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.conversationRepo.getConversations().pipe(filter(conversations => conversations.length > 0), take(1)).subscribe((conversations) => {
          conversations.forEach(conv => {
            this.avatarRepo.upgradeAvatar(conv.Target);
          });
        });
     });

    this.broadcaster.on<any>("BGSYNCOMPLETE")
    .pipe(takeUntil(this.isAlive$)).subscribe(target => {
      this.logger.info("[RootComponent] BGSYNCOMPLETE");
      if (this.CommonUtil.isOnAndroid()) {
        cordova.plugins.backgroundMode.disable();
        this.logger.info("[RootComponent][BGSYNCOMPLETE] allowSleepAgain");
        window.plugins.insomnia.allowSleepAgain(() => {
          this.logger.info("[RootComponent][BGSYNCOMPLETE] allowSleepAgain success");
          if (!!window.appInBackground) {
            const lastInactiveDelay = (Date.now() / 1000) - this.xmppService.getLastInactiveTime();
            if (lastInactiveDelay > 60) {
              this.logger.info("[RootComponent][BGSYNCOMPLETE] allowSleepAgain moveToBackground lastInactiveDelay ", lastInactiveDelay);
              cordova.plugins.backgroundMode.moveToBackground();
            // } else {
            //  this.logger.info("[RootComponent][BGSYNCOMPLETE] allowSleepAgain moveToBackground lastInactiveDelay - SKIP ", lastInactiveDelay);
            }
          }
        }, error => {
          this.logger.error("[RootComponent][BGSYNCOMPLETE] allowSleepAgain error", error);
          this.logger.sentryErrorLog("[RootComponent][BGSYNCOMPLETE] allowSleepAgain error", error);
        });
      }
    });

  }

  private maybeHangup(target: string, event): void {
    if (target) {
      this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(conversationTarget => {
        if (conversationTarget && target === conversationTarget) {
          this.logger.info("[root.component] maybeHangup target===convTarget ", target, event);
          this.conferenceRepo.hangupCallIfActive();
        } else {
          this.logger.warn(`[RootComponent] ${event}: skip, the event is from 2nd call`);
        }
      });
    } else {
      this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(activeConversationTarget => {
        this.store.select(getConferenceParticipants).pipe(take(1)).subscribe(participants => {
          this.store.select(getConferenceType).pipe(take(1)).subscribe(type => {
            this.logger.info("[root.component] maybeHangup2 no target ", event, activeConversationTarget, type, participants);
            if (!!type && !type.startsWith("screen")) {
              if (!!participants && (participants.length < 2)) {
                this.conferenceRepo.hangupCallIfActive();
              }
            } else {
              this.logger.error("[root.component] maybeHangup2 would call hangup!");
              if (!!participants && (participants.length < 2)) {
                this.conferenceRepo.hangupCallIfActive();
              }
            }
          });
        });
      });
    }
  }

  private handleMarkAsRead(target, msg: Message): void {
    if (msg.vncTalkBroadcast) {
      target = msg.vncTalkBroadcast.origtarget;
    }
    if (this.selectedConversation && this.selectedConversation?.Target === target) {
      this.markConversationAsRead(target);
    }
  }

  ngOnInit() {
    if (environment.isElectron) {
      // need time to init electron before apply hack for libsignal-protocol
      // https://github.com/signalapp/libsignal-protocol-javascript/issues/6#issuecomment-247208665
      setTimeout(() => {
        window.nodeRequire = require;
        delete window.require;
        CommonUtil.loadExternalScript("assets/js/libsignal-protocol.js").then(() => {
          window.require = window.nodeRequire;
          delete window.nodeRequire;
        });
      }, 2000);
    } else if (environment.isCordova) {
      setTimeout(() => {
        CommonUtil.loadExternalScript("assets/js/libsignal-protocol.js");
      }, 1000);
    } else {
      CommonUtil.loadExternalScript("assets/js/libsignal-protocol.js");
    }

    this.logger.info("[RootComponent][ngOnInit] selectedServer: ", this.configService.selectedServer);
    this.store.select(getAppSettings)
    .pipe(takeUntil(this.isAlive$))
    .subscribe(options => {
      this.settings = options;
      // if (this.settings.timezone) {
      this.logger.info("[RootComponent][ngOnInit] getAppSettings theme: ", options);
      if (!!options.selectedTheme && this.CommonUtil.isOnAndroid()) {
        try {
          if (options.selectedTheme === "ekbo") {
            if (this.CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#6633cc");
              StatusBar.styleLightContent();
            }
          } else if (options.selectedTheme === "hin") {
            if (this.CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#f39900");
              StatusBar.styleBlackTranslucent();
            }
          } else if (options.selectedTheme === "airbus") {
            if (this.CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#00205b");
              StatusBar.styleLightContent();
            }
          } else {
            if (this.CommonUtil.isOnAndroid()) {
              StatusBar.backgroundColorByHexString("#317bbc");
              StatusBar.styleLightContent();
            }
          }
        } catch (err) {
          this.logger.error("[RootComponent][ngOnInit] set StatusBar for theme error: ", err);
        }
      }
      // }
    });
    if (!environment.isCordova && !environment.isElectron) {
      this.checkForLatestBrowserVersion();
    }

    this.configService.loadConfig();

    this.setupEmoji();
    this.handleBackButton();
    if (!this.configService.isAnonymous) {
      this.handleEvents();
    }

    if (!this.configService.selectedServer) {
      this.logger.info("[RootComponent][ngOnInit] bailing out - no selectedServer: ", this.configService.selectedServer);
      return;
    }

    if (!this.configService.isAnonymous && this.isCordovaOrElectron && !localStorage.getItem("token")){
      this.configService.loginIframe();
      return;
    }

    this.responsiveService.screen$
      .pipe(takeUntil(this.destroy$))
      .subscribe(screen => {
        this.screen = screen;
        this.changeDetectorRef.markForCheck();
      });

    window.addEventListener("orientationchange", () => {
      this.logger.info("[orientationchange]", window.orientation);
      if (document.querySelector("pinch-zoom div") !== null) {
        (<HTMLElement>document.querySelector("pinch-zoom div")).style.transform = "matrix(1, 0, 0, 1, 0, 0)";
        if (window.orientation !== 0 && document.querySelector("#imageInPreview") !== null) {
          (<HTMLElement>document.querySelector("#imageInPreview")).style.maxWidth = "inherit";
        }
      }
      if (this.jitsiService.isJoined) {
        this.jitsiService.attachAllTracks();
      }
      this.logger.info(window.orientation);
      if (window.matchMedia("(orientation: portrait)").matches && !this.expandedAutomatically ) {
        this.store.dispatch(new ExpandConversationList(true));
        this.expandedAutomatically = true;
      }
    }, false);

    document.addEventListener("pause", () => {
      window.appInBackground = true;
      this.store.dispatch(new UpdateNetworkInformation({
        inBackground: true
      }));

      if (this.CommonUtil.isOnAndroid()) {
        AudioToggle.getAudioMode(v => {
          this.logger.info("[getAudioMode]", v);
          window.audioMode = v;
        });

        this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(conversationTarget => {
          if (conversationTarget) {
            // enable background mode for active call
            cordova.plugins.backgroundMode.enable();
          }
        });
      }

      this.logger.info("[RootComponent] on pause");
    });

    document.addEventListener("resume", () => {
      if (this.CommonUtil.isOnAndroid()) {
        let self = this;
        this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(activeConfTarget => {
          if (activeConfTarget) {
            this.logger.info("[getAudioMode] current audio mode", window.audioMode);
            this.audioOutputService.correctAudioOutput();
            AudioToggle.setAudioMode(window.audioMode);
            cordova.plugins.NativeAudio.setVolumeForComplexAsset("calling", 1.0, (res) => {
              self.logger.info("NativeAudio setVolumeForComplexAsset result: " + res);
              self = null;
            }, (error) => {
              self.logger.error("NativeAudio setVolumeForComplexAsset error: " + error);
              self.logger.sentryErrorLog("NativeAudio setVolumeForComplexAsset error: " + error);
              self = null;
            });
          }
        });
      }

      window.appInBackground = false;
      this.store.dispatch(new UpdateNetworkInformation({
        inBackground: false
      }));

      if (this.CommonUtil.isOnAndroid()) {
        cordova.plugins.backgroundMode.disable();
      }

      if (this.isCordovaOrElectron && this.isNetworkOnline){
        this.sendFailedInlineRepliesAndMarkAsReadRequests();
      }

      if (!this.configService.isAnonymous) {
        this.firebaseClearAlliOSNotifications();

        this.resetBadgeCounterForCurrentConv();
      }

      this.logger.info(new Date().toISOString() + " [RootComponent] on resume");
    });

    if (CommonUtil.isMobileSize() && navigator.userAgent.toLowerCase().indexOf("vnctalk") === -1) {
      this.hideSoundActivation = false;
    }

    this.logger.info("[RootComponent] isAnonymous", this.configService.isAnonymous);

    if (!this.configService.isAnonymous) {
      this.loadInitialData();
    } else {
      this.loadConfigOnly();
    }

    this.appService.updateTitle();

    this.initWebWorker();

    this.deepLinksHandlerSetup();

  }

  ngAfterViewInit(): void {
    this.addUserAgent();
    this.importDynamicModules();
    this.loadLibs();
    if (!!document.getElementById("non-cordova-only")) {
      document.getElementById("non-cordova-only").outerHTML = "";
    }

    if (CommonUtil.isOnIOS()) {
      if (typeof device !== "undefined" && device.platform && (device.platform.toLowerCase() === "ios") && (parseInt(device.version) < 16)) {
        this.translate.get("IPHONE_DEPRECATED").pipe(take(1)).subscribe(text => {

          alert(text);
        });
      }
    }

    this.changeDetectionRef.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetectionRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.logger.info("[RootComponent][ngOnDestroy]");

    this.destroy$.next(false);
    this.destroy$.unsubscribe();
    if (this.subscriber) this.subscriber.unsubscribe();
    if (this._routeDataSubscriber) this._routeDataSubscriber.unsubscribe();
    if (this.onSingleText) {
      this.onSingleText.unsubscribe();
    }
    if (this.onGroupText) {
      this.onGroupText.unsubscribe();
    }
    if (this.onIncomingCall) {
      this.onIncomingCall.unsubscribe();
    }
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler() {
    this.logger.info("[beforeunloadHandler]");
    if (this.isLoggedIn && !this.isCordovaOrElectron) {
      this.jitsiService.leave(true);
      this.xmppService.logout();
    }
  }

  private loadInitialData() {
    this.logger.info("[RootComponent][loadInitialData]");

    if (localStorage.getItem("disableLogout") !== null) {
      this.store.dispatch(new UpdateLogoutOption(localStorage.getItem("disableLogout") === "true"));
    }
    if (localStorage.getItem("federatedApps") !== null) {
      let federatedApps = JSON.parse(localStorage.getItem("federatedApps"));
      this.store.dispatch(new SetAvailableApps(federatedApps));
    }

    if (!this.isNetworkOnline) {
      this.logger.info("[RootComponent][loadInitialData] SKIP, no network");
      // TODO: try later
      this.isDelayedRetrieveInitialDataRequired = true;
      return;
    }

    this.retrieveAndSetConfig().subscribe(() => {
      this.loadProfile();

      // no need it immediately
      setTimeout(() => {
        this.configService.getMyProducts().subscribe(data => {
          this.logger.info("[RootComponent][getMyProducts]", data);

          if (data && data.products) {
            localStorage.setItem("federatedApps", JSON.stringify(data.products));
            this.store.dispatch(new SetAvailableApps(data.products));
          }
        });
      }, 5000);
    }, err => {
      this.logger.sentryErrorLog("[RootComponent][loadInitialData]. err: ", err);
      this.logger.error("[RootComponent][loadInitialData]. err: ", err);

      if (err.status === 401) {
        this.doLogout();
      } else {
        const needToRetry = this.tryToRecoverOrDoLogout();
        if (needToRetry) {
          setTimeout(() => {
            this.loadInitialData();
          }, 1000);
        }
      }
    });
  }

  private loadConfigOnly() {
    this.logger.info("[RootComponent][loadConfigOnly]. Network: ", this.isNetworkOnline);

    if (!this.isNetworkOnline) {
      this.logger.info("[RootComponent][loadConfigOnly] SKIP, no network");
      // TODO: try later
      this.isDelayedRetrieveConfigRequired = true;
      return;
    }

    this.retrieveAndSetConfig().subscribe(() => {

    }, err => {
      this.logger.error("[RootComponent][loadConfigOnly]. err: ", err);
      this.logger.sentryErrorLog("[RootComponent][loadConfigOnly]. err: ", err);

      if (err.status === 401) {
        this.doLogout();
      } else {
        const needToRetry = this.tryToRecoverOrDoLogout();
        if (needToRetry) {
          setTimeout(() => {
            this.loadConfigOnly();
          }, 1000);
        }
      }
    });
  }

  private loadLibs() {
    const noiseSuppressorWorkletUrl = CommonUtil.getFullUrl("/assets/js/noise-suppressor-worklet.min.js");
    localStorage.setItem("noiseSuppressorWorkletUrl", noiseSuppressorWorkletUrl);
    if (!this.loadedLibs) {
      this.loadedLibs = true;
      CommonUtil.loadExternalScript("assets/js/strophe.js").then(() => {
        this.logger.info("Loaded strophe.js");
        CommonUtil.loadExternalScript("assets/js/strophe.disco.min.js").then(() => {
          this.logger.info("Loaded strophe.disco.min.js");
        });
      });

      CommonUtil.loadExternalScript("assets/js/custom.function.js").then(() => {
        this.logger.info("Loaded custom.function.js");
      });

      CommonUtil.loadExternalScript("assets/js/lib-jitsi-meet.min.js").then(() => {
        this.logger.info("Loaded latest jitsi meet lib");
        this.jitsiService.initJitsi();
        if (!this.CommonUtil.isOnNativeMobileDevice() && !CommonUtil.isOnSafari()) {
          normalizeCommonJSImport(import("wasm-check")).then(wasmCheck => {
            if (environment.isElectron) {
              window.isElectron = environment.isElectron;
              if (wasmCheck.feature.simd) {
                window.wasmBinaryPath = "tflite-simd.wasm";
              } else {
                window.wasmBinaryPath = "tflite.wasm";
              }
            } else {
              CommonUtil.loadExternalScript("assets/js/olm.js");
            }

            CommonUtil.loadExternalScript("assets/js/virtual-background/vendor/tflite/tflite.js");
            CommonUtil.loadExternalScript("assets/js/virtual-background/vendor/tflite/tflite-simd.js");
            (async () => {
              try {
                if (wasmCheck.feature.simd) {
                  await fetch(CommonUtil.getFullUrl("/assets/js/virtual-background/vendor/tflite/tflite-simd.wasm"));
                } else {
                  await fetch(CommonUtil.getFullUrl("/assets/js/virtual-background/vendor/tflite/tflite.wasm"));
                }
              } catch (error) {
                this.logger.sentryErrorLog("[tflite] preloaded wasm error: ", error);
                this.logger.error("[tflite] preloaded wasm error: ", error);
              }
              this.logger.info("[tflite] preloaded wasm");
            })();
          });

        }
      });
    }
  }

  private retrieveAndSetConfig(forceReload?: boolean) {
    this.logger.info("[RootComponent][retrieveAndSetConfig] ", forceReload);

    // const response = new Subject<Conversation[]>();
    const response = new BehaviorSubject<Conversation[]>([]);

      this.configService.getConfig(forceReload)
      .subscribe(data => {
        this.logger.info("[RootComponent][retrieveAndSetConfig] response", data);

        if (!data) {
          this.logger.warn("[RootComponent][retrieveAndSetConfig] response null");
          response.error({});
          return;
        }

        if (data.disableNewJitsiFeature) {
          CommonUtil.loadExternalScript("assets/js/old-jitsi/lib-jitsi-meet.min.js").then(() => {
            this.logger.info("Loaded old jitsi meet lib");
            this.jitsiService.initJitsi();
          });
        }

        this.configService.set("boshURL", data.boshURL);
        this.configService.set("enabledOwnCloud", data.enabledOwnCloud);
        this.configService.set("jitsiAvailable", data.jitsiAvailable);
        this.configService.set("ownCloudURL", data.ownCloudURL);
        this.configService.set("hideCallLog", data.hideCallLog);
        this.configService.set("disableDocumentPreview", data.disableDocumentPreview);
        this.configService.set("viewerMode", data.viewerMode);
        localStorage.setItem("ownCloudURL", data.ownCloudURL);
        localStorage.setItem("enabledOwnCloud", data.enabledOwnCloud);
        this.configService.set("useOldSearch", data.useOldSearch);
        this.configService.set("xmppWsUrl", data.xmppWsUrl);
        this.configService.set("useOldSearch", data.useOldSearch);
        this.configService.set("botJid", data.botJid);
        this.configService.set("botWS", data.botWS);
        localStorage.setItem("botWS", data.botWS);
        if (data.avatarServiceUrl) {
          this.configService.set("avatarServiceUrl", data.avatarServiceUrl);
          localStorage.setItem("avatarServiceUrl", data.avatarServiceUrl);
          this.configService.avatarServiceUrl =  data.avatarServiceUrl;
        }
        if (data.fileShareService) {
          this.configService.set("fileShareService", data.fileShareService);
          localStorage.setItem("fileShareService", data.fileShareService);
          this.configService.fileShareService =  data.fileShareService;
        }
        this.configService.set("jitsiMeetURL", data.jitsiMeetURL);

        this.configService.set("jitsiURL", data.jitsiURL);
        //
        try {
          let jitURL = new URL(data.jitsiURL);
          localStorage.setItem("currentJitsiHost", jitURL.hostname);
          this.configService.getJitsiConfig(data.jitsiURL, null).pipe(take(1)).subscribe(jitsiConfig => {
            this.logger.info("[RootComponent][loadConfigOnly][getConfig][getJitsiConfig] response", jitsiConfig);
            this.store.dispatch(new UpdateJitsiConfig(jitsiConfig));
          });
        } catch (error) {
          this.logger.info("[root.component] could not get jitsi config: ", error);
        }
        this.configService.REDMINE_URL = data.redmineURL;
        this.configService.set("broadcastAvailable", data.broadcastAvailable);
        localStorage.setItem("isSupportedRTF", data.isSupportedRTF);
        this.configService.set("isSupportedRTF", data.isSupportedRTF);
        localStorage.setItem("streamURL", data.streamURL);
        this.configService.set("streamURL", data.streamURL);
        this.configService.set("whiteBoardAvailable", data.whiteBoardAvailable);
        this.configService.set("disableAVRecording", data.disableAVRecording);
        this.configService.set("disableLogout", data.disableLogout);
        this.configService.set("whiteboardURLNew", data.whiteboardURLNew);
        this.configService.set("knownIOMDomains", data.knownIOMDomains);
        this.configService.set("showScreenshareSettings", data.showScreenshareSettings);
        if (data.requireJitsiAuth) {
          this.configService.set("requireJitsiAuth", data.requireJitsiAuth);
        }
        if (data.requireJitsiAuth) {
          this.configService.set("runningCallCheckViaRest", data.runningCallCheckViaRest);
        } else {
          this.configService.set("runningCallCheckViaRest", false);
        }
        if (data.preferredResolution) {
          this.configService.set("preferredResolution", data.preferredResolution);
        } else {
          this.configService.set("preferredResolution", 720);
        }
        if (data.jitsiAppId) {
          this.configService.set("jitsiAppId", data.jitsiAppId);
        }
        if (data.onlyOfficeApiUrl) {
          this.configService.set("onlyOfficeApiUrl", data.onlyOfficeApiUrl);
        }
        this.configService.backendConfig = data;

        localStorage.setItem("disableLogout", data.disableLogout ? "true" : "false");
        this.store.dispatch(new UpdateLogoutOption(data.disableLogout));

        if (!!data.group_management_via_vncdirectory){
          this.configService.set("groupManagementViaDirectory", data.group_management_via_vncdirectory);
          localStorage.setItem("groupManagementViaDirectory", data.group_management_via_vncdirectory ? "true" : "false");
        } else {
          this.configService.set("groupManagementViaDirectory", false);
          localStorage.setItem("groupManagementViaDirectory", "false");
        }
        if (data.skipAddUserToChatWhenAddToCall != undefined){
          this.configService.set("skipAddUserToChatWhenAddToCall", data.skipAddUserToChatWhenAddToCall);
        } else {
          this.configService.set("skipAddUserToChatWhenAddToCall", false);
        }
        if (!!data.two_factor_authentication) {
          this.configService.set("twoFactorAuthentication", data.two_factor_authentication);
          localStorage.setItem("twoFactorAuthentication", data.two_factor_authentication ? "true" : "false");
        } else {
          this.configService.set("twoFactorAuthentication", false);
          localStorage.setItem("twoFactorAuthentication", "false");
        }
        if (!!data.request_for_connect) {
          this.configService.set("requestForConnect", data.request_for_connect);
          localStorage.setItem("requestForConnect", data.request_for_connect ? "true" : "false");
        } else {
          this.configService.set("requestForConnect", false);
          localStorage.setItem("requestForConnect", "false");
        }
        if (!!data.isGlobalSearchRFC) {
          this.configService.set("isGlobalSearchRFC", data.isGlobalSearchRFC);
          localStorage.setItem("isGlobalSearchRFC", data.isGlobalSearchRFC ? "true" : "false");
        } else {
          this.configService.set("isGlobalSearchRFC", false);
          localStorage.setItem("isGlobalSearchRFC", "false");
        }
        if (!!data.limitInviteJidOnEnter) {
          this.configService.set("limitInviteJidOnEnter", data.limitInviteJidOnEnter);
          localStorage.setItem("limitInviteJidOnEnter", data.limitInviteJidOnEnter ? "true" : "false");
        } else {
          this.configService.set("limitInviteJidOnEnter", false);
          localStorage.setItem("limitInviteJidOnEnter", "false");
        }
        if (data.enableStartVideoMeeting != null) {
          this.logger.info("root: enableStartVideoMeeting: ", data.enableStartVideoMeeting);
          this.configService.set("enableStartVideoMeeting", data.enableStartVideoMeeting);
        } else {
          this.configService.set("enableStartVideoMeeting", true);
        }
        if (!!data.isUserRestrictionEnabled) {
          this.configService.set("isUserRestrictionEnabled", data.isUserRestrictionEnabled);
          localStorage.setItem("isUserRestrictionEnabled", data.isUserRestrictionEnabled ? "true" : "false");
        } else {
          this.configService.set("isUserRestrictionEnabled", false);
          localStorage.setItem("isUserRestrictionEnabled", "false");
        }
        if (data.allowExternalUsersAddByEmail != null) {
          this.configService.set("allowExternalUsersAddByEmail", data.allowExternalUsersAddByEmail);
        } else {
          this.configService.set("allowExternalUsersAddByEmail", true);
        }
        if (data.allowExternalUsersAddByPhone != null) {
          this.configService.set("allowExternalUsersAddByPhone", data.allowExternalUsersAddByPhone);
        } else {
          this.configService.set("allowExternalUsersAddByPhone", false);
        }
        if (!!data.isInviteUserEnabled) {
          this.configService.set("isInviteUserEnabled", data.isInviteUserEnabled);
          localStorage.setItem("isInviteUserEnabled", data.isInviteUserEnabled ? "true" : "false");
        } else {
          this.configService.set("isInviteUserEnabled", false);
          localStorage.setItem("isInviteUserEnabled", "false");
        }
        if (!!data.isChannelsEnabled) {
          this.configService.set("isChannelsEnabled", data.isChannelsEnabled);
        } else {
          this.configService.set("isChannelsEnabled", false);
        }
        if (data.isCopyURLinCallEnabled != null) {
          this.configService.set("isCopyURLinCallEnabled", data.isCopyURLinCallEnabled);
        } else {
          this.configService.set("isCopyURLinCallEnabled", true);
        }
        if (!!data.vncdDisableUserToContactMapping) {
          this.configService.set("vncdDisableUserToContactMapping", data.vncdDisableUserToContactMapping);
          localStorage.setItem("isInviteUserEnabled", data.vncdDisableUserToContactMapping ? "true" : "false");
        } else {
          this.configService.set("vncdDisableUserToContactMapping", false);
        }
        this.configService.set("channelWsUrl", data.channelWsUrl);
        this.configService.set("vncDirectoryPublicUrl", data.vncDirectoryPublicUrl);
        if (!!data.isSocialEnabled) {
          this.configService.set("isSocialEnabled", data.isSocialEnabled);
        } else {
          this.configService.set("isSocialEnabled", false);
        }
        response.next([]);
      }, error => {
        this.logger.sentryErrorLog("[RootComponent][retrieveAndSetConfig]", error);
        this.logger.error("[RootComponent][retrieveAndSetConfig]", error);
        response.error(error);
      });

    return response.asObservable().pipe(take(1));
  }

  private saveJidToPreferences(jid: string){
    setTimeout(() => {
      this.appService.saveUserJidToPreferences(jid);
    }, 300);
  }

  private setToken(token: string): void {
    localStorage.setItem("token", token);
    setTimeout(() => {
      this.appService.saveAuthTokenToPreferences(token);
    }, 300);
  }

  private sendFailedInlineRepliesAndMarkAsReadRequests(){
    this.logger.info("[RootComponent][sendFailedInlineRepliesAndMarkAsReadRequests]");

    setTimeout(() => {
      this.sendInlineReplyMessage();
      this.sendMarkAsReadFailedMessage();
    }, 5000);
  }

  async showEnvironmentDialog() {
    let dialogStyles: any = {
      width: "320px"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        width: "70%",
        minWidth: "70%",
        maxWidth: "70%",
        height: "auto"
      };
    }
    const { CommonNotificationComponent } = await import(
      "./talk/shared/components/dialogs/common-notification/common-notification");
    this.dialog.open(CommonNotificationComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      autoFocus: true
    }, dialogStyles)).afterClosed().subscribe(() => {
          if (environment.isCordova) {
            const initialHref = window.location.href.split("/www")[0];
            window.location.href = `${initialHref}/www/index.html`;
          } else if (environment.isElectron) {
            const initialHref = window.location.href.split("/talk")[0];
            window.location.href = `${initialHref}/index.html`;
          } else {
            window.location.reload();
          }
        });
  }

  checkError(err: any) {
    if (environment.theme === "hin" && !this.isCordovaOrElectron) {
      if (err.status !== 200 && err.status !== 401) {
        this.xmppService.logout();
        if (!this.showedEnvironmentDialog) {
          this.showEnvironmentDialog();
        }
      }
    }
  }

  private loadProfile() {
    this.logger.info("[RootComponent][loadProfile]");

    if (window.location.href.indexOf("external-call") !== -1) {
      this.logger.info("[RootComponent] external-call, return");
      return;
    }

    this.profileAndConfigRetrievalRecoveryTimesMade = 0;
    this.store.dispatch(new DisableGlobalMute());
    this.auth.getProfile().subscribe(profileFromServer => {
      this.logger.info("[RootComponent][loadProfile][getProfile] response", profileFromServer, profileFromServer.serverTimeDiff);
      if (profileFromServer) {
        this.datetimeService.processTimeDiff(profileFromServer.serverTimeDiff);
      }

      // profileFromServer.user.email

      // sets isProfileRetrieved if we've already gotten a property "secret" from profileFromServer
      if (profileFromServer && profileFromServer.secret) {

        // if a user in localstorage is different than user from profile, clear localstorage and reload whole application
        this.store.select(getUserProfile).pipe(take(1)).subscribe(currentProfile => {
          this.logger.info("[RootComponent][loadProfile] getUserProfile, currentProfile: ", currentProfile);
          if (currentProfile && currentProfile.user.id !== profileFromServer.user.id) {
            this.resetAndRedirectToLoginScreen();

            // TODO: clean DB of old user as well?
            return;
          }
          this.configService.isProfileLoaded$.next(true);
          // by some reason it could be a case where secret is empty...
          // since it does not change anymore it is safe to use the old one in this case...
          if (!profileFromServer.secret && currentProfile) {
            this.logger.warn("[RootComponent][loadProfile] getUserProfile, secret is empty, fixing it");
            profileFromServer.secret = currentProfile.secret;
          }
        });

        localStorage.setItem("appDomain", profileFromServer.domain);
        localStorage.setItem("jwtTemp", profileFromServer.jwtTemp);
        if (!!profileFromServer.jitsiauth) {
          localStorage.setItem("jitsiauth", profileFromServer.jitsiauth);
        }
        this.store.dispatch(new SetDomain(profileFromServer.domain));
        profileFromServer.user.fullName = profileFromServer.user.firstName + " " + profileFromServer.user.lastName;
        const user = profileFromServer.user;

        const jid = Array.isArray(user.email) ? user.email[0] : user.email; // FIXME: validate email properly
        this.store.dispatch(new SetBare(jid));
        this.auth.ldapUser = user; // FIXME: use from configservice
        this.configService.set("userId", jid);
        if (!!profileFromServer.jitsiauth) {
          this.configService.set("jitsiauth", profileFromServer.jitsiauth);
        }
        if (environment.enableSentry) {
          this.logger.configureScope(jid);
        }
        (async() => {
          if (environment.isCordova) {
            const Sentry = await normalizeCommonJSImport(import("sentry-cordova"));
            Sentry.configureScope(scope => {
              scope.setUser({ id: jid, email: jid, username: jid });
            });
            this.saveJidToPreferences(jid);
          } else if (environment.isElectron) {
            const ElectronSentry = await normalizeCommonJSImport(import("@sentry/electron"));
            ElectronSentry.configureScope(scope => {
              scope.setUser({ id: jid, email: jid, username: jid });
            });
          } else if (environment.production && environment.enableSentry) {
            const Raven = await normalizeCommonJSImport(import("@sentry/browser"));
            Raven.configureScope(scope => {
              scope.setUser({ id: jid, email: jid, username: jid });
            });

          }
        });

        if (environment.isCordova) {
          this.saveJidToPreferences(jid);
        }
        const token = profileFromServer.secret;
        this.configService.REDMINE_URL = profileFromServer.redmineURL;
        this.logger.warn("[RootComponent][loadProfile] token", token);

        if (token && token.trim().length > 0) {

          if (!environment.enableOauthLogin) {
            this.setToken(token);
          }
          this.logger.warn("[RootComponent][loadProfile] FetchMeSuccess", profileFromServer);

          this.store.dispatch(new FetchMeSuccess(profileFromServer));
        }

        this.configService.hideLoginIframe();

        this.appService.getChatBackgroundCustomImages().subscribe((res: any) => {
          if (res && res.length) {
            res.map(img => {
              this.appService.getChatBackgroundCustomImageById(img.id).subscribe((res: any) => {
                this.store.dispatch(new SetChatBackgroundImages({id: img.id.toString(), name: img.filename, type: img.type, src: res[0].base64data}));
              });
              });
            }
          });

        // request and save to store current user settings
        this.xmppService.getPrivateDocuments().subscribe((documents) => {
          const settings = CommonUtil.getAppSettings(documents);
          this.logger.info("[RootComponent][loadProfile][getPrivateDocuments]", settings);

          if (!!settings.globalMute) {
            this.logger.info("root.component getprivatexml globalMute set to true");
            this.conversationRepo.enableGlobalMute();
          } else {
            this.logger.info("root.component getprivatexml globalMute set to false");
            this.conversationRepo.disableGlobalMute();
          }
          if (!settings.lang) {
            settings["lang"] = profileFromServer.user.language;
          }
          if (typeof settings.enabledSound === "undefined") {
            settings.enabledSound = true;
          }
          if (typeof settings.autoDeviceChange === "undefined") {
            settings.autoDeviceChange = false;
          }
          if (settings.autoDeviceChange) {
            localStorage.setItem("autoDeviceChange", "true");
          } else {
            localStorage.setItem("autoDeviceChange", "false");
          }
          if (settings.lang) {
            if (!!profileFromServer.user.language && (profileFromServer.user.language !== settings.lang)) {
              this.logger.info("language from XML: ", settings.lang);
              this.logger.info("language from profile: ", profileFromServer.user.language);
              settings["lang"] = settings.lang;
            }
            localStorage.setItem("portalLanguage", settings.lang);
            setTimeout(() => {
              this.translate.use(settings.lang);
              this.translate.reloadLang(settings.lang);
              this.commonService.currentLanguage.next(settings.lang);
            }, 2000);
          }
          if (!settings.callRingtone && environment.theme === "hin") {
            settings.callRingtone = "retroMovieRingtone";
          }
          if (settings.selectedTheme && settings.selectedTheme !== localStorage.getItem("theme")) {
            const selectedTheme = settings.selectedTheme;
            localStorage.setItem(ConstantsUtil.THEME, selectedTheme);

            this.isHinTheme = localStorage.getItem("theme") === "hin";
            this.isAirbusTheme = localStorage.getItem("theme") === "airbus";
            this.changeDetectionRef.markForCheck();
            this.broadcaster.broadcast("updateTheme");
            let themePath = `${localStorage.getItem("themeHash")}.`;
            document.getElementById("vnctalkTheme").remove();
            if (selectedTheme === "ekbo") {
              CommonUtil.loadExternalStyle(themePath + "ekbo.css", "vnctalkTheme").then(() => {
                this.logger.info("loaded ekbo theme from backend");
                if (this.CommonUtil.isOnAndroid()) {
                  StatusBar.backgroundColorByHexString("#6633cc");
                  StatusBar.styleLightContent();
                }
              }).catch(() => { });
            } else if (selectedTheme === "hin") {
              CommonUtil.loadExternalStyle(themePath + "hin.css", "vnctalkTheme").then(() => {
                this.logger.info("loaded hin theme from backend");
                if (this.CommonUtil.isOnAndroid()) {
                  StatusBar.backgroundColorByHexString("#f39900");
                  StatusBar.styleBlackTranslucent();
                }
              }).catch(() => { });
            } else if (selectedTheme === "airbus") {
              CommonUtil.loadExternalStyle(themePath + "airbus.css", "vnctalkTheme").then(() => {
                this.logger.info("loaded airbus theme from backend");
                if (this.CommonUtil.isOnAndroid()) {
                  StatusBar.backgroundColorByHexString("#00205b");
                  StatusBar.styleLightContent();
                }
              }).catch(() => { });
            } else {
              CommonUtil.loadExternalStyle(themePath + "vnctalk.css", "vnctalkTheme").then(() => {
                this.logger.info("loaded vnctalk theme from backend");
                if (this.CommonUtil.isOnAndroid()) {
                  StatusBar.backgroundColorByHexString("#317bbc");
                  StatusBar.styleLightContent();
                }
              }).catch(() => { });
            }
          }
          const selectedChatBackground = settings.selectedChatBackground || environment.chatBackground;
          localStorage.setItem(ConstantsUtil.CHAT_BACKGROUND, selectedChatBackground);
          this.store.dispatch(new SetSelectedChatBackgroundPreview(settings.selectedChatBackground || environment.chatBackground));
          localStorage.setItem(ConstantsUtil.CHAT_BACKGROUND_COLOR, (settings.chatBackgroundColor || environment.chatBackgroundColor));
          this.store.dispatch(new SetChatBackgroundColorPreview(settings.chatBackgroundColor || environment.chatBackgroundColor));
          if (settings.callRingtone && environment.isCordova) {
            this.appService.saveCurretRIngtoneToPreferences(settings.callRingtone);
          }

          localStorage.setItem("isAndroidAppUpgradeEnabled", settings.isAndroidAppUpgradeEnabled ? "1" : "0");

          this.logger.info("root.component restoreSettings: ", settings);
          this.store.dispatch(new SetAppSettings(settings));

         //  conference settings
          if (!!settings.videoBackground && !this.CommonUtil.isOnNativeMobileDevice()) {
            this.logger.info("[RootComponent][videoBackground]", settings.videoBackground);
            if (settings.videoBackground?.backgroundType === "image") {
                const id = settings.videoBackground.selectedThumbnail;
                let src = settings.videoBackground.virtualSource;
                if (src.indexOf("/assets/") !== -1) {
                    src = CommonUtil.getFullUrl("/assets/" + src.split("/assets/")[1]);
                }
                this.jitsiService.setImageBackground({id, src}, true);
            } else if ((settings.videoBackground?.backgroundType === "blur") && (settings.videoBackground?.enabled)) {
              this.jitsiService.enableBlur(15, "blur", true);
            } else {
              this.jitsiService.removeBackground();
            }
          }
          if (!!settings.videoQuality) {
            this.jitsiService.videoQuality$.next(+settings.videoQuality);
          }


          setTimeout(() => {
            this.conferenceRepo.updateFrontBackCamerasIds();
            // wait until 'getAvailableMediaDevices' is ready
          }, 500);

          if (environment.isCordova) {
            if (documents.notifyOptions) {
              this.store.dispatch(new NotifyOptionChange(documents.notifyOptions.type));
            }
          } else {
            if (documents.desktopNotifyOptions) {
              this.store.dispatch(new NotifyOptionChange(documents.desktopNotifyOptions.type));
            }
          }

          // if no notification settings, set default ones here
          if (((environment.isCordova && !documents.notifyOptions) ||
            (!environment.isCordova && !documents.desktopNotifyOptions)) && this.isOnline && this.isXmppConnected) {
            this.logger.info("[RootComponent][loadProfile][updateSettings] set default notify settings");
            this.appService.updateSettings("2", settings).subscribe(() => {
            });
          }
        });

      } else {
        this.logger.warn("[RootComponent][loadProfile] response secret is null");

        const needToRetry = this.tryToRecoverOrDoLogout();
        if (needToRetry) {
          setTimeout(() => {
            this.loadProfile();
          }, 1000);
        }
      }
    }, err => {
      this.logger.error("[RootComponent][loadProfile] error", err);

      if (err.status === 401) {
        this.doLogout();
      } else {
        const needToRetry = this.tryToRecoverOrDoLogout();
        if (needToRetry) {
          setTimeout(() => {
            this.loadProfile();
          }, 1000);
        }
      }
    });
  }


  private tryToRecoverOrDoLogout(): boolean {
    this.profileAndConfigRetrievalRecoveryTimesMade += 1;
    this.logger.info("[RootComponent][tryToRecoverOrDoLogout]", this.profileAndConfigRetrievalRecoveryTimesMade);

    if (this.profileAndConfigRetrievalRecoveryTimesMade >= this.configService.PROFILE_AND_CONFIG_RETRIEVAL_RECOVERY_TIMES) {
      this.profileAndConfigRetrievalRecoveryTimesMade = 0;

      this.toastService.show("NO_CONNECTION_POSSIBLE");

      return false;
    }

    return true;
  }

  private resetAndRedirectToLoginScreen() {
    this.logger.info("[RootComponent][resetAndRedirectToLoginScreen]");
    this.configService.clearStorage();
    if (environment.isElectron) {
      this.electronService.clearStorage();
    }
    this.configService.redirectToLoginScreen();
  }

  private doLogout() {
    this.logger.info("[RootComponent][doLogout]");

    this.appService.logout();
  }

  handleCollapse(state) {
    this.logger.info("[RootComponent][handleCollapse] ", this.collapsed, state);
    this.collapsed = state;
    this.appStore.dispatch(new ExpandSideRostersList(this.collapsed));
  }

  getRoomId() {
    let roomId: any = [
      this.selectedConversation?.Target,
      this.userJID?.bare
    ];
    if (this.userJID && !this.userJID?.bare) {
      roomId = [
        this.selectedConversation?.Target,
        this.userJID.local
      ];
    }
    roomId = roomId.sort((a, b): any => {
      return a < b;
    }).join("");
    return roomId.replace(/\./ig, "").replace(/\s+/g, "");
  }

  markConversationAsRead(target: string): void {
    // this.logger.info("[RootComponent][markConversationAsRead1]", target, new Date());

    this.resetMarkAsReadTimeout();
    this.markAsRead = setTimeout(() => {
      // this.logger.info("[RootComponent][markConversationAsRead1] in timeout ", target, new Date());
      this.conversationRepo.markConversationAsRead(target);
    }, 3000);
    const idle = new NotIdle()
    .whenInteractive()
    .within(3, 1000)
    .do(() => {
      this.resetMarkAsReadTimeout();
      // this.logger.info("[resetMarkAsReadTimeout]", target, new Date());
      idle.stop();
    })
    .start();
  }

  resetMarkAsReadTimeout(): void {
    if (this.markAsRead) {
      clearTimeout(this.markAsRead);
    }
  }

  openBubbleChat() {

  }

  getCondition() {
    let conditionNewConversation = document.getElementsByClassName("vnctalk-new-conversation-header");
    let conditionAddContact = document.getElementsByClassName("talk-add-contacts-header");
    let talkRightPanel = document.querySelector(".talk-right-panel");

    const value = conditionNewConversation.length !== 0 || conditionAddContact.length !== 0
      || (talkRightPanel !== null && talkRightPanel.getAttribute("style") === "display: block;");
      conditionNewConversation = null;
      conditionAddContact = null;
      talkRightPanel = null;
      return value;
  }

  getConditionIncoming() {
    let conditionIncomingCall = document.getElementsByClassName("vt-notification-title-mobile");

    const value = conditionIncomingCall.length !== 0;
    conditionIncomingCall = null;
    return value;
  }

  isEnable() {
    return window.location.href.indexOf("/talk") === -1 && window.location.href.indexOf("/external-call") === -1;
  }

  onCreate() {

  }

  onDestroy() {

  }

  getTotalUnread() {
    return 0;
  }

  navigateToCall() {
    this.logger.info("[RootComponent][navigateToCall]");

    if (window.location.href.indexOf("/talk") === -1) {
      this.router.navigate(["/talk"]);
    }

    this.jitsiService.detachAllTracks();
    this.conferenceRepo.hideActiveCall();
    this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(conversationTarget => {
      this.store.dispatch(new SetActiveTab("chat"));
      this.conversationRepo.navigateToConversation(conversationTarget);
      this.changeDetectorRef.markForCheck();
    });

    if (document.getElementById("mainLayout") !== null) { // To hide header on mobile
      document.getElementById("mainLayout").classList.add("hide-header-mobile");
    }
    this.broadcaster.broadcast("closeDialog");
    setTimeout(() => {
      this.broadcaster.broadcast("displayVideoChat");
    }, 500);
  }

  isExternal() {
    return window.location.href.indexOf("external-call") !== -1;
  }

  getDisplay(collapsed) {
    return collapsed ? "block" : "none";
  }

  hideSidebar() {
    this.appStore.dispatch(new ExpandSideRostersList(false));
    this.collapsed = false;
    let iframe: any = <HTMLElement>document.querySelector(".chat-window-wrapper iframe");
    if (iframe !== null) {
      iframe.src = iframe.src; // FIXME: ??
    }
  }

  private joinVideoCallFromLink(link: string) {

    if (this.conferenceRepo.availableMediaDevicesSet) {
      setTimeout(() => {
        let jwtString = link.split("/")[3];
        this.logger.info("[RootComponent][joinVideoCallFromLink] jwtString", jwtString);
        const jwtObject = CommonUtil.verifyJWT(jwtString);
        this.logger.info("[RootComponent][joinVideoCallFromLink] jwtObject", jwtObject);
        if (!!jwtObject.target) {
          this.conversationService.getRoomMembers(jwtObject.target).pipe(take(1)).subscribe((v) => {
            this.logger.info("[RootComponent][joinVideoCallFromLink] jwtObject target roomMembers: ", v);
            // we only get roomMembers if we are member of the group / meeting - so we can join the call normally, otherwise join with meeting component
            if (v.length > 0) {
              this.conversationRepo.joinRoomIfNotJoined(jwtObject.target);
              this.conversationRepo.navigateToConversation(jwtObject.target);
              setTimeout(() => {
                this.logger.warn("[RootComponent][joinVideoCallFromLink] joining target: ", jwtObject.target);
                this.conferenceRepo.startConference(jwtObject.target, "audio");
              }, 300);

            } else {
              // open in vncmeet/join anonymous mode
              this.router.navigate([link]);
            }
          });
        }
      }, 200);
    } else {
      setTimeout(() => {
        this.joinVideoCallFromLink(link);
      }, 200);
    }
  }

  private firebaseSetup() {
    this.logger.info("[RootComponent][firebaseSetup]", this.isXmppConnected);

    this.logger.info("[RootComponent] Web Workers available", !!window.Worker);

    try {
      this.appService.saveApiUrlToPreferences();
      this.appService.saveAvatarServiceUrlToPreferences();

      window.FirebasePlugin.hasPermission(hasPermission => {
        this.logger.info("[RootComponent][firebaseSetup] Permission is ", hasPermission);
        if (!hasPermission) {
          // request permission
          window.FirebasePlugin.grantPermission(hasGratedPermission => {
            this.logger.info("[RootComponent][firebaseSetup] GrantPermission is ", hasGratedPermission);
          });
        }
      });


      window.FirebasePlugin.initCrashlytics(() => {
        this.logger.info("[RootComponent][FirebasePlugin][initCrashlytics] OK");
      },  (error) => {
        this.logger.sentryErrorLog("[RootComponent][FirebasePlugin][initCrashlytics]", error);
        this.logger.error("[RootComponent][FirebasePlugin][initCrashlytics]", error);
      });

      if (this.isOnline) {
        window.FirebasePlugin.onTokenRefresh(token => {
          this.logger.info("[RootComponent]Firebase token", token);

          if (this.CommonUtil.isOnAndroid()){
            this.postPushToken(token, "fcm");
          }

          const storedToken = localStorage.getItem("token");
          const storedFirebaseToken = localStorage.getItem("firebaseToken");
          if (storedToken && storedFirebaseToken !== token) {
            this.appService.setFirebaseToken(token).pipe(take(1)).subscribe(res => {
                this.logger.info("[addFirebaseToken] result", res);
                localStorage.setItem("firebaseToken", token);
              });
                  // goal is to make a single call from mobile app
            // this.appService.addFirebaseToken(token).pipe(take(1)).subscribe(res => {
            //   this.logger.info("[addFirebaseToken] result", res);
            //   localStorage.setItem("firebaseToken", token);
            // });
          }
        }, error => {
          this.logger.sentryErrorLog("[RootComponent] Firebase onTokenRefresh error", error);
          this.logger.error("[RootComponent] Firebase onTokenRefresh error", error);
        });

        if (this.CommonUtil.isOnIOS()) {
          window.FirebasePlugin.onAPNSToken(apnsToken => {
            this.logger.info("[RootComponent] apnsToken", apnsToken);
            this.apnsToken = apnsToken;
            localStorage.setItem("iosApnsToken", this.apnsToken);
            const restoredVoipToken = localStorage.getItem("iosVoipToken");
            if (!!restoredVoipToken) {
              this.voipToken = restoredVoipToken;
            }
            let pToken = this.apnsToken + "+" + this.voipToken + "+" + this.pushEnvironment;
            this.postPushToken(pToken, "apns");
          }, error => {
            this.logger.sentryErrorLog("[RootComponent] apnsToken error", error);
            this.logger.error("[RootComponent] apnsToken error", error);
          });
        }
      }

      window.FirebasePlugin.onNotificationOpen(($event) => {
        this.logger.info("[RootComponent][FirebasePlugin][onNotificationOpen]", $event, window.appInBackground);

        if (this.CommonUtil.isOnIOS()){
          $event.vncPeerJid = $event.jid;
          $event.vncEventType = $event.aps.category.toLowerCase();
        }

        if ($event.notification) {
          this.logger.info("[RootComponent][FirebasePlugin][onNotificationOpen]:", $event);
          const notification = JSON.parse($event.notification);
          this.logger.info("[RootComponent][FirebasePlugin][onNotificationOpen][notification][JSON]:", notification);
          this.channelRepository.redirectToObjectfromNotification(notification);
        }

        if (!$event.vncPeerJid || !$event.vncEventType) {
          return;
        }


        if ($event.tap) {
          this.firebaseClearAlliOSNotifications();

          // TODO: need to remove these local storage manipulations
          localStorage.setItem("vncPeerJid", $event.vncPeerJid);
          localStorage.setItem("vncEventType", $event.vncEventType);
          this.conversationRepo.handlePushNotification($event);
          this.hideSidebar();
        }

        // No need to handle anything here. Code is written in native to handle it
      },  (error) => {
        this.logger.sentryErrorLog("[RootComponent][FirebasePlugin][onNotificationOpen]", error);
        this.logger.error("[RootComponent][FirebasePlugin][onNotificationOpen]", error);
      });

      // will be called only in foreground
      window.FirebasePlugin.onNotificationReceived(($event) => {
        this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived1][notification]", $event, window.appInBackground, this.isXmppConnected);
        if (!window.appInBackground) {
          this.handlePushNotificationReceived($event);
        } else {
          if (!this.isXmppConnected) {
            if (!!$event.callSignal && !!$event.eventType) {
              // handle callSignals from fcm in background when disconnected
              if (($event.callSignal === "1") && ($event.eventType === "invite")) {
                // incoming call
                const options = {
                  eventType: "invite",
                  target: $event.target,
                  username: $event.username,
                  groupName: $event.groupName,
                  message: $event.message,
                  receiver: $event.receiver,
                  vncInitiatorJid: $event.vncInitiatorJid,
                  jitsiRoom: $event.jitsiRoom,
                  jitsiURL: $event.jitsiURL
                };
                this.callKitService.setIncomingCall(options);
                // if (device && (device.platform === "Android") && ((device.version === "13") || (device.version === "12"))) {
                if (device && (device.platform === "Android")) {
                  this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][rejected-self/leave] -> call hideIncomingCallNotification", $event);
                  const groupName = !!$event.groupName ? $event.groupName : "";
                  this.notificationsService.scheduleCallNotification($event.msgid, $event.eventType, $event.target, $event.username, groupName, $event.message, this.userJID?.bare, $event.nfrom, $event.jitsiRoom, $event.jitsiURL);
                }

              } else if (($event.callSignal === "1") && ($event.eventType === "joined-self")) {
                this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][cancelIncomingCall]", $event);
                // joined from other client
                const options = {
                  eventType: "joined-self",
                  target: $event.target,
                  username: $event.username,
                  groupName: $event.groupName,
                  message: $event.message,
                  receiver: $event.receiver,
                  vncInitiatorJid: $event.vncInitiatorJid,
                  jitsiRoom: $event.jitsiRoom,
                  jitsiURL: $event.jitsiURL
                };
                this.callKitService.cancelIncomingCall(options);
                // if (device && (device.platform === "Android") && ((device.version === "13") || (device.version === "12"))) {
                if (device && (device.platform === "Android")) {
                  this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][joined-self] -> call hideIncomingCallNotification", $event);
                  this.notificationsService.hideIncomingCallNotification($event.target);
                }

              } else if (($event.callSignal === "1") && (($event.eventType === "rejected-self") || ($event.eventType === "leave")) ) {
                // rejected / cancelled from other client
                this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][cancelIncomingCall]", $event);
                const options = {
                  eventType: "rejected-self",
                  target: $event.target,
                  username: $event.username,
                  groupName: $event.groupName,
                  message: $event.message,
                  receiver: $event.receiver,
                  vncInitiatorJid: $event.vncInitiatorJid,
                  jitsiRoom: $event.jitsiRoom,
                  jitsiURL: $event.jitsiURL
                };
                this.callKitService.cancelIncomingCall(options);
                // if (device && (device.platform === "Android") && ((device.version === "12") || (device.version === "13"))) {
                if (device && (device.platform === "Android")) {
                  this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][rejected-self/leave] -> call hideIncomingCallNotification", $event);
                  this.notificationsService.hideIncomingCallNotification($event.target);
                }
              }
            }
          }
          this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][notification] background - do sync?", $event, this.isXmppConnected, this.isNetworkOnline);
          if (this.CommonUtil.isOnAndroid()) {
            if (this.checkNetwork) {
              this.checkNetwork.unsubscribe();
            }
            this.checkNetwork = this.store.select(getNetworkInformation).pipe(filter(v => v && v.onlineState), take(1)).subscribe((cn) => {
              if (!this.isXmppConnected) {
                this.logger.info("[RootComponent][FirebasePlugin][onNotificationReceived][notification] background - do sync2", $event, this.isXmppConnected, cn);
                try {
                  let lastBGsync = localStorage.getItem("lastbgsync");
                  let lastbgts;
                  if (!!lastBGsync) {
                    lastbgts = parseInt(lastBGsync, 10);
                  } else {
                    lastbgts = parseInt(localStorage.getItem("resyncedConvsAt"), 10);
                  }
                  let nts = new Date().getTime();
                  // this.logger.info("[RootComponent][firebaseSetup][onNotificationReceived] background - timestamps: ", nts, lastbgts);
                  if (((nts - lastbgts) > 300000) && !this.isSyncLocked) {
                    this.isSyncLocked = true;
                    this.logger.info("[RootComponent][firebaseSetup][onNotificationReceived] background - need to perform sync");
                    cordova.plugins.backgroundMode.enable();
                    window.plugins.insomnia.keepAwake(() => {
                      const lastXmppDisconnectAt = !!localStorage.getItem("lastXmppDisconnectAt") ? parseInt(localStorage.getItem("lastXmppDisconnectAt")) - 180 : Math.floor(Date.now() / 1000) - 3600;
                      const lastSyncCompleted = !!localStorage.getItem("lastSyncCompleted") ? parseInt(localStorage.getItem("lastSyncCompleted")) : Math.floor(Date.now() / 1000) - 600;
                      this.conversationRepo.mobileSyncAllConversationMessagesBackground(Math.min(lastXmppDisconnectAt, lastSyncCompleted));
                      this.conversationRepo.mobileSyncAllRecentBackground(Math.min(lastXmppDisconnectAt, lastSyncCompleted));
                      // this.conversationRepo.loadUpdatedConvsSince(lastbgts - 10000);
                      this.isSyncLocked = false;
                    }, error => {
                      this.logger.warn("[AppComponent][firebaseSetup][onNotificationReceived] background - insomnia failed: ", error);
                    });

                  } else {
                    this.logger.info("[AppComponent][firebaseSetup][onNotificationReceived] background - skip sync");
                  }
                } catch (error) {
                  this.logger.error("[AppComponent][firebaseSetup][onNotificationReceived] background ", error);
                }
              }
            });

          }
        }
      },  (error) => {
        this.logger.sentryErrorLog("[RootComponent][FirebasePlugin][onNotificationReceived]", error);
        this.logger.error("[RootComponent][FirebasePlugin][onNotificationReceived]", error);
      });

      // Mark As Read action (currently only Android)
      if (this.CommonUtil.isOnAndroid()) {
        window.FirebasePlugin.onNotificationMarkAsRead(($event) => {
          this.logger.info("[RootComponent][FirebasePlugin][onNotificationMarkAsRead]", $event);
          this.conversationRepo.resetUnreadCount($event.target);
        }, (error) => {
          this.logger.sentryErrorLog("[RootComponent][FirebasePlugin][onNotificationMarkAsRead]", error);
          this.logger.error("[RootComponent][FirebasePlugin][onNotificationMarkAsRead]", error);
        });
      }
    } catch (e) {
      this.logger.sentryErrorLog("[[RootComponent] FirebasePlugin.try error", e);
      this.logger.error("[[RootComponent] FirebasePlugin.try error", e);
    }
  }

  private postPushToken(token: string, type: string) {
    const isFcmOrAPNSToken = type === "fcm" || type === "apns";

    this.logger.info("[RootComponent][postPushToken]", token, type);

    if (token && token.trim().length > 0) {
      this.appService.setFirebaseToken(token).subscribe(res => {
        if (isFcmOrAPNSToken) {
          this.isFirebaseSetUpCompleted = true;
        }
        this.logger.info("[RootComponent][postPushToken] Ok", res);
      }, err => {
        this.logger.sentryErrorLog("[RootComponent][postPushToken] error", err);
        this.logger.error("[RootComponent][postPushToken] error", err);

        if (isFcmOrAPNSToken) {
          localStorage.setItem("pendingFCMToken", token);
        }

        CommonUtil.sentryLog("[RootComponent] Setting Firebase token error: " + err);
      });
    }
  }

  // iOS only
  private voipPushNotifiactionsSetup() {
    this.logger.info("[RootComponent][voipPushNotifiactionsSetup]");

    if (!CommonUtil.isOnIOS()) {
      this.logger.warn("[RootComponent][voipPushNotifiactionsSetup] skip, no VoIPPushNotification class available for platform");
      return;
    }

    this.voip = window.VoIPPushNotification.init();

    this.voip.on("registration", data => {
      this.logger.info("[RootComponent][voipPushNotifiactionsSetup] registration", data);

      //data.deviceToken;
      //do something with the device token (probably save it to your backend service)
      if (data && data.deviceToken) {
        this.voipToken = data.deviceToken;
        this.logger.info("[RootComponent][voipPushNotifiactionsSetup] registration, voipToken", this.voipToken);
        localStorage.setItem("iosVoipToken", this.voipToken);
        let pToken = this.apnsToken + "+" + this.voipToken + "+" + this.pushEnvironment;
        this.postPushToken(pToken, "apns_voip");
      }
    });

    this.voip.on("notification", data => {
      this.logger.info("[RootComponent][voipPushNotifiactionsSetup] notification", data);

      // do something based on received data
    });

    this.voip.on("error", e => {
      this.logger.error("[RootComponent][voipPushNotifiactionsSetup] error", e);
    });
  }

  private handlePushNotificationReceived(event): void {
    this.logger.info("[RootComponent][handlePushNotificationReceived] event", event);

    if (event.callSignal) {
      this.logger.info("[RootComponent][handlePushNotificationReceived] ignore call signals");
      return;
    }

    const target = event.target;
    const msgid = event.msgid;
    const username = event.username;
    const groupName = event.groupName;
    let message = event.message;
    const eventType = event.eventType;
    const mentions = event.mention.split(",");
    if (this.checkIfGloballyMuted() || this.conferenceRepo.checkIfMuteNotification(target) || this.conferenceRepo.checkIfMuteEverything(target)) {
      this.logger.info("[RootComponent][checkIfGloballyMuted] do not display notification");
      return;
    }
    if (this.selectedConversation && this.selectedConversation?.Target === target) {
      return;
    }

    let conv;
    this.store.select(state => getConversationById(state, target))
      .pipe(take(1)
      , filter(c => !!c))
      .subscribe(c => {
        conv = c;
        this.logger.info("[RootComponent][handlePushNotificationReceived] conv & mentions", conv, mentions);

        let mute = this.conferenceRepo.checkIfMuteSound(target);
        if (this.userJID && environment.isCordova && CommonUtil.isOnAndroid()) {
          const generateSecretKey = "_._" + this.userJID?.bare;
          let body;
          if (message.indexOf(ConstantsUtil.REPLY_MESSAGE_LINE_BREAK) > -1) {
            body = CommonUtil.renderBodyWithEmoji(message.split(ConstantsUtil.REPLY_MESSAGE_LINE_BREAK)[1], this.translate);
          } else {
            body = CommonUtil.renderBodyWithEmoji(message, this.translate);
          }
          if (conv && conv.type === "groupchat" && CommonUtil.parseMentions(body).length > 0) {
            body = this.contactRepo.renderMentionUsers(body, "plain", []);
          }
          if (this.userJID && mentions.includes(this.userJID?.bare)  && this.settings.chatMention) {
            body = body.replace(generateSecretKey, "");
            this.notificationsService.scheduleLocalNotification(target, msgid, username, groupName, body, eventType, mute);
          } else if (!mute && window.appInBackground) {
            this.newNotificationService.playReceiveMessage();
          }
        } else {

          if (this.userJID) {
            if (conv.type === "groupchat" || conv.type === "normal") { // 'normal' means broadcast
              if (mentions.includes(this.userJID?.bare)  && this.settings.chatMention) {
                this.translate.get("MENTIONED_IN_GROUP_CHAT", {user: username}).pipe(take(1)).subscribe(text => {
                  this.notificationsService.mentionNotification(target, text, target, msgid);
                });
              } else {
                if (CommonUtil.parseMentions(message).length > 0) {
                  message = this.contactRepo.renderMentionUsers(message, "plain", []);
                }
                this.notificationsService.groupChat(target, message, target);
              }
            } else {
              this.notificationsService.singleChat(target, message, target);
            }
          } else if (this.userJID && mentions.includes(this.userJID?.bare) && this.settings.chatMention) {
            this.translate.get("MENTIONED_IN_GROUP_CHAT", {user: username}).pipe(take(1)).subscribe(text => {
              this.notificationsService.mentionNotification(target, text, target, msgid);
            });
          }

          if (!mute) {
            this.newNotificationService.playReceiveMessage();
          }

          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private handleDesktopNotification(conv: Conversation, msg: any): void {
    this.logger.info("[RootComponent][handleDesktopNotification] conv", conv, msg);
    if (msg.body === "Encrypted message" && !msg.cannotDecrypt) {
      // skip desktop notification on arrival of encrypted message; notification will be triggered after decrypt
      return;
    }
    let mute = this.conferenceRepo.checkIfMuteSound(conv.Target);
    let muteNotification = this.conferenceRepo.checkIfMuteNotification(conv.Target);
    if (this.checkIfGloballyMuted() || this.conferenceRepo.checkIfMuteEverything(conv.Target)) {
      return;
    }
    this.conferenceRepo.getActiveConference().pipe(take(1)).subscribe(conferenceTarget => {
      if (!!conferenceTarget) {
        mute = true;
      }
    });
    let target = msg?.from?.bare;
    let message = CommonUtil.processHTMLBody(msg.body || "");
    if (msg.vncTalkBroadcast) {
      target = msg.vncTalkBroadcast.origtarget;
    }
    let username;
    this.contactRepo.getContactVCard(msg.fromJid).pipe(take(1)).subscribe(vCard => {
        username = vCard && vCard.fullName;
    });
    if (username == null || username === ""){
      username = this.contactRepo.getFullName(msg.fromJid);
    }
    let references = [];
    if (!!msg.references) {
      references = msg.references.map(v => v.uri.replace(/xmpp:+/ig, ""));
    }
    let hasMentionFromBody = false;
    let msgBody = msg.body || "";
    if (msg.originalMessage && msg.originalMessage.replyMessage) {
      msgBody = msg.originalMessage.replyMessage;
    }
    if (msgBody.match(/@all\b/ig)) {
      hasMentionFromBody = true;
    }
    if (msgBody.startsWith("#quickreply_")) {
      const tkey = (msgBody.startsWith("#quickreply_")) ? msgBody.split("#quickreply_")[1] : msgBody;
      this.translate.get(tkey).pipe(take(1)).subscribe(text => {
        message = text;
        this.logger.info("QUICKREPLYNOTI: ", message);
      });
    }
    if (environment.isCordova && CommonUtil.isOnAndroid()) {
      const generateSecretKey = "_._" + this.userJID?.bare;
      let groupName = conv.groupChatTitle;
      const eventType = conv.type;
      if (msg.vncTalkBroadcast) {
        groupName = "Broadcast: " + msg.vncTalkBroadcast.title;
        // username = msg.vncTalkBroadcast.title;
      }
      let body: string;
      if (message.indexOf(ConstantsUtil.REPLY_MESSAGE_LINE_BREAK) > -1) {
        body = CommonUtil.renderBodyWithEmoji(message.split(ConstantsUtil.REPLY_MESSAGE_LINE_BREAK)[1], this.translate);
      } else {
        body = CommonUtil.renderBodyWithEmoji(message, this.translate);
      }

      if (conv && conv.type === "groupchat" && CommonUtil.parseMentions(body).length > 0) {
        body = this.contactRepo.renderMentionUsers(body, "plain", []);
      }
      if ((!!msg.references && this.userJID && references.includes(this.userJID?.bare)) || hasMentionFromBody) {
          this.translate.get("MENTIONED_IN_GROUP_CHAT", {user: username}).pipe(take(1)).subscribe(text => {
            body = text;
          });
        if (this.settings.chatMention && !muteNotification) {
          body = body.replace(generateSecretKey, "");
          this.notificationsService.scheduleLocalNotification(target, msg.id, username, groupName, body, eventType, mute);
        }
      } else if (!mute) {
        this.newNotificationService.playReceiveMessage();
      }
    } else {
      let mtest = conv.mute_notification & 2;
      this.logger.info("mtest: ", mtest, conv.mute_notification);
      if (this.userJID && ((conv.mute_notification & 2) !== 2)) {
        if (!!msg.attachment && !!msg.attachment.fileType) {
          if (CommonUtil.isImage(msg.attachment.fileType)) {
            this.translate.get("SENT_A_IMAGE", { user: username }).pipe(take(1)).subscribe(text => {
              message = text;
            });
          } else if (CommonUtil.isSupportedVideo(msg.attachment.fileType)) {
            this.translate.get("SENT_A_VIDEO", { user: username }).pipe(take(1)).subscribe(text => {
              message = text;
            });
          } else if (CommonUtil.isAudio(msg.attachment.fileType)) {
            this.translate.get("SENT_A_VOICE_MESSAGE", { user: username }).pipe(take(1)).subscribe(text => {
              message = text;
            });
          } else {
            this.translate.get("SENT_A_FILE", { user: username }).pipe(take(1)).subscribe(text => {
              message = text;
            });
          }
        }
        if (conv.type === "groupchat") {
          if (this.settings.chatMention && msg.references && references.includes(this.userJID?.bare) || hasMentionFromBody) {
            this.translate.get("MENTIONED_IN_GROUP_CHAT", {user: username}).pipe(take(1)).subscribe(text => {
              this.notificationsService.mentionNotification(target, text, target, msg.id);
            });
          } else {
            if (CommonUtil.parseMentions(message).length > 0) {
              if (this.settings.chatMention) {
                message = this.contactRepo.renderMentionUsers(message, "plain", []);
                this.notificationsService.groupChat(target, message, target);
              }
            } else {
              this.notificationsService.groupChat(target, message, target);
            }
          }
        } else if (msg.vncTalkBroadcast) {
          this.notificationsService.groupChat(msg.vncTalkBroadcast.title, message, msg.vncTalkBroadcast.origtarget);
        } else {
          if (!msg.meetingMessage) {
            this.notificationsService.singleChat(target, message, target);
          }
        }
      } else if (hasMentionFromBody || this.userJID && msg.references && references.includes(this.userJID?.bare)) {
        this.translate.get("MENTIONED_IN_GROUP_CHAT", {user: username}).pipe(take(1)).subscribe(text => {
          this.notificationsService.mentionNotification(target, text, target, msg.id);
        });
      }
      if (!mute) {
        this.newNotificationService.playReceiveMessage();
      }
      this.changeDetectorRef.markForCheck();
    }
  }

  @HostListener("document:pause", ["$event"])
  appWentIntoBackground() {
    this.logger.info("[root.ts] [background] app is running in background");
  }

  @HostListener("document:resume", ["$event"])
  appCameIntoForeground() {
    this.logger.info("[root.ts] [background] app is running in foreground");
  }

  @HostListener("window:message", ["$event"])
  windowMessageEventHandler(event: MessageEvent) {
    const eventData = event.data;

    // this.logger.info("[RootComponent][windowMessageEventHandler] eventData", eventData);

    if (!eventData || eventData.source && eventData.source === "@devtools-page") {
      // Chrome Redux-devtools extension message
      return;
    }

    if (eventData && eventData.type === ConstantsUtil.POST_MESSAGE) {
      this.logger.info("[root.ts] login postback update: ", eventData);
      if (environment.isElectron) {
        this.electronService.clearStorage();
      }
      if (eventData.token && eventData.token.trim().length > 0) {
        localStorage.removeItem("unVerifiedToken");
        localStorage.setItem("token", eventData.token);
        this.appService.saveAuthTokenToPreferences(eventData.token);
        // this.appService.addFirebaseToken(eventData.token);
      }
      this.appService.saveApiUrlToPreferences();
      this.appService.saveAvatarServiceUrlToPreferences();

      this.configService.hideLoginIframe();

      let initialHref = CommonUtil.isOnAndroid() ? window.location.href.split("/www")[0] : window.location.href.split("/talk")[0];
      const newHref = `${initialHref}${CommonUtil.isOnAndroid() ? "/www" : ""}/index.html`;
      this.logger.info("redirectToMainPage newHref: ", newHref);
      window.location.href = newHref;

      return;
    } else if (eventData && eventData.type === ConstantsUtil.TFA_OTP_VERIFICATION) {
      if (eventData.token && eventData.token.trim().length > 0) {
        localStorage.setItem("unVerifiedToken", eventData.token);
        this.configService.hideLoginIframe();
        this.configService.tfaOtpIframe();
        return;
      }
    } else if (eventData && eventData.type === ConstantsUtil.GO_TO_LOGIN_SCREEN) {
      this.configService.hideTfaOtpIframe();
      this.configService.loginIframe();
    } else if (eventData && eventData.link && typeof eventData.link !== "function") {
      // PIYUSH_COMMENT quick fix here because
      // eventData.link is picking up value of "ƒ link() { [native code] }" somehow

      this.logger.info("[root.ts] data.link snippet: ", event, eventData.link);
      if (CommonUtil.isOnNativeMobileDevice()) {
        if (device.platform === "iOS") {
          window.open(eventData.link, "_system");
        } else {
          navigator.app.loadUrl(eventData.link, {
            openExternal: true
          });
        }
      } else if (this.electronService.isElectron) {
        this.logger.info("data.link - skip calling electron service");
        this.electronService.openExternalUrl(eventData.link);
      } else {
        const newWindow = window.open(eventData.link, "_blank");
        newWindow.focus();
      }
    } else if (eventData && eventData.type === "CHANGE_SERVER_URL") {
      this.configService.selectedServer = false;
      this.changeDetectionRef.markForCheck();
      this.configService.hideLoginIframe();

    } else if (eventData && eventData.type === "LOGIN_WITH_OAUTH") {
      if (environment.enableOauthLogin) {
        this.loginWithOAuth = true;
        this.changeDetectionRef.markForCheck();
        this.configService.hideLoginIframe();
      }

    } else if (eventData && eventData.type === "PDFJS_CLICK") {
      this.logger.info("PDFJS_CLICK", eventData);
    }

    if (eventData.type === ConstantsUtil.LOGIN_USERNAME_CLICKED) {
      this.configService.handleLoginResize();
    } else if (eventData.type === ConstantsUtil.SHOW_LOGIN_FORM) {
      if (document.querySelector("#forgotPasswordIframe") !== null) {
        document.querySelector("#forgotPasswordIframe").remove();
      }
      if (document.querySelector("#registrationIframe") !== null) {
        document.querySelector("#registrationIframe").remove();
      }
    } else if (eventData.type === ConstantsUtil.SHOW_FORGOT_PASSWORD_FORM) {
      if (document.querySelector("#registrationIframe") !== null) {
        document.querySelector("#registrationIframe").remove();
      }
      this.forgotPasswordIframe();
    } else if (eventData.type === ConstantsUtil.SHOW_REGISTRATION_FORM) {
      if (document.querySelector("#forgotPasswordIframe") !== null) {
        document.querySelector("#forgotPasswordIframe").remove();
      }
      this.registrationIframe();
    }
  }

  private forgotPasswordIframe() {
    let iframe = document.createElement("iframe");
    iframe.id = "forgotPasswordIframe";
    iframe.setAttribute("src", "https://signup.vncmeet.com/account/lost_password");
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.position = "fixed";
    iframe.style.zIndex = "999";
    iframe.style.border = "none";

    if (document.querySelector("body") !== null && document.querySelector("#forgotPasswordIframe") === null) {
        document.querySelector("body").appendChild(iframe);
    }
    iframe = null;
  }

  private registrationIframe() {
    let iframe = document.createElement("iframe");
    iframe.id = "registrationIframe";
    iframe.setAttribute("src", "https://signup.vncmeet.com/account/register");
    iframe.style.height = "100%";
    iframe.style.width = "100%";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.position = "fixed";
    iframe.style.zIndex = "999";
    iframe.style.border = "none";

    if (document.querySelector("body") !== null && document.querySelector("#registrationIframe") === null) {
        document.querySelector("body").appendChild(iframe);
    }
    iframe = null;
  }

  private async setupEmoji() {
    let emojiType = "google";
    let isMac = navigator.platform.toUpperCase().indexOf("MAC") !== -1;
    if (CommonUtil.isOnIOS() || CommonUtil.isOnIOSMobile() || isMac) {
      emojiType = "apple";
    }

    wdtEmojiBundle.defaults.emojiType = emojiType;
    wdtEmojiBundle.defaults.emojiSheets = {
      "google": CommonUtil.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png"),
      "apple": CommonUtil.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png"),
      "twitter": CommonUtil.getFullUrl("/assets/emoji/sheet_twitter_64_indexed_128.png")
    };

    if (!wdtEmojiBundle.emoji) {
      wdtEmojiBundle.emoji = new EmojiConvertor();

      wdtEmojiBundle.emoji.img_set = emojiType;
      wdtEmojiBundle.emoji.use_sheet = true;
      wdtEmojiBundle.emoji.supports_css = true;
      wdtEmojiBundle.emoji.replace_mode = "unified";
      wdtEmojiBundle.emoji.allow_native = true;
      wdtEmojiBundle.emoji.img_sets = {
        "google": {
          mask: 2,
          path: "emoji-data/img-google-64/",
          sheet: CommonUtil.getFullUrl("/assets/emoji/sheet_google_64_indexed_128.png")
        },
        "apple": {
          mask: 2,
          path: "emoji-data/img-apple-64/",
          sheet: CommonUtil.getFullUrl("/assets/emoji/sheet_apple_64_indexed_128.png")
        },
        "twitter": {
          mask: 2,
          path: "emoji-data/img-twitter-64/",
          sheet: CommonUtil.getFullUrl("/assets/emoji/sheet_twitter_64_indexed_128.png")
        }
      };
      document.querySelector("body").dataset.wdtEmojiBundle = emojiType;
      this.logger.info("[setupEmoji]", emojiType);
      this.changeDetectionRef.markForCheck();
    }
  }

  private setupTranslationService() {
    this.translate.addLangs(this.configService.getSupportedLangs());
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.configService.getDefaultLang());
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use(this.config.getConfiguredLang());
    this.translate.use(CommonUtil.getDefaultLang());
    this.changeDetectorRef.markForCheck();
  }

  private sendInlineReplyMessage() {
    if (this.appService) {
      this.appService.fetchReplyMessages().subscribe(messages => {
        if (!messages || messages.length === 0) {
          this.logger.info("[RootComponent][sendInlineReplyMessage] skip, nothing to process");
          return;
        }

        this.logger.info("[RootComponent][sendInlineReplyMessage] messages", messages);

        messages.forEach(msg => {
          this.store.select(state => getConversationById(state, msg.target))
            .pipe(filter(conv => !!conv)
            , take(1))
            .subscribe(conv => {
              this.conversationRepo.sendMessage({ body: msg.message, type: conv.type }, conv.Target, conv.type);
            });
        });

        this.appService.removeReplyMessages();
      });
    }
  }

  private sendMarkAsReadFailedMessage(){
    this.logger.info("[RootComponent][sendMarkAsReadFailedMessage], isNetworkOnline: " + this.isNetworkOnline);

    this.appService.fetchMarkAsReadFailedMessage()
      .subscribe(messages => {
        if (!messages || messages.length === 0) {
          this.logger.info("[RootComponent][sendMarkAsReadFailedMessage] skip, nothing to process");
          return;
        }

        let requests = {};
        this.logger.info("[RootComponent][sendMarkAsReadFailedMessage] messages", messages.length, messages);
        messages.forEach(msg => {
          if (!requests[msg.target] || requests[msg.target] < msg.timestamp) {
            requests[msg.target] = msg.timestamp;
          }
        });
        this.logger.info("[RootComponent][sendMarkAsReadFailedMessage] requests count:", Object.keys(requests).length);
        for (let target in requests) {
          this.logger.info("[RootComponent][sendMarkAsReadFailedMessage] target (requests)", target, requests[target]);
          this.store.select(state => getConversationById(state, target))
            .pipe(filter(conv => !!conv)
            , take(1))
            .subscribe(conv => {
              this.conversationRepo.closeNotifications([conv.Target]);
              if (this.isNetworkOnline){
                this.conversationRepo.markConversationsRead(conv, requests[target]);
              }else{
                this.conversationRepo.resetUnreadCount(conv.Target);
              }
            });
        }

        if (this.isNetworkOnline){
          this.logger.info("[RootComponent][sendMarkAsReadFailedMessage] removeMarkAsReadFailedMessage");
          this.appService.removeMarkAsReadFailedMessage();
        }else{
          this.logger.info("[RootComponent][sendMarkAsReadFailedMessage] skip removeMarkAsReadFailedMessage because offline");
        }
    });
  }

  deviceReady() {
    this.logger.info("[RootComponent][deviceReady]");

    if (window.BuildInfo) {
      this.pushEnvironment = window.BuildInfo.debug ? "dev" : "prod";
    }

    if (environment.isCordova){
      this.logger.info("deviceready - locking orientation");
      if (!CommonUtil.isOnIpad()) {
        try {
          screen.orientation.lock("portrait");
        } catch (error) {
          this.logger.error("failed to lock orientation: ", error);
        }
      }
      this.logger.info("[deviceready] device: ", device);
    }

    this.store.select(getIsConnectedXMPP).pipe(takeWhile(() => !this.isFirebaseSetUpCompleted), filter(v => !!v)).subscribe(() => {
      this.firebaseSetup();
      this.voipPushNotifiactionsSetup();
    });

    this.store.select(getTotalUnreadCount).subscribe(count => {
      this.logger.info("[RootComponent][deviceReady] getTotalUnreadCount", count);
      this.setAppBadgeCounter(count);
    });

    if (typeof Keyboard !== "undefined") {
      Keyboard.shrinkView(true);
    }

    if (environment.isCordova && this.isNetworkOnline){
      this.sendFailedInlineRepliesAndMarkAsReadRequests();
    }

    if (this.CommonUtil.isOnAndroid()) {
      cordova.plugins.DozeOptimize.IsIgnoringBatteryOptimizations((response) => {
        this.logger.info("IsIgnoringBatteryOptimizations: " + response);
        if (response === "false") {
          cordova.plugins.DozeOptimize.RequestOptimizations((response) => {
            this.logger.info(response);
          }, (error) => {
            this.logger.sentryErrorLog("BatteryOptimizations Request Error" + error);
            this.logger.error("BatteryOptimizations Request Error" + error);
          });
        }
        else {
          this.logger.info("Application already Ignoring Battery Optimizations");
        }
      }, (error) => {
        this.logger.sentryErrorLog("IsIgnoringBatteryOptimizations Error" + error);
        this.logger.error("IsIgnoringBatteryOptimizations Error" + error);
      });


      let permissions = cordova.plugins.permissions;
      let checkNotificationPermissionCallback = status => {
        this.logger.info("[RootComponent] notification permissions", status.hasPermission, status);

        if (!status.hasPermission) {
          let errorCallback = function () {
            this.logger.error("[root.component]  notification permission is not granted");
          };
          permissions.requestPermission(
            permissions.POST_NOTIFICATIONS,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };

      let checkVideoPermissionCallback = status => {
        this.logger.info("[RootComponent] Camera permissions", status.hasPermission, status);

        if (!status.hasPermission) {
          let errorCallback = function () {
            this.logger.error("[root.component]  Camera permission is not turned on");
          };
          permissions.requestPermission(
            permissions.CAMERA,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };
      let checkAudioPermissionCallback = status => {
        this.logger.info("[RootComponent] Audio permissions", status.hasPermission, status);

        if (!status.hasPermission) {
          let errorCallback = function () {
            this.logger.error("[root.component] Audio permission is not turned on");
          };
          permissions.requestPermission(
            permissions.RECORD_AUDIO,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };


      let checkWriteExternalStoragePermissionCallback = function (status) {
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.error("[root.component] Write external storage permission is not turned on");
          };
          permissions.requestPermission(
            permissions.WRITE_EXTERNAL_STORAGE,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };

      let checkReadExternalStoragePermissionCallback = function (status) {
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.error("[root.component] Read external storage permission is not turned on");
          };
          permissions.requestPermission(
            permissions.READ_EXTERNAL_STORAGE,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };

      let checkReadMediaVideosPermissionCallback = function (status) {
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.error("[root.component] Read media images permission is not turned on");
          };
          permissions.requestPermission(
            permissions.READ_MEDIA_VIDEO,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };

      let checkReadMediaAudioPermissionCallback = function (status) {
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.error("[root.component] Read media images permission is not turned on");
          };
          permissions.requestPermission(
            permissions.READ_MEDIA_AUDIO,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);
        }
      };


      let checkCallPhonePermissionCallback = function (status) {
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.log("[root.component] phone call permission");
          };
          permissions.requestPermission(
            permissions.CALL_PHONE,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);

        }
      };
      let checkAnswerPhonePermissionCallback = function (status) {
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.log("[root.component] phone call answer permission");
          };
          permissions.requestPermission(
            permissions.ANSWER_PHONE_CALLS,
            function (s) {
              if (!s.hasPermission) {
                errorCallback();
              }
            },
            errorCallback);

        }
      };
      let checkReadPhoneNumbersPermissionCallback = function (status) {
        // eslint-disable-next-line no-console
        console.log("[root.component] read phone numbers permission status: ", status);
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.log("[root.component] read phone numbers answer permission error");
          };
          permissions.requestPermission(
            permissions.READ_PHONE_NUMBERS,
            function (s) {
              if (!s.hasPermission) {
                // eslint-disable-next-line no-console
                console.log("[root.component] read phone numbers NO permission");
                errorCallback();
              }
            },
            errorCallback);

        }
      };

      let checkBluetoothPermissionCallback = function (status) {
        // eslint-disable-next-line no-console
        console.log("[root.component] BLUETOOTH_CONNECT permission status: ", status);
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.log("[root.component] BLUETOOTH_CONNECT answer permission error");
          };
          permissions.requestPermission(
            permissions.BLUETOOTH_CONNECT,
            function (s) {
              if (!s.hasPermission) {
                // eslint-disable-next-line no-console
                console.log("[root.component] BLUETOOTH_CONNECT NO permission");
                errorCallback();
              }
            },
            errorCallback);

        }
      };

      let checkReadPhoneStatePermissionCallback = function (status) {
        // eslint-disable-next-line no-console
        console.log("[root.component] READ_PHONE_STATE  permission status: ", status);
        if (!status.hasPermission) {
          let errorCallback = function () {
            // eslint-disable-next-line no-console
            console.log("[root.component] READ_PHONE_STATE  answer permission error");
          };
          permissions.requestPermission(
            permissions.BLUETOOTH_CONNECT,
            function (s) {
              if (!s.hasPermission) {
                // eslint-disable-next-line no-console
                console.log("[root.component] READ_PHONE_STATE  NO permission");
                errorCallback();
              }
            },
            errorCallback);

        }
      };

      permissions.checkPermission(permissions.READ_PHONE_NUMBERS, checkReadPhoneNumbersPermissionCallback, null);
      permissions.checkPermission(permissions.CAMERA, checkVideoPermissionCallback, null);
      permissions.checkPermission(permissions.RECORD_AUDIO, checkAudioPermissionCallback, null);

      permissions.checkPermission(permissions.READ_EXTERNAL_STORAGE, checkReadExternalStoragePermissionCallback, null);
      permissions.checkPermission(permissions.WRITE_EXTERNAL_STORAGE, checkWriteExternalStoragePermissionCallback, null);
      permissions.checkPermission(permissions.ANSWER_PHONE_CALLS, checkAnswerPhonePermissionCallback, null);
      permissions.checkPermission(permissions.CALL_PHONE, checkCallPhonePermissionCallback, null);
      permissions.checkPermission(permissions.BLUETOOTH_CONNECT, checkBluetoothPermissionCallback, null);
      if (device && (device.platform === "Android") && ((device.version === "12") || (device.version === "11"))) {
        permissions.checkPermission(permissions.READ_PHONE_STATE, checkReadPhoneStatePermissionCallback, null);
      }
      this.checkForExtentendedAdroidPermissions();

      cordova.plugins.backgroundMode.on("disable", function() {
        // this.logger.info("[root.component] BACKGROUNDMODE DISABLE");
        this.isSyncLocked = false;
      });
      cordova.plugins.backgroundMode.on("deactivate", function() {
        // this.logger.info("[root.component] BACKGROUNDMODE DEACTIVATE");
        this.isSyncLocked = false;
      });

      cordova.plugins.backgroundMode.on("activate", function() {
        cordova.plugins.backgroundMode.disableWebViewOptimizations();
      });

      cordova.plugins.backgroundMode.disableBatteryOptimizations();

      cordova.plugins.backgroundMode.setDefaults({
        resume: true,
        hidden: true,
        showWhen: false,
        visibility: "public"
      });

      // this.checkDevUpdatesIfNeed();
    }

    if (this.CommonUtil.isOnIOS()) {
      if (!!localStorage.getItem("serverURLBackup")) {
        localStorage.setItem("serverURL", localStorage.getItem("serverURLBackup"));
        localStorage.removeItem("serverURLBackup");
      }
    }

    if (universalLinks) {
      universalLinks.subscribe("triggerActionEvent", eventData => {
        this.processTriggerActionEvent(eventData);
      });

      universalLinks.subscribe("vncmeetJoinCallEvent", eventData => {
        this.processConfJoinUniversalLink(eventData);
      });
    }

    const initSuccess = () => {
      this.logger.info("[openwith] init success!");
    };
    const initError = (err) => {
      this.logger.info("[openwith] init failed: " + err);
    };
    cordova.openwith.init(initSuccess, initError);
    const myHandler = (intent) => {
      this.logger.info("[openwith] intent received", intent);
      localStorage.setItem("shared_data", JSON.stringify({
        intent: intent
      }));
      this.handleSharing();
    };
    cordova.openwith.addHandler(myHandler);
  }

  private checkDevUpdatesIfNeed() {
    this.logger.info("[RootComponent][checkDevUpdatesIfNeed]");

    const isAndroidAppUpgradeStored = localStorage.getItem("isAndroidAppUpgradeEnabled");
    const isAndroidAppUpgradeEnabled = !!isAndroidAppUpgradeStored && (isAndroidAppUpgradeStored === "1");

    let currentTime = Date.now();
    let latestChecktime = localStorage.getItem("dateLatestCheckDevAndroidVersion");
    const appPackage = "biz.vnc.vnctalk";
    appAvailability.check(
      appPackage,
      (data) => {
        this.logger.info("[RootComponent][checkDevUpdatesIfNeed] appName got data: ", appPackage, data);
        if (!!data && !!data.installSource) {
          // when installed from store, installSource is 'com.android.vending'
          if (data.installSource !== "com.android.vending") {
            if (isAndroidAppUpgradeEnabled && (!latestChecktime || (currentTime - +latestChecktime > 1 * 60 * 1000))) {
              cordova.getAppVersion.getAppName().then(appName => {
                this.logger.info("[RootComponent][checkDevUpdatesIfNeed] appName: ", appName, !!isAndroidAppUpgradeEnabled);

                cordova.getAppVersion.getVersionNumber().then(currentVersion => {
                  this.logger.info("[RootComponent][checkDevUpdatesIfNeed] currentVersion: ", currentVersion);

                  CommonUtil.getLatestDevApkVersion(appName).subscribe(devVersion => {
                    this.logger.info("[RootComponent][checkDevUpdatesIfNeed] devVersion: ", devVersion);
                    let isNew = true;
                    try {
                      const nDevVersion = parseInt(devVersion.replace(/[a-zA-Z.-]/g, ""), 10);
                      const nCurrentVersion = parseInt(currentVersion.replace(/[a-zA-Z.-]/g, ""), 10);
                      isNew = nDevVersion > nCurrentVersion;
                      this.logger.info("[RootComponent][checkDevUpdatesIfNeed] comp: ", isNew, nDevVersion, nCurrentVersion);
                    } catch (error) {
                      this.logger.info("[RootComponent][checkDevUpdatesIfNeed] comp error: ", error);
                    }
                    if (!!currentVersion && !!devVersion && (currentVersion !== devVersion.replace("-", ".")) && isNew) {
                      this.logger.info("[RootComponent][checkDevUpdatesIfNeed] : SOFTWARE_UPDATE_AVAILABLE");
                      this.translate.get(["SOFTWARE_UPDATE_AVAILABLE", "PRESS_YES_TO_DOWNLOAD_AND_UPDATE", "YES", "CANCEL"]).subscribe(res => {
                        localStorage.setItem("dateLatestCheckDevAndroidVersion", currentTime.toString());
                        navigator.notification.confirm(
                          res.PRESS_YES_TO_DOWNLOAD_AND_UPDATE,
                          (index) => {
                            this.logger.info("[RootComponent][checkDevUpdatesIfNeed] index: ", index);
                            if (index == 1) {
                              const url = APK_STORE_URL + appName + "-" + devVersion + ".apk";
                              this.logger.info("[RootComponent][checkDevUpdatesIfNeed] Url for download: ", url);
                              navigator.app.loadUrl(url, {
                                openExternal: true
                              });
                            }
                          },
                          res.SOFTWARE_UPDATE_AVAILABLE,
                          `${res.YES}, ${res.CANCEL}`
                        );
                      });
                    } else {
                      this.logger.info("[RootComponent][checkDevUpdatesIfNeed] installed the latest dev version");
                    }
                  });
                });
              });
            } else {
              this.logger.info("[RootComponent][checkDevUpdatesIfNeed] too early to check updates or disabled");
            }
          }
        }
      },
      (err) => {
        this.logger.info("[RootComponent][checkDevUpdatesIfNeed] appName got error: ", appPackage, err);
      }
    );

  }

  private processConfJoinUniversalLink(eventData) {
    // hide all open dialogs
    this.broadcaster.broadcast("closeDialog");

    if (!!localStorage.getItem("serverURL")) {
      localStorage.setItem("serverURLBackup", localStorage.getItem("serverURL"));
    }
    this.configService.selectedServer = true;
    this.configService.isAnonymous = true;
    this.logger.info("[RootComponent] isAnonymous = TRUE");
    this.configService.hideLoginIframe();
    this.changeDetectionRef.markForCheck();

    this.logger.info("[RootComponent][universalLinks] path", eventData.path);

    const pathSplit = eventData.path.split("/");
    const jwt = pathSplit[pathSplit.length - 1];
    this.logger.info("[RootComponent][universalLinks] jwt", jwt);
    const serverURL = eventData.url.split("/vncmeet")[0];
    this.configService.API_URL = serverURL;
    this.store.dispatch(new SetBaseApiUrl(serverURL));
    localStorage.setItem("vncmeetURL", serverURL);
    this.loadConfigOnly();
    const url = `${ConstantsUtil.CONFERENCE_LINK_MOBILE_PART}${jwt}`;

    this.router.navigate([url]);
  }


  private firebaseClearAlliOSNotifications() {
    // remove all notifications on opening the app
    if (this.CommonUtil.isOnIOS()){
      window.FirebasePlugin.clearAllNotifications( () => {
        this.logger.info("[RootComponent][FirebasePlugin clearAllNotifications] success");
      }, error => {
        this.logger.error("[RootComponent][FirebasePlugin clearAllNotifications] error", error);
      });
    }
  }

  private resetBadgeCounterForCurrentConv() {
    // reset badge for current conv if open an app from background
    this.conversationRepo.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        this.conversationRepo.resetUnreadCount(conv.Target);

        this.store.select(getTotalUnreadCount).pipe(take(1)).subscribe(count => {
          this.setAppBadgeCounter(count);
        });
      }
    });
  }

  private setAppBadgeCounter(counter) {
    this.logger.info("[RootComponent][setAppBadgeCounter]", counter);
    if (window.FirebasePlugin) {
      if (CommonUtil.isOnIOS()){
        // for iOS we get a badge counter from push notification when in background,
        // so from the app we update iy only when on foreground
        if (!window.appInBackground){
          window.FirebasePlugin.setBadgeNumber(counter);
        }
      } else {
        window.FirebasePlugin.setBadgeNumber(counter);
      }
    }

  }

  private async handleSharing() {
    const data = localStorage.getItem("shared_data");
    this.logger.info("[RootComponent][handleSharing] data: ", data);
    this.logger.info("[RootComponent][handleSharing] isLoggedIn: ", this.isLoggedIn);

    if (data && this.isLoggedIn) {
      localStorage.removeItem("shared_data");

      let dialogStyles = {
        "width": "517px",
        "maxWidth": "80vw",
        "height": "none",
        "maxHeight": "600px",
        "visibility": "visible"
      };
      if (CommonUtil.isMobileSize()) {
        dialogStyles = {
          "width": "100%",
          "maxWidth": "100%",
          "height": "100%",
          "maxHeight": "100%",
          "visibility": "visible"
        };
      }
      const { ForwardDialogComponent } = await import(
        "./talk/shared/components/dialogs");
      this.dialog.open(ForwardDialogComponent, {
        backdropClass: "forward-dialog-backdrop",
        panelClass: ["forward-dialog-panel", "preview-doc-dialog-panel"],
        disableClose: true,
        autoFocus: false,
        width: dialogStyles.width,
        data: data,
        maxHeight: dialogStyles.maxHeight,
        maxWidth: dialogStyles.maxWidth,
        height: dialogStyles.height
      });
    } else {
      this.logger.info("[RootComponent][handleSharing] skip, not all data available");
    }
  }

  private checkIfGloballyMuted(): boolean {
    let isGloballyMuted = false;
    this.store.select(getGlobalMute)
      .pipe(take(1))
      .subscribe(mute => {
        isGloballyMuted = mute;
      });
    this.logger.info("[checkIfGloballyMuted]", isGloballyMuted);
    return isGloballyMuted;
  }

  @HostListener("document:click", ["$event"])
  documentClick(event) {
    this.logger.info("[RootComponent][document:click]documentClick ", event, event.target.classList, CommonUtil.isMobileSize(), event.target.closest("vp-participants-list"));
    CommonUtil.hideCircularMenu();
    if (CommonUtil.isOnMobileDevice()) {
      this.broadcaster.broadcast("hideSwipeAction");
    }
    if (wdtEmojiBundle && event.target.closest(".wdt-emoji-menu-content") === null && !event.target.classList.contains("wdt-emoji-picker")) {
      wdtEmojiBundle.close();
    }
    if (event.target.classList.contains("emoji-inner") || event.target.classList.contains("wdt-emoji-picker")) {
      wdtEmojiBundle.search("");
      wdtEmojiBundle.searchInput.value = "";
    }

    let url: string;
    if (event.target.classList.contains("open-new-window")) {
      url = event.target.href;
    } else if (event.target.nodeName !== "A" && event.path && event.path[1] && event.path[1].classList && event.path[1].classList.contains("open-new-window")) {
      url = event.path[1].href;
    }

    if (url && url !== "" && (url.startsWith("https") || url.startsWith("http")) && event.target.nodeName === "A" && (!event.target.getAttribute("target") || event.target.getAttribute("target") !== "_blank")) {
      event.target.setAttribute("target", "_blank");
    }

    if (event.target.nodeName === "svg") {
      // this.logger.info("[RootComponent][document:click]documentClick svg ", event.target.parentNode);
      try {
        if (event?.target?.parentNode?.classList.contains("open-new-window")) {
          url = event.target.parentNode.href;
        }
      } catch (error) {
        this.logger.info("error parsing parentNode: ", error);
      }
    }
    if (url && (environment.isCordova || environment.isElectron)) {
      event.stopPropagation();
      event.preventDefault();

      if (typeof cordova !== "undefined") {
        if (device.platform === "iOS") {
          window.open(url, "_system");
        } else if (device.platform === "Android") {
          navigator.app.loadUrl(url, {
            openExternal: true
          });
        }
      } else if (this.electronService.isElectron) {
        this.logger.info("[documentClick] open electron external: ", url);
        this.electronService.openExternalUrl(url);
      }
    }
    if (!CommonUtil.isMobileSize()) {
      event.stopPropagation();
      if (!!document.querySelector(".group-menu")) {
        this.broadcaster.broadcast("hideConversationItemMenu");
      }
      if (!!document.querySelector(".meeting-participants-list")) {
        this.broadcaster.broadcast("hideInviteesList");
      }
      if (!event.target.classList.contains("mat-menu-item") && !event.target.classList.contains("avatar-photo")
      && !event.target.classList.contains("groupchat-avatar-icon") && !event.target.classList.contains("avatar-text")
      && !event.target.classList.contains("vnc-avatar") && !event.target.classList.contains("notification-icon")) {
        this.broadcaster.broadcast("hideDropdownMenuHeader");
      }
    }
    if (!event.target.classList.contains("mat-menu-item") && !event.target.classList.contains("avatar-photo")
    && !event.target.classList.contains("groupchat-avatar-icon") && !event.target.classList.contains("avatar-text")
    && !event.target.classList.contains("material-icons")
    && !event.target.classList.contains("vnc-avatar") && event.target.id !== "broadcastKebabMenu") {
      if (CommonUtil.isMobileSize()) {
        this.broadcaster.broadcast("closeMainMenu");
      } else {
        this.broadcaster.broadcast("hideProfileMenu");
      }
    }

    this.broadcaster.broadcast(Events.DOCUMENT_CLICKED);

    if (document.querySelector(".show-menu") !== null) {
      document.querySelector(".show-menu").classList.remove("show-menu");
    }

    const videElement = document.querySelector("gallery-video") as HTMLElement | null;
    const videoPlayer = document.querySelector("video") as HTMLMediaElement | null;
    const playButton = document.createElement("div") as HTMLElement | null;
    const videoConditions = (param) => param !== null;
    if (videoConditions(videElement) && videoConditions(videoPlayer)) {
      if (CommonUtil.isOnFirefox()) {
        playButton.style.display = "none";
        return;
      }
      videElement.appendChild(playButton);
      playButton.innerHTML = this.svgPlayButton;
      playButton.classList.add("videoPlayButton");
      playButton.addEventListener("click", function () {
        videoPlayer.play();
      });
      videoPlayer.addEventListener("play", (event) => {
        if (event.type == "play") {
          playButton.style.visibility = "hidden";
        }
      });
      videoPlayer.addEventListener("pause", (event) => {
        if (event.type == "pause") {
          playButton.style.visibility = "visible";
        }
      });
    }
  }

  async showWelcomeDialog() {
    let dialogStyles: any = {
      "width": "320px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "70%",
        "min-width": "70%",
        "max-width": "70%",
        "height": "auto",
        "visibility": "visible"
      };
    }
    const { WelcomeNotificationComponent } = await import(
      "./talk/shared/components/welcome-notification/welcome-notification");
    this.dialog.open(WelcomeNotificationComponent,Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        autoFocus: true
      }, dialogStyles));
  }

  async showRemainDialog() {
    let dialogStyles: any = {
      "width": "320px",
      "visibility": "visible"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "70%",
        "min-width": "70%",
        "max-width": "70%",
        "height": "auto",
        "visibility": "visible"
      };
    }
    const { RescanNotificationComponent } = await import(
      "./talk/shared/components/rescan-notification/rescan-notification");
    this.dialog.open(RescanNotificationComponent,Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        autoFocus: true
      }, dialogStyles));
  }

    private async checkForLatestBrowserVersion() {
      if (window.navigator.userAgent !== "Mozilla/5.0 (Linux; Android 8.0; Pixel 2 Build/OPD3.170816.012) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3329.0 Mobile Safari/537.36") {
        // that's the agent of nightwatch tests!
        const browser = Bowser.getParser(window.navigator.userAgent);
        const isValidBrowser = browser.satisfies({
          // or in general
          chrome: ">=84",
          chromium: ">=84",
          firefox: ">=84",
          edge: ">=82",
          opera: ">=72",
          safari: ">=12.0"
        });

        this.logger.info("[RootComponent][checkForLatestBrowserVersion]", browser);

        if (!isValidBrowser) {
          const { InvalidBrowserDialogComponent } = await import(
            "./talk/shared/components/dialogs/invalid-browser/invalid-browser.component");
          this.dialog.open(InvalidBrowserDialogComponent, {
            width: "380px",
            backdropClass: "invalid-browser-backdrop",
            panelClass: "invalid-browser-panel",
            disableClose: true
          });
        }
      }
  }

  private recheckConfigs() {
    this.logger.info("[RootComponent][recheckConfigs]");
    this.configService.getConfigFromServer().pipe(distinctUntilChanged()).pipe(take(1)).subscribe(cfg => {
      const cachedConfigString = localStorage.getItem("configFromServer");
      let cachedConfig;
      try {
        if (!!cachedConfigString && (cachedConfigString !== "")) {
          cachedConfig = JSON.parse(cachedConfigString);
        }
      } catch (e) {
        this.logger.sentryErrorLog("[RootComponent][recheckConfigs] error restoring cachedConfig: ", e);
        this.logger.error("[RootComponent][recheckConfigs] error restoring cachedConfig: ", e);
      }
      let configEqual = CommonUtil.compareObjects(cfg, cachedConfig);
      this.logger.info("[RootComponent][recheckConfigs] configEqual: ", configEqual);
      if (!configEqual) {
        this.retrieveAndSetConfig(true);
      }
    });

    if (CommonUtil.isOnAndroid()) {
      this.logger.info("[RootComponent][recheckConfigs] check for updates - checkDevUpdatesIfNeed");
      this.checkDevUpdatesIfNeed();
    }
  }

  async showUserInfo(recipient: Recipient) {
    let dialogStyles: any = {
      "width": "400px",
      "height": "405px",
      "minWidth": "400px"
    };
    if (CommonUtil.isMobileSize()) {
      dialogStyles = {
        "width": "90%",
        "height": "90%",
        "margin-top": "0",
        "minWidth": "90%"
      };
    }
    if (!CommonUtil.isGroupTarged(recipient.target)) {
      const { UserInfoDialogComponent } = await import(
        "./talk/shared/components/dialogs/user-info/user-info.component");
      this.dialog.open(UserInfoDialogComponent, Object.assign({
        data: { bare: recipient.target, name: recipient.title, ldapData: recipient.ldapData },
        backdropClass: "vnctalk-form-backdrop",
        panelClass: ["vnctalk-form-panel", "border-radius-6px","box-shadow"],
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false
      }, dialogStyles));
    } else {
      const { VNCTalkGroupInformationComponent } = await import(
        /* webpackPrefetch: true */
        "./talk/shared/components/groupchat-information/groupchat-information.component");
      this.dialog.open(VNCTalkGroupInformationComponent, Object.assign({
        data: recipient.target,
        backdropClass: "vnctalk-form-backdrop",
        panelClass: ["vnctalk-form-panel", "border-radius-6px"],
        disableClose: true,
        hasBackdrop: true,
         autoFocus: false
      }, dialogStyles));
    }
  }

  closeUserInfoDialog() {
    if (!CommonUtil.isMobileSize()) {
      this.broadcaster.broadcast("closeUserInfoDialog");
    }
  }

  async showUserInfoOnHover(recipient: Recipient) {
    if (!CommonUtil.isMobileSize()) {
      this.closeUserInfoDialog();
      let dialogStyles: any = {
        "width": "400px",
        "height": "405px",
        "minWidth": "400px",
        "right": "110px"
      };
      if (!CommonUtil.isGroupTarged(recipient.target)) {
        const { UserInfoDialogComponent } = await import(
          "./talk/shared/components/dialogs/user-info/user-info.component");
        this.dialog.open(UserInfoDialogComponent, Object.assign({
          data: { bare: recipient.target, name: recipient.title, ldapData: recipient.ldapData },
          backdropClass: "vnctalk-form-backdrop",
          panelClass: ["vnctalk-form-panel", "border-radius-6px","box-shadow"],
          disableClose: true,
          hasBackdrop: false,
          autoFocus: false
        }, dialogStyles));
      } else {
        const { VNCTalkGroupInformationComponent } = await import(
          /* webpackPrefetch: true */
          "./talk/shared/components/groupchat-information/groupchat-information.component");
        this.dialog.open(VNCTalkGroupInformationComponent, Object.assign({
          data: recipient.target,
          backdropClass: "vnctalk-form-backdrop",
          panelClass: ["vnctalk-form-panel", "border-radius-6px"],
          disableClose: true,
          hasBackdrop: false,
          autoFocus: false
        }, dialogStyles));
      }

    }
  }

  private processTriggerActionEvent(eventData) {
    this.logger.info("[RootComponent][processTriggerActionEvent]", eventData);
    if (eventData) {
      if (eventData.params) {
        const params = eventData.params;
        this.logger.info("[RootComponent][processTriggerActionEvent]", params);
        if (eventData.params && eventData.path === "/talk/trigger") {
          this.router.navigate([eventData.path], { queryParams: params });
        }
      }
    }
  }

  private getTrustResourceURL(path) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtil.getFullUrl(path));
  }

  private registerIcons() {
    this.matIconRegistry.addSvgIcon(
      `add_users`,
      this.getTrustResourceURL(`/assets/svg/add-users.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `perm-identity`,
      this.getTrustResourceURL(`/assets/svg/icon-product-perm-identity.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `contacts-new`,
      this.getTrustResourceURL(`/assets/svg/icon-product-contacts-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `vpn-key-new`,
      this.getTrustResourceURL(`/assets/svg/icon-product-vpn-key-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `exit-to-app`,
      this.getTrustResourceURL(`/assets/svg/icon-product-exit-to-app.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `audio`,
      this.getTrustResourceURL(`/assets/svg/audio.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `pad`,
      this.getTrustResourceURL(`/assets/svg/pad.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `screenshare`,
      this.getTrustResourceURL(`/assets/svg/screenshare.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `video`,
      this.getTrustResourceURL(`/assets/svg/video.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `whiteboard`,
      this.getTrustResourceURL(`/assets/svg/whiteboard.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `icon-product-chart-03`,
      this.getTrustResourceURL(`/assets/svg/icon-product-chart-03.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `bell`,
      this.getTrustResourceURL(`/assets/svg/bell.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `mute_sound`,
      this.getTrustResourceURL(`/assets/svg/mute_sound.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `mute_notification`,
      this.getTrustResourceURL(`/assets/svg/mute_notification.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `mute_everything`,
      this.getTrustResourceURL(`/assets/svg/mute_everything.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `muted_sound`,
      this.getTrustResourceURL(`/assets/svg/sound_muted.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `muted_notification`,
      this.getTrustResourceURL(`/assets/svg/notification_muted.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `muted_everything`,
      this.getTrustResourceURL(`/assets/svg/muted_everything.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `mute_incoming_call`,
      this.getTrustResourceURL(`/assets/svg/mute_incoming_call.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `mute_incoming_call_muted`,
      this.getTrustResourceURL(`/assets/svg/mute_incoming_call_muted.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `settings`,
      this.getTrustResourceURL(`/assets/svg/settings.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `private`,
      this.getTrustResourceURL(`/assets/svg/key.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `broadcast`,
      this.getTrustResourceURL(`/assets/svg/icon-product-broadcast-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `chats`,
      this.getTrustResourceURL(`/assets/svg/icon-product-chats-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `hashtag`,
      this.getTrustResourceURL(`/assets/svg/icon-product-hashtag-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `mention`,
      this.getTrustResourceURL(`/assets/svg/icon-product-mention-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `help`,
      this.getTrustResourceURL(`/assets/svg/icon-product-help-outline.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `plus`,
      this.getTrustResourceURL(`/assets/svg/icon-product-add.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.getTrustResourceURL(`/assets/svg/icon-product-delete.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `acc_circle`,
      this.getTrustResourceURL(`/assets/svg/icon-product-account-circle.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `new_topic`,
      this.getTrustResourceURL(`/assets/svg/icon-product-new-topic-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `new_channel`,
      this.getTrustResourceURL(`/assets/svg/icon-product-new-chanel-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `archive`,
      this.getTrustResourceURL(`/assets/svg/icon-product-archive-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `request_connect`,
      this.getTrustResourceURL(`/assets/svg/icon-product-request-connect-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `unarchive`,
      this.getTrustResourceURL(`/assets/svg/unarchive.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `users`,
      this.getTrustResourceURL(`/assets/svg/users.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `info-circle-outline`,
      this.getTrustResourceURL(`/assets/svg/info-circle-outline.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `icon-product-file-02-new`,
      this.getTrustResourceURL(`/assets/svg/icon-product-file-02-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `icon-product-lock-outline`,
      this.getTrustResourceURL(`/assets/svg/icon-product-lock-outline.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `icon-product-helpdesk-new`,
      this.getTrustResourceURL(`/assets/svg/icon-product-helpdesk-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.getTrustResourceURL(`/assets/svg/delete.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `advanced_search`,
      this.getTrustResourceURL(`/assets/svg/adv-search.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `send_to_outline`,
      this.getTrustResourceURL(`/assets/svg/send-to-outline.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `camera_icon`,
      this.getTrustResourceURL(`/assets/svg/camera_icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `contact_icon`,
      this.getTrustResourceURL(`/assets/svg/contact_icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `chat_icon`,
      this.getTrustResourceURL(`/assets/svg/chat_icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `background_icon`,
      this.getTrustResourceURL(`/assets/svg/background_icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `info-icon`,
      this.getTrustResourceURL(`/assets/svg/info-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `screenshare_icon`,
      this.getTrustResourceURL(`/assets/svg/screenshare-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `add_icon`,
      this.getTrustResourceURL(`/assets/svg/add.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `edit_icon`,
      this.getTrustResourceURL(`/assets/svg/edit.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `fullscreen_icon`,
      this.getTrustResourceURL(`/assets/svg/fullscreen.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `expand_icon`,
      this.getTrustResourceURL(`/assets/svg/icon-custom-expand.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `minimize_icon`,
      this.getTrustResourceURL(`/assets/svg/icon-custom-minimize.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `camera_off_icon`,
      this.getTrustResourceURL(`/assets/svg/icon-custom-camera-off.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `smartlink`,
      this.getTrustResourceURL(`/assets/svg/smart-objects/smartlink.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `sticky-note`,
      this.getTrustResourceURL(`/assets/svg/smart-objects/sticky-note.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `highlight`,
      this.getTrustResourceURL(`/assets/svg/smart-objects/highlight.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `reference`,
      this.getTrustResourceURL(`/assets/svg/smart-objects/reference.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `zoom_out`,
      this.getTrustResourceURL(`/assets/svg/zoom-out.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `social-icon`,
      this.getTrustResourceURL(`/assets/svg/live-icon.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `public-channel-icon`,
      this.getTrustResourceURL(`/assets/icon-product-users-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `private-channel-icon`,
      this.getTrustResourceURL(`/assets/icon-product-lock-outline.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `intranet-channel-icon`,
      this.getTrustResourceURL(`/assets/icon-product-arrow-key-left-new.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `extranet-channel-icon`,
      this.getTrustResourceURL(`/assets/icon-product-arrow-keyboard-tab-new.svg`)
    );
  }

  uploadFileToTalk(data: any) {
    this.logger.info("[uploadFileToTalk]", data);
    data.fileName = data.fileName.split("?")[0];
    this.conversationRepo.uploadAttachmentAndSendMessage(data);
  }

  uploadFileToOwnCloud(data) {
    const file = data.blob;
    const extraHeaders = {};
    if (file.lastModifiedDate) {
      extraHeaders["X-OC-Mtime"] = "" + file.lastModifiedDate.getTime() / 1000;
    } else if (file.lastModified) {
      extraHeaders["X-OC-Mtime"] = "" + file.lastModified / 1000;
    }
    this.ownCloudService.isLoggedIn().pipe(filter(v => !!v), take(1)).subscribe(() => {
      this.ownCloudService.oc.files.putFileContents(data.path + data.fileName.replace(/%20/g, " "), file, {
        headers: extraHeaders,
        onProgress: progress => {
          this.logger.info(`[worker ] uploading file to cloud`, progress);
        }
      }).then(res => {
        this.newNotificationService.openSnackBarWithTranslation("FILES_HAVE_UPLOADED");
        this.logger.info(`[worker ] uploaded`, res);
        this.ownCloudService.reload$.next(true);
      }).catch(() => {
        this.newNotificationService.openSnackBarWithTranslation("CANNOT_UPLOAD");
      });
    });
  }

  async importDynamicModules() {
    this.logger.info("[importDynamicModules]", window.linkifyHtml);
    window.$ = window.jQuery = await normalizeCommonJSImport(import("jquery/dist/jquery.min.js"));
  }

  async checkForExtentendedAdroidPermissions() {
    this.logger.info("[checkForExtendedAndroidPermissions]");
    try {
      await this.checkcheckReadMediaImagesPermission();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("checkcheckReadMediaImagesPermission() rejected?");
    }
    try {
      await this.checkcheckReadMediaAudioPermission();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("checkcheckReadMediaImagesPermission() rejected?");
    }
    try {
      await this.checkcheckReadMediaVideoPermission();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("checkcheckReadMediaImagesPermission() rejected?");
    }

  }

  async checkcheckReadMediaImagesPermission() {
    return new Promise((resolve, reject) => {
      cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.READ_MEDIA_IMAGES, (status) => {
        // eslint-disable-next-line no-console
        console.log("checkReadMediaImagesPermission status: ", status);
        if (status.hasPermission) {
          resolve(true);
        } else {
          cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.READ_MEDIA_IMAGES, (s) => {
            if (s.hasPermission) {
              resolve(true);
            } else {
              reject(false);
            }
          }, (er) => {
            // eslint-disable-next-line no-console
            console.log("requestPermissionError: ", er);
            reject(false);
          });
        }
      }, () => {
        reject(false);
      });

    });
  }

  async checkcheckReadMediaAudioPermission() {
    return new Promise((resolve, reject) => {
      cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.READ_MEDIA_AUDIO, (status) => {
        // eslint-disable-next-line no-console
        console.log("checkReadMediaAudiPermission status: ", status);
        if (status.hasPermission) {
          resolve(true);
        } else {
          cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.READ_MEDIA_AUDIO, (s) => {
            if (s.hasPermission) {
              resolve(true);
            } else {
              reject(false);
            }
          }, (er) => {
            // eslint-disable-next-line no-console
            console.log("requestPermissionError: ", er);
            reject(false);
          });
        }
      }, () => {
        reject(false);
      });

    });
  }

  async checkcheckReadMediaVideoPermission() {
    return new Promise((resolve, reject) => {
      cordova.plugins.permissions.checkPermission(cordova.plugins.permissions.READ_MEDIA_VIDEO, (status) => {
        // eslint-disable-next-line no-console
        console.log("checkReadMediaVideoPermission status: ", status);
        if (status.hasPermission) {
          resolve(true);
        } else {
          cordova.plugins.permissions.requestPermission(cordova.plugins.permissions.READ_MEDIA_VIDEO, (s) => {
            if (s.hasPermission) {
              resolve(true);
            } else {
              reject(false);
            }
          }, (er) => {
            // eslint-disable-next-line no-console
            console.log("requestPermissionError: ", er);
            reject(false);
          });
        }
      }, () => {
        reject(false);
      });

    });
  }

  private deepLinksHandlerSetup() {
    window.handleOpenURL = url => {
      this.logger.warn("[root.component][handleOpenURL]", url);
      if (url === "vnctalk://talk/channels") {
        this.ngZone.run(() => {
          this.router.navigateByUrl("/talk/channels");
        });
      } else {
        if (url.startsWith("vnctalk://")) {
          this.broadcaster.broadcast("electronOpenUri", url);
        }
      }
    };
  }

  initWebWorker() {
    if (typeof Worker !== "undefined") {
      // Create a new
      this.ownCloudService.worker = new Worker(new URL("./app.worker", import.meta.url), { type: "module" });
      this.ownCloudService.worker.onmessage = ({ data }) => {
        this.logger.info(`[initWebWorker] page got message`, data);
        if (data && data.blob && data.action === "download") {
         this.uploadFileToOwnCloud(data);
        } else if (data && data.blob && data.action === "sendtotalk") {
         this.uploadFileToTalk(data);
        } else if (data && data.error) {
          this.newNotificationService.openSnackBarWithTranslation("CANNOT_UPLOAD");
        }
      };
     } else {
      this.logger.info(`Web Workers are not supported in this environment.`);
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
     }
  }

  addUserAgent() {
    if (navigator.userAgent.toLowerCase().match(/iphone/)) {
      document.querySelector("html").classList.add("iphone");
    } else if (navigator.userAgent.toLowerCase().match(/ipad/)) {
      document.querySelector("html").classList.add("ipad");
    } else if (navigator.userAgent.toLowerCase().match(/android/)) {
      document.querySelector("html").classList.add("android");
    }
    if (typeof device !== "undefined" || navigator.vendor.toLowerCase().match(/vnctalk/)) {
      if (navigator.vendor.toLowerCase().match(/apple/)) {
        document.querySelector("html").classList.add("vnctalksafari");
        document.querySelector("html").classList.add("safari");
      }
      if (navigator.vendor.toLowerCase().match(/google/)) {
        document.querySelector("html").classList.add("vnctalkchrome");
        document.querySelector("html").classList.add("chrome");
      }
    } else if (navigator.userAgent.toLowerCase().match(/fxios/) || navigator.userAgent.toLowerCase().match(/firefox/)) {
      document.querySelector("html").classList.add("firefox");
    } else if (navigator.userAgent.toLowerCase().match(/crios/)) {
      document.querySelector("html").classList.add("chrome");
    } else if (navigator.userAgent.toLowerCase().match(/android/)
      && navigator.userAgent.toLowerCase().match(/chrome/)) {
      document.querySelector("html").classList.add("chrome");
    } else {
      document.querySelector("html").classList.add("safari");
    }

    if (environment.isCordova) {
      document.querySelector("html").classList.add("is-cordova-app");
    }
  }
}

export enum CloseSmartObjectDialog {
  CLOSE_SMART_OBJECTS_PREVIEW_DIALOG,
  CLOSE_SMART_LINK_POPUP_DIALOG,
  CLOSE_SMART_LINK_FILTER_DIALOG,
  CLOSE_SMART_LINK_PREVIEW_DIALOG,
  CLOSE_STICKY_NOTE_DIALOG,
  CLOSE_ADD_REFERENCE_DIALOG,
  CLOSE_USER_SELECT_MOBILE_DIALOG
}
